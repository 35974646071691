import { Attribute } from '@/tt-entity-design/src/schema-types'
import { OPERATION_CENTERS } from './constants'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { AppContextEventManager } from '@/tt-app-context/types'

export const getAttr = (attr: Attribute<typeof OPERATION_CENTERS>) => attr

export const operationCenterResourceUpdated = (
  eventManager: AppContextEventManager,
): void => {
  eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
    resource: Resources.OPERATION_CENTERS,
  })
}
