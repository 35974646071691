<template>
  <div class="ml-3 mr-3">
    <div v-for="(group, groupKey) in groupedEnums" :key="groupKey" class="mb-3">
      <CollapsibleHeader
        v-if="group"
        :title="$t(createTranslationKey(groupKey))"
        :opened="isGroupCollapsibleHeaderOpen[groupKey]"
      >
        <v-checkbox
          v-for="item in group"
          :key="item.value"
          v-model="selected"
          dense
          hide-details
          :label="item.text"
          :value="item.value"
        />
      </CollapsibleHeader>
    </div>
  </div>
</template>

<script lang="ts">
import BaseFilter from '@/tt-entity-filter/components/BaseFilter'
import { PropType } from 'vue'
import { EnumAttributeFilterOptions } from '@/tt-widget-components'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { TYPE_GROUPING } from '@/tt-entity-design/src/components/system-exception-tickets/constants'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'
import {
  CtaGroupsCheckBoxFilter,
  CtaGroupsEnum,
} from '@/tt-entity-design/src/components/system-exception-tickets/type'

export default BaseFilter.extend({
  name: 'CustomCtaTypeFilter',
  props: {
    options: {
      type: Object as PropType<EnumAttributeFilterOptions>,
      default: () => ({} as EnumAttributeFilterOptions),
    },
  },
  data() {
    return {
      selected: [],
      isGroupCollapsibleHeaderOpen: {
        [CtaGroupsEnum.MOBILE]: false,
        [CtaGroupsEnum.RUNSHEETS]: false,
        [CtaGroupsEnum.SAFETY_MONITORING]: false,
        [CtaGroupsEnum.SHIFT_SCHEDULING]: false,
        [CtaGroupsEnum.OTHERS]: false,
      } as Record<CtaGroupsEnum, boolean>,
    }
  },
  computed: {
    groupedEnums(): Record<string, CtaGroupsCheckBoxFilter[]> {
      const isNotExcluded = (value: string) =>
        !this.options.exclude?.includes(value)

      const createItem = (value: string) => ({
        text: this.$t(
          this.$appContext.widgetServices.resourceMetaManager.getAttributeLabelKey(
            this.attributeMeta.resource,
            this.attributeMeta.name,
            FormLabelTypes.LABEL,
            value,
          ),
        ),
        value,
      })

      const groupedData: Record<string, CtaGroupsCheckBoxFilter[]> = {}
      for (const [groupKey, ctaTypes] of Object.entries(TYPE_GROUPING)) {
        const items = ctaTypes
          .filter(isNotExcluded)
          .map((type) => createItem(type))
          .sort((a, b) =>
            a.value !== b.value ? (a.value < b.value ? -1 : 1) : 0,
          )
        if (items.length) {
          groupedData[groupKey] = items
        }
      }

      return groupedData
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(val) {
        if (val) {
          this.model = {
            value: val ? val.join(',') : null,
            operator: FilterOperatorType.IN,
            attribute: this.value.attribute,
          }
        }
      },
    },
  },
  methods: {
    createTranslationKey(value: string) {
      return `tt-entity-design.system-exception-tickets.group-titles.${value}`
    },
    onInput(input?: { value: string | string[] }) {
      const value = input?.value || []
      this.selected = typeof value === 'string' ? value.split(',') : value
    },
    reset() {
      this.selected = []
    },
  },
  created() {
    if (this.selected.length === 0) {
      this.isGroupCollapsibleHeaderOpen[CtaGroupsEnum.MOBILE] = true

      return
    }

    Object.keys(TYPE_GROUPING).forEach((groupKey) => {
      const groupItems = TYPE_GROUPING[groupKey as CtaGroupsEnum]
      this.isGroupCollapsibleHeaderOpen[groupKey as CtaGroupsEnum] =
        this.selected.some((item) => groupItems.includes(item))
    })
  },
})
</script>
