<template>
  <span>
    <slot
      v-bind="{ localImgUrl, uploadFile, file, disabled: isReadOnly, loading }"
    />
  </span>
</template>

<script lang="ts">
import { fetchImage, uploadFile } from './utils'
import BaseInput from '@/tt-widget-components/components/BaseInput'
import LocalImagePreview from './LocalImagePreview.vue'
import { revokeObjectUrl, createObjectUrl } from './utils'
import { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

const onError = (msg: string) => (error) => console.warn(msg, error)

export default (
  BaseInput as VueConstructor<InstanceType<typeof BaseInput> & FormHookProvider>
).extend({
  name: 'ApiImageHandler',
  inject: ['formHook'],
  components: { LocalImagePreview },
  props: {
    initialImgUrl: {
      type: String,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    localImgUrl(): string {
      return createObjectUrl(this.file)
    },
    isReadOnly(): boolean {
      return this.formHook().isReadOnly()
    },
  },
  beforeDestroy() {
    revokeObjectUrl(this.localImgUrl)
  },
  watch: {
    initialImgUrl: {
      handler(url) {
        // preview images needs to be fetched with authenticated request
        this.fetchImage(url)
      },
      immediate: true,
    },
    localImgUrl(newUrl, oldUrl) {
      revokeObjectUrl(oldUrl)
    },
    id() {
      if (!this.id) this.file = null
    },
  },
  data: () => ({
    loading: false,
    file: null,
  }),
  methods: {
    async uploadFile(file?: File) {
      if (!file) return this.update(null, null)

      this.setLoading(true)

      const updateAndEmit = (response) => {
        const id = response?.data?.id
        if (!id) throw new Error('No id received from API response')

        this.update(file, id)
      }
      const stopLoading = () => this.setLoading(false)

      await uploadFile(this.$appContext, file)
        .then(updateAndEmit)
        .catch(onError('Error during upload of image'))
        .finally(stopLoading)
    },
    setLoading(isLoading: boolean) {
      this.loading = isLoading ? true : false
      this.formHook().state.isDebouncing = isLoading
    },
    update(file?: File, id?: number) {
      this.file = file
      this.$emit('uploaded', id)
    },
    async fetchImage(url: string) {
      if (!url) return

      this.loading = true

      const file = await fetchImage(this.$appContext, url).catch(
        onError('Error while fetching image'),
      )

      this.file = file

      this.loading = false
    },
  },
})
</script>
