<template>
  <div class="pa-4 fill-height">
    <DashboardDragAndDropProvider v-bind="{ hook }">
      <DashboardWidgetRow
        v-for="(row, index) in hook.state.widget.rows"
        :key="`row_${index}`"
        :row-index="index"
        :hook="hook"
        :edit-mode="editMode"
        :container="container"
      />
    </DashboardDragAndDropProvider>
    <v-toolbar
      v-if="editMode && !showAddButton"
      height="30"
      short
      dense
      @click="viewCode"
    >
      <v-spacer />
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn x-small icon v-on="on">
            <v-icon>mdi-code-braces</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('components.widget.code-preview.btn')" />
      </v-tooltip>
    </v-toolbar>
    <div v-if="showAddButton" class="mt-6" style="text-align: center">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn v-on="on" @click="hook.addRow(0)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('widgets.dashboard.add_row_up')" />
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import BaseWidget from '@/tt-widget-components/components/BaseWidget'
import DashboardWidgetHook from './DashboardWidgetHook'
import DashboardWidgetRow from './DashboardWidgetRow.vue'
import DashboardDragAndDropProvider from './DashboardDragAndDropProvider.vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'

export default BaseWidget.extend({
  name: 'DashboardWidget',
  components: {
    DashboardWidgetRow,
    DashboardDragAndDropProvider,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    hook: {
      type: Object as PropType<DashboardWidgetHook>,
      required: true,
    },
  },
  data() {
    return {
      unsubscribe: Function as UnsubscribeFunction,
    }
  },
  watch: {
    hook: {
      immediate: true,
      handler(hook: DashboardWidgetHook) {
        if (this.unsubscribe) this.unsubscribe()
        this.unsubscribe = hook.onChange((dashboard) => {
          this.$emit('change', dashboard)
        })
      },
    },
    renderedWidgets() {
      const renderedWidgets = Object.entries(this.renderedWidgets)
      const current = renderedWidgets.filter(
        ([_, isRendered]) => isRendered,
      ).length
      const total = renderedWidgets.length

      this.$emit('rendering', { current, total })

      const areAllWidgetsRendered = current === total

      if (areAllWidgetsRendered) {
        this.$emit('rendered')
      }
    },
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe()
  },
  computed: {
    showAddButton(): boolean {
      return !this.hook.state.widget.rows.length && this.editMode
    },
    renderedWidgets(): Record<string, boolean> {
      const createWidgetPosition = (row, rowIndex) =>
        row.columns.map((col, colIndex) => {
          const widget = Array.isArray(col.widgetLookup)
            ? col.widgetLookup[0] // We only cover for 1st tab for now
            : col.widgetLookup

          return widget.is ? `${rowIndex}-${colIndex}-0` : ''
        })

      const checkIfRendered = (position) => [
        position,
        this.hook.state.rendered?.[position] === true,
      ]

      const renderedWidgets = this.hook.widget.rows
        .flatMap(createWidgetPosition)
        .filter(Boolean)
        .map(checkIfRendered)

      return Object.fromEntries(renderedWidgets)
    },
  },
  methods: {
    viewCode() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        title: 'components.widget.code-preview.label',
        is: 'WidgetJsonViewer',
        props: {
          json: this.hook.state.widget,
        },
      })
    },
  },
})
</script>
