<template>
  <div>
    <v-row no-gutters>
      <v-col class="mr-3" cols="6">
        <json-field
          name="customId"
          prepend-inner-icon="mdi-barcode"
          :disabled="isImported"
        />
      </v-col>
      <v-col>
        <json-field name="type" />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="mr-3">
        <json-field name="firstName" :disabled="isImported" />
      </v-col>
      <v-col>
        <json-field name="lastName" :disabled="isImported" />
      </v-col>
    </v-row>
    <json-field name="jobTitle" :disabled="isImported" />
    <v-row no-gutters>
      <v-col class="mr-3">
        <json-field name="primaryPhone" :disabled="isImported" />
      </v-col>
      <v-col>
        <json-field name="secondaryPhone" :disabled="isImported" />
      </v-col>
    </v-row>
    <json-field
      name="email"
      prepend-inner-icon="email"
      :disabled="isImported"
    />
    <json-field name="notes" />
    <json-field name="relatedTo" :placeholder="typeToSearchLabel" />
    <AccountContextJsonField name="account" />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { VueWithFormHookInjection } from '@/helpers/components/types'

export default (Vue as VueWithFormHookInjection).extend({
  name: 'AssetPersonsForm',
  inject: ['formHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    isImported(): string {
      return this.itemHook?.getRawValue('isImported')
    },
    typeToSearchLabel(): string {
      return this.$t('common.type_to_search')
    },
  },
})
</script>
