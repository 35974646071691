// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportTemplateFeatureAssociationsTile from './ReportTemplateFeatureAssociationsTile.vue'
import ReportTemplateFeatureAssociationsColumns from './ReportTemplateFeatureAssociationsColumns'
import ReportTemplateFeatureAssociationsDetail from './ReportTemplateFeatureAssociationsDetail.vue'
import ReportTemplateFeatureAssociationsMetaCard from './ReportTemplateFeatureAssociationsMetaCard.vue'
import ReportTemplateFeatureAssociationsReference from './ReportTemplateFeatureAssociationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportTemplateFeatureAssociationsTile',
      ReportTemplateFeatureAssociationsTile,
    )
    Vue.component(
      'ReportTemplateFeatureAssociationsDetail',
      ReportTemplateFeatureAssociationsDetail,
    )
    Vue.component(
      'ReportTemplateFeatureAssociationsMetaCard',
      ReportTemplateFeatureAssociationsMetaCard,
    )
    Vue.component(
      'ReportTemplateFeatureAssociationsReference',
      ReportTemplateFeatureAssociationsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FEATURE_ASSOCIATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFeatureAssociationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FEATURE_ASSOCIATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportTemplateFeatureAssociationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FEATURE_ASSOCIATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFeatureAssociationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FEATURE_ASSOCIATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFeatureAssociationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FEATURE_ASSOCIATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFeatureAssociationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
