<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="$attrs.label"
        append-icon="mdi-calendar"
        readonly
        error-count="2"
        :messages="messagesArray"
        :error-messages="errorMessagesArray"
        v-bind="attrs"
        outlined
        v-on="on"
      >
        <template #message="{ key, message }">
          <div v-if="displayRequestedDays" class="days-information-container">
            <div class="days-information-message">
              <span>{{ totalRequestedLocale }}:</span>
              <span
                class="pl-1 font-weight-bold"
                :style="{ color: requestedDaysTextColor }"
              >
                {{ message }}
              </span>
              <TTooltipIcon
                v-if="showWarning"
                :icon="'mdi-information-outline'"
                :icon-color="'warning'"
                :open-direction="'bottom'"
                :tooltip-text="tooltipText"
              />
            </div>
          </div>
          <span
            v-if="displayErrorMessage"
            v-text="$t(errorMessagesArray[key])"
          />
        </template>
      </v-text-field>
    </template>
    <v-card max-height="410">
      <slot name="legend" />
      <v-date-picker
        v-model="dates"
        :locale="currentLocale"
        range
        no-title
        flat
        class="d-flex flex-row"
        :min="minDate"
        :events="pillDates"
        @update:picker-date="$emit('displayed-year-month', $event)"
        @input="fireInput"
      >
        <slot />
      </v-date-picker>
      <slot name="conflicts" />
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import TTooltipIcon from '@/tt-ui/components/TTooltipIcon.vue'

import {
  DateRangeInput,
  formatDateRange,
  getDifference,
} from '@/helpers/formats/dates'
import { sortDateAsc } from '@/helpers/formats/dates/utils'

import isNil from 'lodash/isNil'
import { updateDOM } from '@/helpers/dom'
import { CalendarPills } from '@/tt-entity-design/src/components/leave-requests/types'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { ErrorObject } from 'ajv'

export default Vue.extend({
  name: 'DateRangeField',
  components: { TTooltipIcon },
  props: {
    invalidTimeError: {
      type: Array as PropType<ErrorObject[]>,
      default: () => [],
    },
    leaveConflictError: {
      type: Array as PropType<ErrorObject[]>,
      default: () => [],
    },
    scheduledShiftsDates: {
      type: Object as PropType<CalendarPills>,
      default: () => ({}),
    },
    leaveRequestsDates: {
      type: Object as PropType<CalendarPills>,
      default: () => ({}),
    },
    formDates: {
      type: Object as PropType<DateRangeInput>,
      default: () => ({ startDate: null, endDate: null }),
    },
    availableBalance: {
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
    minDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menu: false,
      dates: [] as string[],
      currentSelected: null,
      unsubscribeFn: null as UnsubscribeFunction,
    }
  },
  computed: {
    dateRange(): DateRangeInput {
      const isFormDateListSet = Object.values(this.formDates).every(
        (date: string) => !isNil(date),
      )

      if (isFormDateListSet) return this.formDates

      return {
        startDate: this.sortedAscendingDates[0],
        endDate: this.sortedAscendingDates[1],
      }
    },
    dateRangeText(): string {
      return formatDateRange(this.dateRange, {
        ...this.$appContext.authModule.getUserPreferences(),
      })
    },
    sortedAscendingDates(): string[] {
      return sortDateAsc(this.dates)
    },
    currentLocale(): string {
      return this.$i18n.locale
    },
    totalRequestedLocale(): string {
      return this.$tc('tt-entity-design.leave-requests.total-requested')
    },
    requestedDaysAmount(): number | null {
      const { startDate, endDate } = this.dateRange
      if (startDate && endDate) {
        return Math.round(getDifference(startDate, endDate, 'hours', true) / 24)
      }

      return startDate ? 1 : null
    },
    displayRequestedDays(): boolean {
      return Boolean(
        this.errorMessagesArray.length === 0 && this.requestedDaysAmount,
      )
    },
    daysLocale(): string {
      return this.$tc(
        'tt-entity-design.leave-requests.days-amount',
        this.requestedDaysAmount,
      )
    },
    messagesArray(): string[] {
      if (this.requestedDaysAmount) {
        return [this.daysLocale]
      } else {
        return []
      }
    },
    errorMessagesArray(): string[] {
      if (this.leaveConflictError.length && !this.invalidTimeError.length) {
        return [this.leaveConflictError[0].message]
      } else if (this.invalidTimeError.length) {
        return [this.invalidTimeError[0].message]
      } else {
        return []
      }
    },
    showWarning(): boolean {
      const isRequestGreater = this.requestedDaysAmount > this.availableBalance

      return !isNaN(this.availableBalance) && isRequestGreater
    },
    requestedDaysTextColor(): string {
      return this.showWarning
        ? 'var(--v-warning-base)'
        : 'var(--v-ttPrimary-base)'
    },
    tooltipText(): string {
      return 'tt-entity-design.leave-management.leave-requests.request-balance-tooltip'
    },
    displayErrorMessage(): boolean {
      return this.errorMessagesArray.length > 0
    },
  },
  created() {
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      LayoutWindowEvent.DIALOG_CLOSED,
      () => (this.menu = true),
    )
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      LayoutWindowEvent.DIALOG,
      () => (this.menu = false),
    )
  },
  beforeDestroy() {
    if (this.unsubscribeFn) this.unsubscribeFn()
  },
  methods: {
    close(): void {
      this.menu = false
      this.$emit('blur')
    },
    async fireInput() {
      await updateDOM()
      this.$emit('input', this.sortedAscendingDates)
    },

    pillDates(date): string[] {
      return [
        this.leaveRequestsDates[date],
        this.scheduledShiftsDates[date],
      ].filter((date) => !!date)
    },
  },
})
</script>
<style scoped>
.days-information-container {
  min-height: 12px;
  margin-bottom: 8px;
  line-height: 10px;
  font-size: 12px;
  position: relative;
}

.days-information-message {
  position: absolute;
  display: flex;
  align-items: center;
  animation: slide-in 0.2s;
}

.days-information-message > span:last-of-type {
  font-weight: bold;
}

.warning-icon {
  font-size: 14px;
  line-height: 1px;
}

@keyframes slide-in {
  from {
    top: -10px;
  }
  to {
    top: 0;
  }
}
</style>
