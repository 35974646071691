<template>
  <div>
    <json-field name="type" />
    <json-field name="recurrentDate" />
    <json-field
      name="fixedDates"
      :item-props="{ allowedDates, disabled: loading, loading }"
    />
  </div>
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import Vue, { VueConstructor } from 'vue'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { CollectionQuery, FormHookProvider } from '@/tt-widget-components/types'
import { CollectionQueryResponse } from '@/tt-widget-factory'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { isSameDate } from '@/helpers/dates/isSameDate'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'

import { ExceptionTypeActions } from './types'
import { Resources } from '../../types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ExceptionTypesAddDates',
  inject: ['formHook'],
  data() {
    return {
      assignedDates: [] as string[],
      loading: false,
      unsubscribe: null as UnsubscribeFunction,
    }
  },
  computed: {
    debouncedFetchAssignedDates(): () => void {
      return debounce(() => this.fetchAssignedDates(), 100)
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    restrictedDates(): string[] {
      return [...this.assignedDates, ...this.selectedDates]
    },
    selectedDates(): string[] {
      return this.formHook().getPathValue('fixedDates')
    },
    typeId(): number {
      return this.itemHook.get('id')
    },
  },
  created() {
    this.unsubscribe = this.$eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ actionName, entityId, resource }) => {
        const isAddDateAction = () =>
          resource === Resources.EXCEPTION_TYPES &&
          actionName === ExceptionTypeActions.AddDates &&
          entityId === this.typeId

        const isDateUpdated = () => resource === Resources.EXCEPTION_TYPE_DATES

        if (isAddDateAction() || isDateUpdated()) {
          this.debouncedFetchAssignedDates()
        }
      },
    )

    this.debouncedFetchAssignedDates()
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe()
  },
  methods: {
    allowedDates(date: string): boolean {
      const isNotSame = (allowedDate: string) => !isSameDate(date, allowedDate)

      return !this.loading && this.restrictedDates.every(isNotSame)
    },
    fetchAssignedDates(): void {
      const query: CollectionQuery = {
        resource: Resources.EXCEPTION_TYPE_DATES,
        filters: [
          {
            attribute: 'type',
            operator: FilterOperatorType.EQUAL,
            value: this.typeId,
          },
        ],
      }
      const setAssignedDates = ({ items }: CollectionQueryResponse): void => {
        this.assignedDates = items.map(({ date }) => date)
      }
      const onError = (err: any): void => {
        this.$crash.captureException(err)
      }

      this.loading = true
      this.$appContext.widgetServices.resourceDataManager
        .getCollection(query)
        .then(setAssignedDates)
        .catch(onError)
        .finally(() => (this.loading = false))
    },
  },
})
</script>
