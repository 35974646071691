import {
  AccountFeatureAssociationResponse,
  ReportTemplateFeatureAssociationResponse,
} from '@/tt-entity-design/src/components/report-templates/types'
import { Resources } from '@/tt-entity-design/src/types'
import { AuthModule } from '@tracktik/tt-authentication'

export function getFeatureAssociationsForAccount(
  authModule: AuthModule,
  accountId: number,
): Promise<AccountFeatureAssociationResponse> {
  return authModule.getApi().get(Resources.ACCOUNTS, accountId, {
    extension: ['reportTemplateFeatures'],
    fields: [{ attribute: 'id' }],
  })
}

export function getFeatureAssociationsForReportTemplate(
  authModule: AuthModule,
  reportTemplateId: number,
): Promise<ReportTemplateFeatureAssociationResponse> {
  return authModule.getApi().get(Resources.REPORT_TEMPLATES, reportTemplateId, {
    fields: [{ attribute: 'associatedFeatures' }],
    include: ['associatedFeatures'],
  })
}
