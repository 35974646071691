<template>
  <div><slot /></div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import isEqual from 'lodash/isEqual'
import { FormHookProvider } from '@/tt-widget-components'
import { DispatchTaskFormServiceProvider } from './types'
import {
  DispatchCustomLocationInterface,
  LocationType,
  TaskTypeItems,
} from '../types'

/**
 * This component is used to set and monitor values of the form fields based on the values of the service.
 * It serves as the link between the formHook and the DispatchTaskFormServiceProvider.
 */

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormFieldSetter',
  inject: ['formHook', 'service'],
  computed: {
    reportTemplateId(): number | null {
      return this.service.getReportTemplateId()
    },
    priceTierId(): number | null {
      return this.service.getPriceTierId()
    },
    priority(): TaskTypeItems['priority'] | null {
      return this.service.getTaskTypePriority()
    },
    LocationType(): LocationType {
      return this.formHook().getPathValue('locationType')
    },
    clientId(): number | null {
      return this.formHook().getPathValue('client')
    },
    taskId(): number | null {
      return this.formHook().getPathValue('taskType')
    },
    isEditForm(): boolean {
      return this.service.getIsEditForm()
    },
    dispatchCustomLocation(): DispatchCustomLocationInterface {
      return this.service.getDispatchCustomLocation()
    },
  },
  watch: {
    reportTemplateId: {
      immediate: true,
      handler(): void {
        this.formHook().setObjectValue('reportTemplate', this.reportTemplateId)
      },
    },
    priceTierId: {
      immediate: true,
      handler(): void {
        if (!this.isEditForm) {
          this.formHook().setObjectValue('priceTier', this.priceTierId)
        }
      },
    },
    priority: {
      immediate: true,
      handler(): void {
        if (!this.isEditForm) {
          this.formHook().setObjectValue('priority', this.priority)
        }
      },
    },
    LocationType(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.service.setErrorsToWhiteList(newValue)
      }
    },
    clientId: {
      immediate: true,
      handler(id) {
        this.service.setClientId(id)
      },
    },
    taskId: {
      immediate: true,
      handler(id) {
        this.service.setTaskId(id)
      },
    },
    dispatchCustomLocation: {
      immediate: true,
      handler(location) {
        if (location) {
          this.formHook().setObjectValue('location', { ...location })
        }
      },
    },
  },
  created() {
    if (!this.service.getIsCustomLocation() && !this.service.getIsEditForm()) {
      this.formHook().setObjectValue(
        'locationType',
        LocationType.ACCOUNT_ADDRESS,
      )
    }
  },
})
</script>
