import { Payload } from '@tracktik/tt-browser-channel'
import { RESET_PRESENCE_TRACKING_TIMER } from './constants'

export enum UserActivityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  AWAY = 'AWAY',
}

export type PresenceTrackingManagerOption = {
  inactivityTime: number
}

export type PresenceTrackingManager = {
  init: (option?: PresenceTrackingManagerOption) => void
  destroy: () => void
  setUserStatus: (status: UserActivityStatus) => void
  getUserStatus: () => UserActivityStatus
}

export type PresenceTrackingBrowserChannelPayload = Payload<
  typeof RESET_PRESENCE_TRACKING_TIMER,
  { status: UserActivityStatus }
>

/**
 * Type to be used when injecting in Vue component
 */
export type PresenceStatusProvider = {
  presenceStatus: () => UserActivityStatus
}
