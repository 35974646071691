<template>
  <CollapsibleHeader v-if="showAffectedShifts" :opened="true">
    <template #title>
      <span
        class="pl-4 pr-2"
        v-text="$t('res.leave-requests.extensions.affectedShifts.labels.label')"
      />
      <LeaveRequestsAffectedShiftsCounter in-details />
    </template>
    <WidgetContainer style="height: 400px">
      <MetadataLoadingGuard>
        <WidgetFactory :widget="listWidget" />
      </MetadataLoadingGuard>
    </WidgetContainer>
  </CollapsibleHeader>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import LeaveRequestsAffectedShiftsCounter from './LeaveRequestsAffectedShiftsCounter.vue'
import { Shift } from './types'
import { ListWidgetModel, Filter } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { Resources } from '../../types'
import MetadataLoadingGuard from '@/tt-widget-views/components/MetadataLoadingGuard.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsDetailAffectedShifts',
  components: { LeaveRequestsAffectedShiftsCounter, MetadataLoadingGuard },
  inject: ['getItemHook'],
  computed: {
    shifts(): Shift[] {
      return this.getItemHook().get('extensions.affectedShifts.shifts')
    },
    shiftsTotal(): number {
      return this.getItemHook().get('extensions.affectedShifts.total')
    },
    showAffectedShifts(): boolean {
      return !!this.shiftsTotal
    },
    listFilter(): Filter {
      return {
        attribute: 'id',
        operator: FilterOperatorType.IN,
        value: this.shifts?.map((request) => request.id).join(',') ?? [],
      }
    },
    listWidget(): ListWidgetModel {
      return {
        is: 'ListWidget',
        title: '',
        query: {
          resource: Resources.SHIFTS,
          filters: [this.listFilter],
          returnCount: false,
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
})
</script>
