<template>
  <div>
    <div
      class="datatable-row--left-border"
      :class="level ? 'left-border--error' : ''"
    />
    <tt-attr-label name="escalationLevel" class="mr-2" />
    <tt-attr name="escalationLevel" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider } from '@/tt-app-layout'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

type VueWithInjections = VueConstructor<
  Vue & ActiveWindowProvider & ItemHookProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'SystemExceptionTicketsEscalationsAttr',
  inject: {
    activeWindow: { default: undefined },
    getItemHook: {},
  },
  computed: {
    level(): number {
      return this.getItemHook().getRawValue(`escalationLevel`)
    },
  },
})
</script>

<style scoped>
.datatable-row--left-border {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 !important;
}
.left-border--error {
  border-left: 4px solid var(--v-error-base);
  z-index: 1;
}
</style>
