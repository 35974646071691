import { WidgetSchema } from '@/tt-widget-factory'

const schedulerWidgetSchema: WidgetSchema = {
  type: 'object',
  title: 'Scheduler',
  required: ['title', 'is', 'query', 'attributeMap'],
  properties: {
    is: {
      enum: ['SchedulerWidget'],
    },
    title: {
      title: 'Title',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    query: {
      $ref: '#/definitions/CollectionQuery',
    },
    attributeMap: {
      $ref: '#/definitions/SchedulerAttributeMap',
    },
    toolbar: {
      $ref: '#/definitions/DefaultToolbar',
    },
    allowActions: {
      title: 'Allow Actions',
      type: 'boolean',
    },
    allowCreation: {
      title: 'Allow Creation',
      type: 'boolean',
    },
    contextFilters: {
      $ref: '#/definitions/WidgetContextFilters',
    },
  },
  definitions: {
    SchedulerAttributeMap: {
      title: 'Attribute Mapping',
      type: 'object',
      required: ['startAttribute', 'endAttribute'],
      properties: {
        titleAttribute: {
          title: 'Title Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        startAttribute: {
          title: 'Start Time Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        endAttribute: {
          title: 'End Time Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
      },
    },
  },
}

export default schedulerWidgetSchema
