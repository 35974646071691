import { UserActivityStatus } from './types'

export const statusColorDictionary: Record<UserActivityStatus, string> = {
  [UserActivityStatus.ACTIVE]: 'green darken-1',
  [UserActivityStatus.INACTIVE]: 'red darken-1',
  [UserActivityStatus.AWAY]: 'orange darken-1',
}

export const DEFAULT_STATUS_COLOR = 'grey'

export const BROWSER_CHANNEL_PRESENCE_TRACKING = 'presence-tracking'

export const RESET_PRESENCE_TRACKING_TIMER = 'reset-presence-tracking-timer'

/**
 * Default settings
 * inactivityTime: Inactivity duration before switching to AWAY mode (in milliseconds).
 */
export const INACTIVITY_TIME = 300000

/**
 * Default settings
 * countdownValue: Countdown duration before switching to INACTIVE mode (in milliseconds).
 */
export const COUNTDOWN_TIME = 30000

/**
 * Default settings
 * debounceDelay: Delay before resetting the timer after user activity (in milliseconds).
 */
export const DEBOUNCE_DELAY = 500
/**
 * Default settings
 * debounceMaxWait: Maximum delay before resetting the timer after user activity (in milliseconds).
 */
export const DEBOUNCE_MAX_WAIT = 10000
