<template>
  <div>
    <h4 class="mb-5 mx-4 mt-3">
      {{ groupTitle }}
    </h4>
    <json-form
      v-if="!loading"
      v-bind="form"
      v-model="model"
      @errors="formErrors = $event"
      @valid="formErrors = null"
    >
      <v-divider />
      <div class="mx-2">
        <v-btn-toggle
          v-if="!onlyExceptions"
          v-model="toggleOption"
          color="ttPrimary"
          mandatory
          class="mt-4"
        >
          <v-btn outlined>
            {{ translateKey('customize') }}
          </v-btn>
          <v-btn outlined>
            {{ translateKey('everyday.description') }}
          </v-btn>
        </v-btn-toggle>
        <div v-show="selectedOption === options.Customize">
          <SchedulingGroupDaySetupForm
            :key="selectedOption"
            :resource-name="resourceName"
            :holidays="holidays"
            :only-exceptions="onlyExceptions"
            :others-type-for-runsheet-events-enabled="
              othersTypeForRunsheetEventsEnabled
            "
            :others-type-for-runsheets-enabled="othersTypeForRunsheetsEnabled"
            :others-type-for-site-tasks-enabled="othersTypeForSiteTasksEnabled"
            :others="others"
            :exception-enabled="exceptionEnabled"
          />
        </div>
        <div v-show="selectedOption === options.Everyday">
          <json-object name="days">
            <SchedulingGroupDaySetupDayRow always-enabled day-name="EVERYDAY" />
          </json-object>
        </div>
      </div>
      <v-divider />
      <v-row class="mt-4 mx-2">
        <json-valid :errors="errors" :valid="!hasErrors" :show-text="false" />
        <v-spacer />
        <v-btn
          color="success"
          class="ma-3"
          raised
          :loading="saving"
          :disabled="!enableSaveButton"
          @click="save"
        >
          {{ $t('common.save.btn') }}
        </v-btn>
      </v-row>
    </json-form>
    <template v-else>
      <v-skeleton-loader
        v-for="index in 2"
        :key="index"
        tile
        type="card-heading"
      />
      <v-divider class="mt-4" />
      <v-skeleton-loader type="actions" />
    </template>
  </div>
</template>

<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import Vue, { PropType, VueConstructor } from 'vue'

import { BatchFileResponse } from '@/types'
import { cloneData } from '@/helpers/cloneData'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { EntityCreateIntent } from '@/tt-widget-entity-flow'
import {
  EntityItemHook,
  EntityItemViewInterface,
} from '@/tt-widget-entity-flow/EntityItemHook'
import { FormOptions } from '@tracktik/tt-json-schema-form'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { parseBatchFileErrorMessages } from '@/helpers/batch-file-request'
import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { Resources } from '@/tt-entity-design/src/types'
import { updateDOM } from '@/helpers/dom'

import SchemaHelper from '../helpers/schema-helper'
import SchedulingApiHelper from '../helpers/scheduling-group-api-helpers'
import SchedulingGroupDaySetupDayRow from '../../scheduling-groups/components/SchedulingGroupDaySetupDayRow.vue'
import SchedulingGroupDaySetupForm from '../../scheduling-groups/components/SchedulingGroupDaySetupForm.vue'
import {
  DayOptions,
  ExceptionType,
  SchedulingEntity,
  SchedulingEntityResource,
  SchedulingExceptionTypeEntity,
  SchedulingGroupEntity,
  SchedulingGroupBatch,
  DaysSetupOptions,
  PerformType,
} from '../types'
import { CalendarExceptionFeatureFlags } from '@/tt-entity-design/src/components/calendar-exceptions/types'

enum LoadingType {
  CalendarExceptions,
  Form,
  SchedulingExceptions,
  SchedulingItems,
}

type VueWithInjections = VueConstructor<
  Vue & { formOptions?: FormOptions } & CalendarExceptionFeatureFlags
>

export default (Vue as VueWithInjections).extend({
  name: 'SchedulingGroupDaysSetup',
  components: {
    SchedulingGroupDaySetupDayRow,
    SchedulingGroupDaySetupForm,
  },
  inject: {
    formOptions: { default: null },
    calendarExceptionFeatureFlags: {
      default: {
        othersTypeForRunsheetEventsEnabled: false,
        othersTypeForRunsheetsEnabled: false,
        othersTypeForSiteTasksEnabled: false,
      },
    },
  },
  props: {
    accountId: { type: Number, required: true },
    extraPropsToCascade: {
      type: Array<string>,
      default: () => [],
    },
    group: {
      type: Object as PropType<SchedulingGroupEntity>,
      required: true,
    },
    resourceName: {
      type: String as PropType<SchedulingEntityResource>,
      required: true,
    },
    exceptionEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Show only the holidays
     */
    onlyExceptions: { type: Boolean, default: false },
  },
  data() {
    return {
      effectiveGroupInfo: {} as SchedulingGroupEntity,
      savedCustomize: null,
      savedEveryday: null,
      formBuilder: null as DialogFormBuilder,
      formErrors: null as Record<string, unknown>,
      holidays: [] as ExceptionType[],
      others: [] as ExceptionType[],
      loadingMap: {
        [LoadingType.CalendarExceptions]: false,
        [LoadingType.Form]: false,
        [LoadingType.SchedulingItems]: false,
        [LoadingType.SchedulingExceptions]: false,
      } as Record<LoadingType, boolean>,
      model: {} as Partial<SchedulingGroupBatch>,
      saving: false,
      schedulingApiHelper: new SchedulingApiHelper(this.resourceName),
      schedulingExceptions: [] as SchedulingExceptionTypeEntity[],
      groupedOthersExceptions: {} as Record<string, ExceptionType[]>,
      schedulingItems: [] as SchedulingEntity[],
      schemaHelper: new SchemaHelper(this.resourceName),
      selectedOption: DaysSetupOptions.Customize,
    }
  },
  computed: {
    groupTitle(): string {
      return `${this.translateKey('title_form')} ${
        this.group.name
          ? this.group.name
          : this.translateKey('title_form_group')
      }`
    },
    toggleOption: {
      get(): number {
        return this.selectedOption === DaysSetupOptions.Customize ? 0 : 1
      },
      set(value: number) {
        this.selectedOption =
          value === 0 ? DaysSetupOptions.Customize : DaysSetupOptions.Everyday
      },
    },
    canViewExceptions(): boolean {
      return (
        this.$appContext.widgetServices.resourceMetaManager.hasResource(
          this.exceptionsResourceName,
        ) &&
        ResourcePermissionAuditor.canViewResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.exceptionsResourceName,
        )
      )
    },
    enableSaveButton(): boolean {
      return !this.saving && !this.hasErrors
    },
    errors(): Record<string, unknown> {
      if (!this.hasDays) {
        if (
          this.model &&
          this.model.holidays &&
          Object.values(this.model.holidays).every(
            (holiday) =>
              holiday.performType === PerformType.PERFORM_ON_EXCEPTION_DAY,
          )
        ) {
          return {
            ...this.formErrors,
          }
        }

        return {
          ...this.formErrors,
          days: [
            {
              dataPath: 'days',
              keyword: 'required',
              message: this.translateKey('errors.noDays'),
            },
          ],
        }
      }

      return {
        ...this.formErrors,
      }
    },
    form(): Record<string, unknown> | null {
      const state = this.formBuilder?.getState()
      if (!state) {
        return null
      }

      return this.schemaHelper.modifyGroupSchemaForCustomForm(
        state,
        this.holidays,
        this.others,
      )
    },
    hasDays(): boolean {
      if (this.onlyExceptions) {
        return (
          //If the model has holidays object
          !!this.model.holidays &&
          //And the object has at least 1 element
          Object.keys(this.model.holidays).length > 0 &&
          //And at least one elment is not null
          Object.values(this.model.holidays).some((holiday) => !!holiday)
        )
      }

      return (
        this.model?.days &&
        Object.keys(this.model.days).filter((day) => day != DayOptions.HOLIDAYS)
          .length > 0
      )
    },
    hasErrors(): boolean {
      return !isEmpty(this.errors)
    },
    itemHook(): EntityItemHook {
      const viewInterface: EntityItemViewInterface = {
        entityId: 0,
        entity: {
          id: 0,
          [this.schedulingApiHelper.getAccountKey()]: this.accountId,
        },
        resourceName: this.resourceName,
      }
      const itemHook = new EntityItemHook(this.$appContext, viewInterface, {
        fetchEnabled: false,
        hasFetched: true,
        fetchActions: false,
      })

      return itemHook
    },
    loading(): boolean {
      return (
        !this.form || Object.values(this.loadingMap).some((loading) => loading)
      )
    },
    options(): typeof DaysSetupOptions {
      return DaysSetupOptions
    },
    exceptionsResourceName(): string {
      return Resources.CALENDAR_EXCEPTIONS
    },
    translateScope(): string {
      return 'scheduling_group_create_wizard.days_setup'
    },
    othersTypeForRunsheetEventsEnabled(): boolean {
      return this.calendarExceptionFeatureFlags
        .othersTypeForRunsheetEventsEnabled
    },
    othersTypeForRunsheetsEnabled(): boolean {
      return this.calendarExceptionFeatureFlags.othersTypeForRunsheetsEnabled
    },
    othersTypeForSiteTasksEnabled(): boolean {
      return this.calendarExceptionFeatureFlags.othersTypeForSiteTasksEnabled
    },
  },
  watch: {
    group: {
      immediate: true,
      async handler() {
        this.resetForm()
        this.effectiveGroupInfo =
          this.extraPropsToCascade.length > 0
            ? await this.schedulingApiHelper.addExtraPropsToSchedulingGroup(
                this.group,
                this.extraPropsToCascade,
                this.$appContext.authModule.getApi(),
              )
            : this.group
      },
    },
    selectedOption() {
      this.resetModel()
    },
  },
  methods: {
    async fetchExceptions(): Promise<void> {
      await this.setLoading(LoadingType.CalendarExceptions, true)
      this.holidays = []

      this.holidays = await this.schedulingApiHelper.fetchAccountExceptions(
        this.accountId,
        this.$appContext.widgetServices,
      )

      const { exceptionsList, grouped } =
        await this.schedulingApiHelper.fetchOthersExceptions(
          this.accountId,
          this.$appContext.widgetServices,
        )

      this.others = exceptionsList
      this.groupedOthersExceptions = grouped

      this.setLoading(LoadingType.CalendarExceptions, false)
    },

    async fetchSchedulingExceptions(): Promise<void> {
      await this.setLoading(LoadingType.SchedulingExceptions, true)
      this.schedulingExceptions = []

      this.schedulingExceptions =
        await this.schedulingApiHelper.fetchSchedulingExceptions(
          this.group.id,
          this.$appContext.widgetServices,
        )
      this.setLoading(LoadingType.SchedulingExceptions, false)
    },

    async fetchSchedulingItems(): Promise<void> {
      this.setLoading(LoadingType.SchedulingItems, true)
      this.schedulingItems = []

      if (!this.onlyExceptions) {
        this.schedulingItems =
          await this.schedulingApiHelper.fetchSchedulingEntityByGroup(
            this.group.id,
            this.$appContext.widgetServices,
          )
      }
      this.setLoading(LoadingType.SchedulingItems, false)
    },
    handleSaveError(error: unknown, message: string): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message,
      })
      this.$emit('submit:error', error)
    },
    handleSaveSuccess(response: BatchFileResponse['success']): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.$t('common.operation_successful'),
      })
      this.$emit('submit:success', response)
    },
    async resetForm() {
      await this.setLoading(LoadingType.Form, true)
      this.formBuilder = null

      let requests = [this.fetchSchedulingItems()]

      if (
        this.canViewExceptions &&
        (this.exceptionEnabled ||
          this.resourceName === Resources.MOBILE_RUNSHEETS)
      ) {
        requests = [
          ...requests,
          this.fetchExceptions(),
          this.fetchSchedulingExceptions(),
        ]
      } else {
        this.holidays = []
        this.others = []
        this.schedulingExceptions = []
      }

      await Promise.all(requests)

      const intent = new EntityCreateIntent(this.$appContext)
      this.formBuilder = await intent.getDialogFormBuilder({
        formOptions: this.formOptions,
        resourceName: this.resourceName,
        itemHook: this.itemHook,
      })

      this.resetModel()
      await this.setLoading(LoadingType.Form, false)
    },
    resetModel() {
      if (this.selectedOption === DaysSetupOptions.Customize) {
        this.savedEveryday = cloneData(this.model)
        this.model = this.schedulingApiHelper.createCustomGroupBatch(
          this.effectiveGroupInfo,
          this.schedulingItems,
          this.schedulingExceptions,
          this.groupedOthersExceptions,
        )
        if (this.savedCustomize) {
          this.model = { ...this.model, ...this.savedCustomize }

          return
        }
      } else {
        this.savedCustomize = cloneData(this.model)
        this.model = this.schedulingApiHelper.createEverydayGroupBatch(
          this.group,
        )
        if (this.savedEveryday) {
          this.model = { ...this.model, ...this.savedEveryday }

          return
        }
      }
    },
    async save(): Promise<void> {
      this.saving = true
      try {
        let response: BatchFileResponse
        let responseSaveException: BatchFileResponse
        if (!this.onlyExceptions && this.hasDays) {
          response = await this.saveSchedulingItemData()
          if (!response) return // to review
        }

        if (
          !isEmpty(this.model.holidays) || // If there are exceptions to update
          this.schedulingExceptions.length // If there are exceptions to remove
        ) {
          const schedulingItemIds = response?.success.map((schedulingItem) =>
            Number(schedulingItem.resourceId),
          )

          responseSaveException = await this.saveExceptionsData(
            schedulingItemIds,
          )
          await this.saveOthersExceptionsData()
        }

        this.handleSaveSuccess([
          ...(response?.success || []),
          ...(responseSaveException?.success || []).filter(
            (resp) => resp.resource === Resources.MOBILE_SCHEDULES,
          ),
        ])
      } catch (error) {
        console.error(error.message)

        this.handleSaveError(
          error.response,
          this.$t('common.error_message') as string,
        )
      } finally {
        this.saving = false
      }
    },
    async saveSchedulingItemData(): Promise<BatchFileResponse | null> {
      const { persister } = this.$appContext.entityServices
      const { resourceDataManager } = this.$appContext.widgetServices

      try {
        return await this.schedulingApiHelper.saveItemsSchedules(
          this.model as SchedulingGroupBatch,
          { persister, resourceDataManager },
        )
      } catch (error) {
        const errors = parseBatchFileErrorMessages(error.response.data)
        this.handleSaveError(error.response, errors[0])

        return null
      }
    },
    async saveExceptionsData(
      schedulingItemIds: number[],
    ): Promise<BatchFileResponse | null> {
      const { persister } = this.$appContext.entityServices
      const { resourceDataManager } = this.$appContext.widgetServices

      return await this.schedulingApiHelper.saveSchedulingExceptionsSchedule(
        {
          data: this.model as SchedulingGroupBatch,
          schedulingItemIds,
          accountId: this.accountId,
        },
        { persister, resourceDataManager },
      )
    },
    async saveOthersExceptionsData() {
      const { persister } = this.$appContext.entityServices
      const { resourceDataManager } = this.$appContext.widgetServices

      await this.schedulingApiHelper.saveOthersExceptionsDataSchedule(
        {
          data: this.model as SchedulingGroupBatch,
          accountId: this.accountId,
        },
        { persister, resourceDataManager },
      )
    },
    async setLoading(type: LoadingType, value: boolean) {
      this.loadingMap[type] = value
      await updateDOM()
    },
    translateKey(key: string, vars: Record<string, string> = undefined) {
      return this.$t(`${this.translateScope}.${key}`, vars)
    },
  },
})
</script>
