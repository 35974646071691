<template>
  <v-col cols="auto" md="12" class="d-flex" style="min-width: 350px">
    <v-row justify="center" align-content="center">
      <v-col v-for="action in availableActions" :key="action" cols="auto">
        <tt-attr-action :action="action">
          <ConflictsBtnAction :action="action" />
        </tt-attr-action>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor } from 'vue'
import { Resources } from '../../types'
import { ConflictActions, ConflictActionType } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ConflictsBtnGroupActions',
  inject: ['getItemHook'],
  inheritAttrs: false,
  data() {
    return {
      unsubscribeFn: null as UnsubscribeFunction | null,
    }
  },
  computed: {
    actions(): ConflictActionType[] {
      return Object.values(ConflictActions)
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    availableActions(): string[] {
      const isActionAvailable = (action) =>
        this.getItemHook().isActionAvailable(action)

      return this.actions.filter(isActionAvailable)
    },
  },
  created() {
    this.getItemHook().needActions()
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ resource }) => {
        if (resource === Resources.CONFLICTS) {
          this.getItemHook().needActions({ force: true })
        }
      },
    )
  },
})
</script>
