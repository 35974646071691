import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import isString from 'lodash/isString'
import moment from 'moment-timezone'
import { ApiDateTimeFormats, ShortFormatOptions } from './types'
import { getDifference } from '@/helpers/formats/dates/dates'
import { defaultFormat } from '@/helpers/dates/formatDate'
import { isProduction } from '@/helpers/env/isProduction'
import i18n from '@/plugins/i18n'

export const getFormatWithoutTimezone = (momentFormat: string) =>
  momentFormat.replace('z', '').trim()

export const dateOrUnix = (
  input: string | number,
  { locale, timeZone }: UserPreferenceInterface,
) => {
  moment.locale(locale)

  const isFormatSupported = (format) => moment(input, format, true).isValid()

  const dateFormat = Object.values(ApiDateTimeFormats).find(isFormatSupported)

  if (dateFormat) {
    return dateFormat === ApiDateTimeFormats.ISO_8601
      ? moment.tz(input as string, ApiDateTimeFormats.ISO_8601, timeZone)
      : moment(input, dateFormat, true)
  }

  if (!isProduction) {
    console.warn('input date format not supported:', input)
  }

  const inputCast = isString(input) ? parseInt(input) : input

  if (moment.unix(inputCast).isValid()) {
    return moment.unix(inputCast)
  }

  if (!isProduction) {
    console.warn('input date might be invalid')
  }

  return moment.tz(input, timeZone)
}

export const convertPrefToMoment = (format = '', short = false): string => {
  const formatDictionary = {
    Y: short ? 'YY' : 'YYYY',
    m: short ? 'M' : 'MM',
    d: short ? 'D' : 'DD',
    H: short ? 'H' : 'HH',
    h: short ? 'h' : 'hh',
    i: 'mm',
    T: 'z',
  }

  const charsToReplace = Object.keys(formatDictionary).join()
  const regex = new RegExp(`[${charsToReplace}]`, 'g')

  return format.replace(regex, (key) => formatDictionary[key])
}

// No minutes, we remove the mintues
export const getShortFormat = ({
  momentObj,
  momentFormat,
  removeTimezone,
}: ShortFormatOptions) => {
  const formatWithoutTimezone = getFormatWithoutTimezone(momentFormat)
  const timezone = !removeTimezone && momentFormat.includes('z') ? ' z' : ''
  const minutes = momentObj.get('minute')

  const getHourOnlyFormat = () =>
    formatWithoutTimezone.includes('a') ? 'ha' : 'H'

  const shortFormat = !minutes ? getHourOnlyFormat() : formatWithoutTimezone

  return shortFormat + timezone
}

type HumanizedDurationOpts = {
  /**
   * Add a suffix like `in a few seconds` or `a few seconds ago`
   */
  suffix?: boolean
}

export const getSecondsHumanized = (
  seconds: number,
  options?: HumanizedDurationOpts,
): string =>
  moment.duration(seconds, 'seconds').humanize(!!options?.suffix, { h: 24 })

/**
 * Humanize a time difference (ie: `few seconds`, `a week`, ...)
 *
 * https://momentjscom.readthedocs.io/en/latest/moment/08-durations/03-humanize/
 */
export const getDurationHumanized = (
  startDateTime: string,
  endDateTime: string,
  options?: HumanizedDurationOpts,
): string => {
  const diff = getDifference(startDateTime, endDateTime, 'seconds')

  return getSecondsHumanized(diff, options)
}

export const sortDateAsc = (dates: string[], outputFormat = defaultFormat) => {
  return dates
    .map((d) => moment(d))
    .sort((a: moment.Moment, b: moment.Moment) => a.diff(b))
    .map((d) => moment(d).format(outputFormat))
}

export const formatedElapsedSeconds = (elapsedSeconds: number): string => {
  if (elapsedSeconds <= 60) {
    return `${elapsedSeconds} ${i18n.t('common.abbreviated-secondes')}`
  }

  const days = Math.floor(elapsedSeconds / (60 * 60 * 24))
  const hours = Math.floor((elapsedSeconds / (60 * 60)) % 24)
  const minutes = Math.floor((elapsedSeconds / 60) % 60)

  const timeUnits = [
    { unit: `${i18n.tc('common.days', days)}`, value: days },
    { unit: `${i18n.t('common.abbreviated-hours')}`, value: hours },
    { unit: `${i18n.t('common.abbreviated-minutes')}`, value: minutes },
  ]

  const filteredUnits = timeUnits.filter((unit) => unit.value > 0)

  if (filteredUnits.length === 0) {
    return `0 ${i18n.t('common.abbreviated-secondes')}`
  }

  const displayUnits = filteredUnits.map((unit) => {
    if (
      unit.unit === `${i18n.t('common.abbreviated-minutes')}` &&
      elapsedSeconds > 60 &&
      unit.value === minutes
    ) {
      return `${Math.round(unit.value)} ${unit.unit}`
    }

    return `${unit.value} ${unit.unit}`
  })

  return displayUnits.join(' ')
}

export const formatSecondsToTime = (rawSeconds: number): string => {
  const hours = Math.floor(rawSeconds / 3600)
  const minutes = Math.floor((rawSeconds % 3600) / 60)
  const seconds = Math.floor(rawSeconds % 60)
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`
}

export const getElapsedTime = (inputDateTime: string): string => {
  const inputDateTimeUTC = moment
    .utc(inputDateTime)
    .format('YYYY-MM-DDTHH:mm:ssZ')
  const nowUTC = moment.utc().format('YYYY-MM-DDTHH:mm:ssZ')
  const difference = getDifference(inputDateTimeUTC, nowUTC, 'seconds')

  // IF THE DIFFERENCE IS GREATER THAN 23:59:59 H,
  // WE RETURN THE DIFFERENCE IN HUMANIZED FORMAT
  // ELSE HH:MM:SS
  if (difference > 86400) {
    return getSecondsHumanized(difference)
  } else {
    return formatSecondsToTime(difference)
  }
}

/**
 * Check if a time string is valid
 *
 * Format: HH:mm:ss
 */
export const isValidTimeString = (time: string): boolean =>
  moment(time, 'HH:mm:ss', true).isValid()

/**
 * Check if the formatter string for a time is AP/PM or 24h.
 *
 * Both MomentJS and PHP uses the character "a" / "A" as the AM/PM indicator.
 * - https://momentjs.com/docs/#/displaying/format
 * - https://www.php.net/manual/en/datetime.format.php#refsect1-datetime.format-parameters
 */
export const isAmPmFormat = (timeFormat: string): boolean =>
  ['a', 'A'].some((amPm) => timeFormat.includes(amPm))
