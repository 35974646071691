<template>
  <EntityRelationListPreview
    :entity="entity"
    :entity-id="entityId"
    :item-hook="itemHook"
    :resource-name="resource"
    :hide-entity-preview-header="true"
    :allow-actions="true"
    :allow-search="false"
  />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SiteVendorServiceTypesTab',
  inject: ['getItemHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    entityId(): number {
      return this.itemHook.getRawValue('vendor.id')
    },
    resource(): string {
      return Resources.VENDORS
    },
    entity(): string {
      return 'serviceTypes'
    },
  },
})
</script>
