import { AuthModule } from '@tracktik/tt-authentication'
import { ContextAccount } from '@/tt-widget-factory/context/ContextAccountHandler'
import { DevConsole } from '@/plugins/DevConsole'

const STORAGE_KEY = 'TT_ASSET-HUB_CONTEXT-ACCOUNT'

type ServerUrl = string
type UserId = string
type StateKey = `${ServerUrl}_${UserId}`

/**
 * Service to read / write the context account into the local storage.
 */
export const ContextAccountPersister = ({
  authModule,
}: {
  authModule: AuthModule
}) => {
  const getUserKey = (): StateKey => {
    const serverUrl = authModule.getServerUrl()
    const userId = authModule.getUserId()

    return `${serverUrl}_${userId}`
  }

  /**
   * Reads and parses data from local storage associated with the specified storage key (server URL + user ID).
   *
   * @returns {Record<string, ContextAccount | null>} An object containing the parsed data from local storage.
   * If the data cannot be read or parsed, an empty object is returned.
   *
   * @throws Will log a warning to the DevConsole if an error occurs during the read or parse process.
   */
  const readFromStorage = (): Record<string, ContextAccount | null> => {
    try {
      const serializedData = localStorage.getItem(STORAGE_KEY)

      return serializedData ? JSON.parse(serializedData) : {}
    } catch (e) {
      DevConsole.warn(`Couldn't read persisted data from local storage`, e)

      return {}
    }
  }

  /**
   * Writes the provided data to local storage under a specific key (server URL + user ID).
   *
   * @param data - An object where the keys are strings and the values are either `ContextAccount` objects or `null`.
   *
   * @throws Will log a warning to the console if the data cannot be serialized or written to local storage.
   */
  const writeToStorage = (data: Record<string, ContextAccount | null>) => {
    try {
      const serializedData = JSON.stringify(data)
      localStorage.setItem(STORAGE_KEY, serializedData)
    } catch (e) {
      DevConsole.warn(`Couldn't write data to local storage`, e)
    }
  }

  return {
    save: (accountInfo?: ContextAccount) => {
      const key = getUserKey()
      const storageData = readFromStorage()

      storageData[key] = accountInfo || null // Update or clear the specific key

      writeToStorage(storageData)
    },
    get: (): ContextAccount | null => {
      try {
        const key = getUserKey()
        const storageData = readFromStorage()

        return storageData[key] || null
      } catch (e) {
        DevConsole.warn(
          `Couldn't read persisted context account from local storage`,
          e,
        )

        return null
      }
    },
  }
}
