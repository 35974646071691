import { enforceLeadingZero } from '@/helpers/dates/time'

/**
 * Helper file for the TimePickerDropdown component
 */

/**
 * UI items type for the dropdowns
 */
export type DropDownItem = {
  text: string
  value: number | string
}

export type AM_PM = 'AM' | 'PM'

/**
 * Creates a list of hours in 24h format
 */
export const createHoursFormat24 = (): DropDownItem[] => {
  return [...Array(24)].map((_, index) => ({
    text: `${enforceLeadingZero(index)}`,
    value: index,
  }))
}

/**
 * Creates a list of hours in 12h format
 */
export const createHoursFormat12 = (): DropDownItem[] => {
  return [...Array(12)].map((_, index) => {
    const hour12 = index || 12

    return {
      text: `${hour12}`,
      value: hour12,
    }
  })
}

/**
 * Creates a list of minutes
 */
export const create60Items = () =>
  [...Array(60)].map((_, index) => ({
    text: `${enforceLeadingZero(index)}`,
    value: index,
  }))

/**
 * Given an hour in 24h format and a minute, returns a time string with seconds.
 *
 * ie: 23:59:00
 */
export const createTimeString = (
  hour: number,
  minute: number,
  seconds: number,
): string => {
  const hourString = enforceLeadingZero(hour)
  const minuteString = enforceLeadingZero(minute)
  const secondsString = enforceLeadingZero(seconds)

  return `${hourString}:${minuteString}:${secondsString}`
}

/**
 * Given an hour in 12h format and a AM/PM, returns an hour in 24h format.
 */
export const convertTo24h = (hour12: number, amPm: AM_PM): number => {
  const modulo12 = hour12 % 12

  return amPm === 'PM' ? modulo12 + 12 : modulo12
}

/**
 * Given an hour in 24h format, returns an hour in 12h format and AM/PM.
 */
export const convertTo12h = (
  hour24: number,
): { hour12: number; amPm: AM_PM } => ({
  hour12: hour24 % 12 || 12,
  amPm: hour24 < 12 ? 'AM' : 'PM',
})
