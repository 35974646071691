<template>
  <div class="fill-height d-flex flex-column" @mousemove="onMouseMove">
    <v-app-bar
      class="toolbar1"
      style="position: sticky; top: 0; z-index: 5"
      :style="{ opacity: appBarVisible ? 1 : 0 }"
      @mouseenter="isHoveringAppBar = true"
      @mouseleave="isHoveringAppBar = false"
    >
      <v-app-bar-nav-icon>
        <v-btn icon @click="$emit('exit')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <WidgetIcon class="mr-2" :widget-type="widget.is" />
      <v-toolbar-title style="opacity: 0.5">
        {{ title }}
      </v-toolbar-title>

      <v-spacer />

      <WidgetContextToolbar
        :default-filter-values="defaultFilterValues"
        :show-title="false"
        :hook="hook"
      />
      <FullScreenExtension />
      <DarkLightExtension />
    </v-app-bar>

    <widget-factory
      v-if="widget"
      :widget="widget"
      @update:hook="hook = $event"
    />
  </div>
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import Vue, { PropType, VueConstructor } from 'vue'

import LayoutManager from '@/tt-app-layout/LayoutManager'
import { EnablePresentationMode } from '@/plugins/o11n'
import { EntityFilterEvent } from '@/tt-entity-filter/types'
import { Filter } from '@/tt-widget-components'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'
import { WidgetReference, WidgetStoreInterface } from '@/tt-widget-factory'
import { WidgetHookInterface } from '@/tt-widget-factory/WidgetHookInterface'

type Widget = {
  uid: string
  provider: string
} & WidgetReference

type VueWithLayoutManager = VueConstructor<
  Vue & { layoutManager: LayoutManager }
>

export default (Vue as VueWithLayoutManager).extend({
  name: 'PresentationView',
  inject: ['layoutManager'],
  props: {
    widgetStoreModel: {
      type: Object as PropType<WidgetStoreInterface>,
      required: true,
    },
  },
  data() {
    return {
      isHoveringAppBar: false,
      isMenuOpen: false,
      isMoving: false,
      unsubscribe: [] as UnsubscribeFunction[],
      hook: null as WidgetHookInterface | null,
    }
  },
  computed: {
    onMouseStopMoving(): any {
      return debounce(() => {
        this.isMoving = false
      }, 2000)
    },
    appBarVisible(): boolean {
      return this.isHoveringAppBar || this.isMenuOpen || this.isMoving
    },
    defaultFilterValues(): Filter[] {
      return cloneDeep(this.widget?.contextFilters?.defaults ?? [])
    },
    title(): string {
      return this.widget ? this.widget.title : ''
    },
    widget(): Widget {
      return {
        provider: this.widgetStoreModel.provider,
        ...this.widgetStoreModel.widget,
        uid: this.widgetStoreModel.uid,
      }
    },
  },
  beforeDestroy() {
    this.revertLayout()

    if (document.fullscreenElement) {
      document.exitFullscreen()
    }

    this.unsubscribe.forEach((fx) => fx())
  },
  mounted() {
    this.listenToMenuStatus()

    this.setPresentationLayout()

    if (document.documentElement.requestFullscreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          this.$analytics.track(EnablePresentationMode.create())
        })
        .catch(() => {
          console.warn('Could not activate fullscreen.')
        })
    }
  },
  methods: {
    listenToMenuStatus(): void {
      this.unsubscribe.push(
        this.$eventManager.subscribeEvent(
          EntityFilterEvent.TOOLBAR_MENU_ACTIVE,
          (status: boolean) => {
            this.isMenuOpen = status
          },
        ),
      )
    },
    onMouseMove(): void {
      this.isMoving = true
      this.onMouseStopMoving()
    },
    setPresentationLayout(): void {
      this.layoutManager.configuration.sideMenu.hidden = true
      this.$eventManager.dispatchEvent(LayoutWindowEvent.TOP_BAR_CLOSE, {})
    },
    revertLayout(): void {
      this.layoutManager.configuration.sideMenu.hidden = false
      this.$eventManager.dispatchEvent(LayoutWindowEvent.TOP_BAR_OPEN, {})
    },
  },
})
</script>

<style scoped>
.v-application .toolbar3 {
  background-color: var(--v-toolbar1-base) !important;
  border-color: var(--v-toolbar1-base) !important;
}
.v-application .theme--light.toolbar1,
.v-application .theme--light.toolbar3 {
  background-color: var(--v-toolbar3-base) !important;
  border-color: var(--v-toolbar3-base) !important;
}
</style>
