<template>
  <v-textarea
    v-model="textareaValue"
    v-bind="{ ...vuetifyDefaults, placeholder, label, errorMessages }"
    :disabled="isReadOnly"
    height="90"
    no-resize
  />
</template>

<script lang="ts">
import BaseInput from '@/tt-widget-components/components/BaseInput'
import {
  getFieldAbsoluteName,
  vuetifyDefaults,
} from '@tracktik/tt-json-schema-form'
import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
import { VueConstructor } from 'vue'

type VueWithInjections = VueConstructor<
  InstanceType<typeof BaseInput> & FormHookProvider & NamespaceProvider
>

export default (BaseInput as VueWithInjections).extend({
  name: 'TextAreaField',
  inject: ['formHook', 'namespace'],
  computed: {
    vuetifyDefaults: () => vuetifyDefaults,
    absoluteName(): string {
      return getFieldAbsoluteName(this)
    },
    isReadOnly(): boolean {
      return this.formHook()?.isReadOnly() || false
    },
    textareaValue: {
      get(): string {
        return this.value
      },
      set(newVal: string) {
        this.formHook().setObjectValue(this.absoluteName, newVal, {
          debounce: true,
        })
      },
    },
  },
})
</script>
