<template>
  <tt-tile>
    <tt-attr slot="title" name="operationCenter" />
    <template slot="line2">
      <tt-attr-label name="level" class="mr-2" />
      <tt-attr name="level" />
    </template>
    <tt-attr slot="line3" name="reason" />
    <template slot="actionRight">
      <TChipValue
        :text="humanizedDiff"
        chip-class="font-weight-bold red--text text--darken-2 red lighten-5"
      />
    </template>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getDurationHumanized } from '@/helpers/formats/dates/utils'
import TChipValue from '@/tt-ui/components/TChipValue.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCenterEscalationTicketsTile',
  components: {
    TChipValue,
  },
  inject: ['getItemHook'],
  computed: {
    createdOn(): string {
      return this.getItemHook().getRawValue('createdOn')
    },
    humanizedDiff(): string {
      if (!this.createdOn) return 'N/A'

      return getDurationHumanized(this.createdOn, Date().toString())
    },
  },
  created() {
    this.getItemHook().addAttribute('createdOn')
  },
})
</script>
