<template>
  <div>
    <!-- we deactivate the region context on this field, to get all regions showing -->
    <json-field
      :model-context="{ regionAttribute: false }"
      name="location.region"
      class="pt-3"
      hide-details
    />

    <div v-if="inferredFromAddress" class="d-flex justify-end align-center">
      <div
        class="caption text-uppercase font-weight-bold success--text pa-1"
        v-text="$t('tt-entity-design.dispatch-tasks.inferred-from-address')"
      />
      <v-icon class="caption" color="success" small> mdi-check-circle </v-icon>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import {
  getLowestChildRegionsFromList,
  convertRegionsToTreeItems,
} from '@/tt-widget-components/components/treeview/utils'
import { FormHookProvider } from '@/tt-widget-components/types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'DispatchTasksFormLocationRegion',
  inject: ['formHook'],
  props: {
    longitude: {
      type: Number,
      default: 0,
    },
    latitude: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      inferredFromAddress: false,
    }
  },
  computed: {
    latitudeLongitudeString(): string {
      if (!this.latitude || !this.longitude) return ''

      return `${this.latitude},${this.longitude}`
    },
  },
  watch: {
    latitudeLongitudeString: {
      handler() {
        this.inferRegionFromLocation()
      },
    },
  },
  methods: {
    async inferRegionFromLocation() {
      this.inferredFromAddress = false
      this.formHook().setObjectValue('location.region', null)

      if (!this.latitudeLongitudeString) return

      this.loading = true

      const regionIds = await this.$auth
        .getApi()
        .getAll<{ id: number }>('regions', {
          filters: [
            {
              attribute: 'byCoordinates',
              operator: 'EQUAL',
              value: this.latitudeLongitudeString,
            },
          ],
        })
        .then((response) => response.items.map((item) => String(item.id)))
        .catch((err) => {
          console.error(
            'Could not fetch regions from coordinates:',
            this.latitudeLongitudeString,
            err,
          )

          return []
        })

      this.loading = false
      const regionManager = this.$appContext.regionManager

      const treeItems = convertRegionsToTreeItems(
        regionManager,
        regionManager.getAllUserRegions(),
      )

      const lowestChildRegionAtCoordinates = getLowestChildRegionsFromList(
        treeItems,
        regionIds,
      )

      /**
       * If more than one region is found at the coordinates, we let the user decide
       */
      const exactlyOneRegionFound = lowestChildRegionAtCoordinates.length === 1

      if (exactlyOneRegionFound) {
        const newRegionId = Number(lowestChildRegionAtCoordinates[0])

        this.formHook().setObjectValue('location.region', newRegionId)

        this.inferredFromAddress = true
      }
    },
  },
})
</script>
