import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import pickBy from 'lodash/pickBy'

import {
  Filter,
  FiltersMap,
  FilterOptions,
  CustomFilter,
  FilterHandler,
} from '@/tt-widget-components'
import { isFilterValid } from '@/tt-entity-filter/util'
import isString from 'lodash/isString'

const getFilterWithAttribute = (filter: Filter, attribute: string): Filter => ({
  ...filter,
  attribute: filter.attribute || attribute,
})

export const getCustomFilterName = (
  customFilter: string | CustomFilter,
): string => {
  return isString(customFilter) ? customFilter : customFilter.filterName
}

const getManagedFilterNames = (
  options: FilterOptions,
  filterHandlers: FilterHandler[] = [],
): string[] => {
  const filterNames = options.filters?.map((f) => f.attributeName) ?? []

  const customFilterNames =
    options.customFilters?.map(getCustomFilterName) ?? []

  const customFilterButtonName = filterHandlers.map((f) => f.attribute)

  return [...filterNames, ...customFilterNames, ...customFilterButtonName]
}

// Replace the values of the managed filters without touching other filters
export const mergeManagedFilters = (
  previousFilters: FiltersMap,
  managedFilters: FiltersMap,
  options: FilterOptions,
  filterHandlers: FilterHandler[] = [],
): FiltersMap => {
  const addFilterByName = (
    currentFilters: FiltersMap,
    filterName: string,
  ): FiltersMap => ({
    ...currentFilters,
    [filterName]:
      managedFilters[filterName] && isFilterValid(managedFilters[filterName])
        ? getFilterWithAttribute(managedFilters[filterName], filterName)
        : null,
  })

  const managedFilterNames = getManagedFilterNames(options, filterHandlers)
  const fullManagedFilters = managedFilterNames.reduce(addFilterByName, {})
  const fullFilters = { ...previousFilters, ...fullManagedFilters }
  const cleanFilters = omitBy(fullFilters, isNil)

  return cleanFilters
}

/**
 * Given a list of filters, returns only the filters the user is allowed to change (defined in the filter options toolbar)
 */
export const pickManagedFilters = (
  filters: FiltersMap,
  options: FilterOptions,
  filterHandlers: FilterHandler[] = [],
): FiltersMap => {
  const filterNames = getManagedFilterNames(options, filterHandlers)

  return pickBy(filters, (_, filterName) => filterNames.includes(filterName))
}
