<template>
  <div>
    <tt-attr-row-card v-bind="$props">
      <tt-attr-row name="customId" value-css-class="color-pill" />
    </tt-attr-row-card>

    <tt-attr-row-card>
      <tt-attr-row name="category" />
      <tt-attr-row v-if="!isRegion" name="manufacturer" />
      <tt-attr-row v-if="!isRegion" name="supplier" />
    </tt-attr-row-card>

    <tt-attr-row-card>
      <tt-attr-row name="isBundle" />
      <tt-attr-row name="isCheckable" />
      <tt-attr-row name="isSerialNumberUnique" />
    </tt-attr-row-card>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { getContextAccountInfo } from '@/apps/app.tracktik.assets/helpers'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetTypesDetail',
  inject: ['getItemHook'],
  computed: {
    isRegion(): boolean {
      return getContextAccountInfo(this.$appContext).isRegion
    },
  },
})
</script>
