<template>
  <json-field v-bind="fieldBinding" />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHook, getFieldAbsoluteName } from '@tracktik/tt-json-schema-form'
import { ContextAccountHandler } from '@/tt-widget-factory/context/ContextAccountHandler'

export default (
  Vue as VueConstructor<
    Vue & { formHook: () => FormHook } & { namespace: string }
  >
).extend({
  name: 'AccountContextJsonField',
  inject: ['formHook', 'namespace'],
  props: {
    name: { type: String, required: true },
  },
  computed: {
    selectedAccountId(): number | undefined {
      return ContextAccountHandler(this.$appContext).get()
    },
    fieldBinding(): Record<string, any> {
      if (this.selectedAccountId) {
        return {
          ...this.$props,
          ...this.$attrs,
          as: 'input',
          type: 'hidden',
        }
      } else {
        return {
          ...this.$props,
          ...this.$attrs,
        }
      }
    },
    absoluteName(): string {
      return getFieldAbsoluteName(this)
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.selectedAccountId) {
        this.formHook().setObjectValue(
          this.absoluteName,
          this.selectedAccountId,
        )
      }
    }, 1000)
  },
})
</script>
