<template>
  <div class="d-flex flex-column">
    <v-tabs
      v-model="tab"
      slider-color="orange"
      class="small-tabs mb-2 flex-grow-0"
      dense
      small
      short
    >
      <v-tab href="#tab-form" v-text="$t('asset-types.tabs.main')" />
      <v-tab
        href="#tab-instructions"
        v-text="$t('asset-types.tabs.instructions')"
      />
      <v-tab
        v-if="canShowAdditionalTabs && isAttributeAvailable('reportTemplates')"
        href="#tab-incident-reports"
        v-text="$t('asset-types.tabs.incident-reports')"
      />
      <v-tab
        v-if="canShowAdditionalTabs && isAttributeAvailable('customAttributes')"
        href="#tab-customization"
        v-text="$t('asset-types.tabs.customization')"
      />
    </v-tabs>

    <v-tabs-items v-model="tab" class="pa-3">
      <!-- tab: form -->
      <v-tab-item :value="'tab-form'" class="tt-tab-content flex-grow-1">
        <v-row no-gutters>
          <v-col class="mr-3" cols="8">
            <json-field
              name="name"
              :disabled="disableField('name')"
              prepend-inner-icon="label"
            />
          </v-col>
          <v-col>
            <json-field
              name="customId"
              :disabled="disableField('customId')"
              prepend-inner-icon="mdi-barcode-scan"
              cols="4"
            />
          </v-col>
        </v-row>
        <json-field
          v-show="!disableField('category')"
          name="category"
          :placeholder="typeToSearchLabel"
          :disabled="disableField('category')"
          :model-context="{ accountAttribute: false }"
        />
        <json-field
          v-if="!isRegion"
          name="manufacturer"
          :placeholder="typeToSearchLabel"
          :disabled="disableField('manufacturer')"
          :model-context="{ accountAttribute: false }"
        />

        <json-field
          name="type"
          :disabled="disableField('type')"
          value="STATIC"
          as="Const"
        />
        <div class="mb-7">
          <json-field
            name="isBundle"
            :disabled="disableField('isBundle')"
            as="v-switch"
            color="green"
            hide-details
          />

          <json-field
            name="beContainedIntoBundle"
            :disabled="disableField('beContainedIntoBundle')"
            as="v-switch"
            color="green"
            hide-details
          />

          <json-field
            name="isCheckable"
            :disabled="disableField('isCheckable')"
            as="v-switch"
            color="green"
            hide-details
          />

          <json-field
            name="isSerialNumberUnique"
            :disabled="disableField('isSerialNumberUnique')"
            as="v-switch"
            color="green"
            hide-details
          />

          <!-- if it's editing, this field is shown in the first tab -->
          <div v-if="isEditing">
            <json-field
              name="isApprovalRequired"
              :disabled="disableField('isApprovalRequired')"
              as="VSwitch"
              color="green"
            />
          </div>
          <!-- / if it's editing, this field is shown in the first tab -->
        </div>

        <AccountContextJsonField
          name="account"
          :disabled="disableField('account')"
        />
        <json-field
          v-if="!isRegion"
          name="supplier"
          :placeholder="typeToSearchLabel"
          :disabled="disableField('supplier')"
          :model-context="{ accountAttribute: false }"
        />
      </v-tab-item>
      <!-- / tab: form -->

      <!-- tab: incident reports -->
      <v-tab-item
        v-if="!isEditing"
        :value="'tab-incident-reports'"
        class="tt-tab-content flex-grow-1"
      >
        <div class="d-flex flex-grow-1 flex-column">
          <div class="tt-reports-list">
            <json-field
              name="reportTemplates"
              :disabled="disableField('reportTemplates')"
              :item-props="{ blockCreation: true }"
            />
          </div>
          <json-field
            name="isApprovalRequired"
            :disabled="disableField('isApprovalRequired')"
            as="VSwitch"
            color="green"
          />
        </div>
      </v-tab-item>
      <!-- / tab: incident reports -->

      <!-- tab: customization -->
      <v-tab-item
        :value="'tab-customization'"
        class="tt-tab-content flex-grow-1"
      >
        <json-field
          name="customAttributes"
          :disabled="disableField('customAttributes')"
          :item-props="{ blockCreation: true }"
        />
      </v-tab-item>
      <!-- / tab: customization -->

      <!-- Instructions Tab -->
      <v-tab-item
        :value="'tab-instructions'"
        class="tt-tab-content flex-grow-1"
      >
        <json-field
          name="instructions"
          :disabled="disableField('instructions')"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { FormHookProvider } from '@/tt-widget-components'
import { Resources } from '../../types'
import { Attribute } from '../../schema-types'
import { getContextAccountInfo } from '@/apps/app.tracktik.assets/helpers'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

type AssetTypeAttributes = Attribute<'asset-types'>
const IS_BUNDLE: AssetTypeAttributes = 'isBundle'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetTypesForm',
  inject: ['formHook'],
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    isEditing(): boolean {
      // if we have the id, it means that we're editing an entity, otherwise it's a creation and we should not set any default value
      return Boolean(this.formHook().getPathValue('id'))
    },
    isRegion(): boolean {
      return getContextAccountInfo(this.$appContext).isRegion
    },
    canShowAdditionalTabs(): boolean {
      return !this.isEditing && !this.isRegion
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    importedProperties(): Record<string, boolean> {
      return this.itemHook.data?.importedProperties
    },
    typeToSearchLabel(): string {
      return this.$t('common.type_to_search')
    },
  },
  created() {
    const getIsBundleValue = () => this.formHook().getPathValue(IS_BUNDLE)
    const initialValueIsBundle = getIsBundleValue()
    const askFormConfirmation = (newValue) => {
      if (newValue === false) this.confirmDisablingBundle()
    }
    this.itemHook.addAttribute('importedProperties')

    // If `isBundle` was `true`, and it changes to `false`, we ask for confirmation
    if (initialValueIsBundle) this.$watch(getIsBundleValue, askFormConfirmation)

    // If it's not in edit mode, the agreement on the Report Forms tab should be on by default
    if (!this.isEditing) {
      this.setAgreementByDefault()
    }
  },
  methods: {
    confirmDisablingBundle(): Promise<void> {
      const requestConfirmation = () =>
        new Promise<void>((resolve, reject) => {
          this.$appContext.eventManager.dispatchEvent(
            LayoutWindowEvent.CONFIRM,
            {
              message: this.$tc(
                'asset-types.confirmation-dialog.deactivating-is-bundle',
              ),
              accept: () => resolve(),
              cancel: () => reject(),
            },
          )
        })

      const activateIsBundle = () =>
        this.formHook().setObjectValue(IS_BUNDLE, true)

      return requestConfirmation().catch(activateIsBundle)
    },
    setAgreementByDefault(): void {
      this.formHook().setObjectValue('isApprovalRequired', true)
    },
    isAttributeAvailable(attributeName: string): boolean {
      return !!this.$appContext.widgetServices.resourceMetaManager.getAttribute(
        Resources.ASSET_TYPES,
        attributeName,
      )
    },
    isImportedProperty(fieldName: string): boolean {
      return this.importedProperties?.[fieldName] ?? false
    },
    disableField(fieldName: string): boolean {
      return this.isEditing && this.isImportedProperty(fieldName)
    },
  },
})
</script>

<style lang="scss" scoped>
.tt-reports-list {
  flex-grow: 1;
  max-height: 300px;
  overflow-y: auto;
}
.tt-tab-content {
  min-height: 300px;

  &:not([style*='display:none']) {
    display: flex;
    flex-direction: column;
  }
}
.tt-empty-list {
  min-height: 250px;
}
.tt-btn-primary {
  background-color: #f4f4f4;
  // remove the default opacity of the text element
  &.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(
      :hover
    ) {
    ::v-deep .v-btn__content {
      opacity: 1;
    }
  }

  // change the default text transformation
  ::v-deep .v-btn__content {
    text-transform: none;
  }
}

::v-deep .v-input--selection-controls.report-template {
  margin-top: 0;
  padding-top: 0;
}
</style>
