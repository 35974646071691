import { PluginFunction } from 'vue'

const ReportCompleteView = () =>
  import(
    /* webpackChunkName: "ReportCompleteView" */
    './views/ReportCompleteView.vue'
  )
const ReportEditView = () =>
  import(
    /* webpackChunkName: "ReportEditView" */
    './views/ReportEditView.vue'
  )
const ReportCreateObject = () =>
  import(
    /* webpackChunkName: "ReportCreateObject" */
    './components/ReportCreateObject.vue'
  )

const ReportCreateNameColumn = () =>
  import('./components/ReportCreateNameColumn.vue')

export { ReportCompleteView, ReportEditView }

export const ReportsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('ReportCompleteView', ReportCompleteView)
  Vue.component('ReportEditView', ReportEditView)
  Vue.component('ReportCreateObject', ReportCreateObject)
  Vue.component('ReportCreateNameColumn', ReportCreateNameColumn)
}
