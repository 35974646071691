<template>
  <div>
    <TSearchAddress
      v-if="!hideSearchField"
      @clear="$emit('clear')"
      @input="setCoordinates"
    />

    <template v-if="isValidCoordinates">
      <MapMarkerList
        v-if="mapManager"
        :map-manager="mapManager"
        :markers="formattedMarkers"
        icon="mdi-map-marker"
        color="ttPrimary"
      >
        <template #default="{ markerManager, marker, icon, color }">
          <TMapMarker
            v-bind="{
              markerManager,
              marker,
              icon,
              color,
            }"
          />
        </template>
      </MapMarkerList>

      <MapBaseView
        :style="{ height: `${height}px` }"
        :params="baseMapParams"
        @loaded="saveMapManager"
      />
    </template>

    <v-card v-else class="mt-2 mb-11" flat outlined>
      <v-img :src="thumbnail" height="300px" contain eager />
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { isValidCoordinates } from '@/tt-widget-components/components/map/is-valid-coordinates'
import {
  MapBaseView,
  MapMarkerList,
  MapManager,
  MapManagerParams,
} from '@tracktik/tt-maps'
import TMapMarker from '@/tt-ui/components/TMapMarker.vue'
import '@tracktik/tt-maps/style'
import TSearchAddress from './TSearchAddress.vue'
import { Coordinates, Geocoding } from '@tracktik/tt-geo-proxy'
import { Marker } from '@tracktik/tt-maps'
import { WidgetName } from '@/tt-widget-components/lib/names'

export default Vue.extend({
  name: 'MapCoordinateSelector',
  components: {
    MapBaseView,
    MapMarkerList,
    TSearchAddress,
    TMapMarker,
  },
  props: {
    coordinates: {
      type: Object as PropType<Coordinates>,
      default: null,
    },
    hideSearchField: {
      type: Boolean,
      default: false,
    },
    preventClick: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      mapManager: null as MapManager | null,
    }
  },
  computed: {
    thumbnail(): string {
      const config =
        this.$appContext.widgetServices.widgetManager.getWidgetByName(
          WidgetName.MAP_WIDGET,
        )

      return config.config.thumbnail
    },
    formattedMarkers(): Marker[] {
      if (!this.isValidCoordinates) return []

      return [
        {
          id: Math.random(),
          position: [this.coordinates.longitude, this.coordinates.latitude],
          active: false,
        },
      ]
    },
    baseMapParams(): MapManagerParams {
      return {
        development: false,
      }
    },
    isValidCoordinates(): boolean {
      return isValidCoordinates(this.coordinates)
    },
  },
  watch: {
    coordinates() {
      this.zoomToCenter(true)
    },
  },
  methods: {
    setCoordinates(geocoding: Geocoding) {
      this.zoomToCenter()
      this.$emit('input', geocoding)
    },
    zoomToCenter(animate = false) {
      if (!this.isValidCoordinates) return

      this.mapManager?.zoomTo(
        {
          coordinates: [this.coordinates.longitude, this.coordinates.latitude],
          type: 'Point',
        },
        { animate },
      )
    },
    onClick() {
      this.mapManager?.map.on('click', ({ lngLat }) => {
        if (!this.preventClick) {
          const newCoordinates = {
            latitude: lngLat.lat,
            longitude: lngLat.lng,
          }

          this.$emit('input', {
            addressLabel: '',
            address: {},
            coordinates: newCoordinates,
          })
        }
      })
    },
    async saveMapManager(mapManager: MapManager) {
      this.mapManager = mapManager

      this.zoomToCenter()

      this.onClick()
    },
  },
})
</script>
