// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PeriodicSchedulePeriodStatusesTile from './PeriodicSchedulePeriodStatusesTile.vue'
import PeriodicSchedulePeriodStatusesColumns from './PeriodicSchedulePeriodStatusesColumns'
import PeriodicSchedulePeriodStatusesDetail from './PeriodicSchedulePeriodStatusesDetail.vue'
import PeriodicSchedulePeriodStatusesMetaCard from './PeriodicSchedulePeriodStatusesMetaCard.vue'
import PeriodicSchedulePeriodStatusesReference from './PeriodicSchedulePeriodStatusesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'PeriodicSchedulePeriodStatusesTile',
      PeriodicSchedulePeriodStatusesTile,
    )
    Vue.component(
      'PeriodicSchedulePeriodStatusesDetail',
      PeriodicSchedulePeriodStatusesDetail,
    )
    Vue.component(
      'PeriodicSchedulePeriodStatusesMetaCard',
      PeriodicSchedulePeriodStatusesMetaCard,
    )
    Vue.component(
      'PeriodicSchedulePeriodStatusesReference',
      PeriodicSchedulePeriodStatusesReference,
    )

    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULE_PERIOD_STATUSES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulePeriodStatusesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULE_PERIOD_STATUSES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PeriodicSchedulePeriodStatusesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULE_PERIOD_STATUSES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulePeriodStatusesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULE_PERIOD_STATUSES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulePeriodStatusesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULE_PERIOD_STATUSES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulePeriodStatusesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
