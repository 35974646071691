import SiteVendorServiceTypesTab from './SiteVendorServiceTypesTab.vue'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

export default {
  install(Vue) {
    Vue.component('SiteVendorServiceTypesTab', SiteVendorServiceTypesTab)
    // Add the schedule preview in the tabs
    addPreviewTab(Resources.SITE_VENDORS, {
      title: 'res.vendors.attr.serviceTypes.label',
      is: 'SiteVendorServiceTypesTab',
    })
  },
}
