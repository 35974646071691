<template>
  <tt-tile>
    <template #action>
      <DaysUntilShiftAttr />
      <ShiftsDateTime />
      <tt-if-attr name="published" :value="1">
        <v-chip
          label
          x-small
          color="indigo darken-2"
          text-color="white"
          class="mr-6"
        >
          {{ $tc('tt-entity-design.shifts.attrs.published') }}
        </v-chip>
      </tt-if-attr>
      <span v-show="hasShiftPassed">
        <tt-if-attr name="vacant" :value="0">
          <tt-if-attr name="clockedHours" not :value="[null, undefined, '']">
            <v-tooltip left>
              <template #activator="{ on }">
                <v-chip
                  v-show="!tooShort"
                  label
                  x-small
                  color="green"
                  text-color="white"
                  class="mr-6"
                  v-on="on"
                >
                  {{ $t('tt-entity-design.shifts.attrs.compliant') }}
                </v-chip>
              </template>
              <span>{{ $tc('tt-entity-design.tooltip-compliant') }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-chip
                  v-show="tooShort"
                  label
                  x-small
                  color="red"
                  text-color="white"
                  class="mr-3"
                  v-on="on"
                >
                  {{ $t('tt-entity-design.shifts.attrs.non-compliant') }}
                </v-chip>
              </template>
              <span>{{ $tc('tt-entity-design.tooltip-non-compliant') }}</span>
            </v-tooltip>
          </tt-if-attr>
        </tt-if-attr>
      </span>
    </template>
    <tt-attr
      slot="title"
      name="position.account"
      data-analytics="shifts-tile-account"
    />
    <tt-attr slot="line2" name="position" />
    <template slot="line3">
      <!-- if employee name -->
      <tt-if-attr name="employee.name" not :value="[null, undefined, '']">
        <tt-attr name="employee.name" />
      </tt-if-attr>
      <!-- if no employee name -->
      <tt-if-attr name="employee.name" :value="[null, undefined, '']">
        <v-chip color="red" class="mr-1" x-small label text-color="white">
          {{ $t('tt-entity-design.shifts.attrs.vacant.label') }}
        </v-chip>
      </tt-if-attr>
    </template>
    <template #line4>
      <tt-attr name="startsOn" />
    </template>
    <template slot="line5">
      <div style="display: flex; flex-direction: row">
        <tt-if-attr name="vacant" :value="1">
          <tt-if-attr name="onShiftBoard" :value="1">
            <v-chip color="green" class="mr-1" small label text-color="white">
              {{ $tc('tt-entity-design.shifts.attrs.onShiftBoard') }}
            </v-chip>
          </tt-if-attr>
        </tt-if-attr>
        <tt-if-attr name="employee.name" not :value="[null, undefined, '']">
          <tt-if-attr name="acknowledged" :value="1">
            <v-chip color="green" x-small class="mr-1" label text-color="white">
              {{ $tc('tt-entity-design.shifts.attrs.acknowledged') }}
            </v-chip>
          </tt-if-attr>
          <tt-if-attr name="notified" :value="1">
            <v-chip color="green" class="mr-1" x-small label text-color="white">
              {{ $tc('tt-entity-design.shifts.attrs.notified') }}
            </v-chip>
          </tt-if-attr>
        </tt-if-attr>
      </div>
    </template>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import DaysUntilShiftAttr from './DaysUntilShiftAttr.vue'
import ShiftsDateTime from './ShiftsDateTime.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import moment from 'moment'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ShiftsTile',
  components: {
    DaysUntilShiftAttr,
    ShiftsDateTime,
  },
  inject: ['getItemHook', 'relationPrefix'],
  computed: {
    startsOnPath(): string {
      return this.getAttributePath('startsOn')
    },
    totalSecondsExpectedPath(): string {
      return this.getAttributePath(
        'breakRuleComplianceLog.totalSecondsExpected',
      )
    },
    totalSecondsActual(): string {
      return this.getAttributePath('breakRuleComplianceLog.totalSecondsActual')
    },
    tooShort(): boolean {
      return (
        this.getItemHook().getRawValue(this.totalSecondsExpectedPath) >
        this.getItemHook().getRawValue(this.totalSecondsActual)
      )
    },
    startsOn(): string {
      return this.getItemHook().getRawValue(this.startsOnPath)
    },
    hasShiftPassed(): boolean {
      return moment(this.startsOn).isBefore()
    },
  },
  created() {
    this.getItemHook().addAttribute(this.totalSecondsExpectedPath)
    this.getItemHook().addAttribute(this.totalSecondsActual)
  },
  methods: {
    getAttributePath(name: string): string {
      return this.relationPrefix ? `${this.relationPrefix}.${name}` : name
    },
  },
})
</script>
