import { AppContext } from '@/tt-app-context'
import { ContextAccountPersister } from '../extensions/app-account-context/ContextAccountPersister'
import { AccountContextInfo } from './types'

/**
 * Retrieves the account info from localStorage and maps the account type and subType
 */
export const getContextAccountInfo = (
  appContext: AppContext,
): AccountContextInfo => {
  const accountInfo = ContextAccountPersister(appContext).get()

  return {
    accountInfo,
    // account types asserts
    isSite: accountInfo?.type === 'CLIENT',
    isRegion: accountInfo?.type === 'REGION',
    isZone: accountInfo?.type === 'ZONE',
    // account sub types assserts
    isMultiSite:
      accountInfo?.type === 'CLIENT' && accountInfo?.subType === 'MULTI',
  }
}
