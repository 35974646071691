import {
  EventManagerInterface,
  UnsubscribeFunction,
} from '@tracktik/tt-event-manager'
import { EventProviderInterface } from './types'
import { AppContextEventPayload } from '@/tt-app-context'

/**
 * Entity intent
 */
export class EventGroupManager {
  // List of providers
  providers: EventProviderInterface[] = []

  unsubscriptions: UnsubscribeFunction[] = []

  /**
   * Base module
   */
  protected eventManager: EventManagerInterface<AppContextEventPayload>

  /**
   * Pass the module that will serve
   * @param module
   */
  constructor(module: EventManagerInterface<AppContextEventPayload>) {
    this.eventManager = module
  }

  /**
   * Register the provider
   * @param provider
   */
  register(provider: EventProviderInterface) {
    this.providers.push(provider)
  }

  subscribeEvents() {
    this.providers.forEach((provider: EventProviderInterface) => {
      const registered = this.eventManager.subscribeEvent(
        provider.getEventName(),
        (payload) => {
          provider.run(payload)
        },
      )

      this.unsubscriptions.push(registered)
    })
  }

  unsubscribe() {
    this.unsubscriptions.forEach((unsubscribe) => unsubscribe())
  }
}
