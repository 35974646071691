import {
  ContextFilter,
  WidgetContextFilters,
  WidgetModels,
} from '@/tt-widget-components/schemas-types'
import { ContextManagerInterface } from '@/tt-widget-factory/types'
import unionBy from 'lodash/unionBy'

type WidgetWithDefaultContextFilters = WidgetModels & {
  contextFilters?: WidgetContextFilters
}

const extractDefaultContextFilters = (
  widget: WidgetWithDefaultContextFilters,
): ContextFilter[] => widget?.contextFilters?.defaults ?? []

/**
 * Given a widget configuration, extracts the default context filters and applies them to the given `ContextManager`.
 *
 * Needs to be used before the widget hook is making any request.
 */
export const applyDefaultContextFiltersToContextManager = (
  widget: WidgetWithDefaultContextFilters,
  contextManager: ContextManagerInterface,
) => {
  const WidgetContextFilters = extractDefaultContextFilters(widget)

  if (!WidgetContextFilters.length) return

  const filters = contextManager.getContextFilters()
  const filterValues = unionBy(filters, WidgetContextFilters, 'attribute')

  contextManager.setContextFilters(filterValues)
}
