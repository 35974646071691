import { WidgetSchema } from '@/tt-widget-factory'

export default {
  type: 'object',
  required: ['is', 'title'],
  additionalProperties: false,
  properties: {
    description: {
      type: 'string',
    },
    uid: {
      type: 'string',
    },
    title: {
      type: 'string',
    },
    is: {
      enum: ['DashboardWidget'],
    },
    allowDownload: {
      $ref: '#/definitions/AllowDownloadCsvPdf',
    },
    rows: {
      type: 'array',
      items: {
        $ref: '#/definitions/DashboardRow',
      },
    },
    contextFilters: {
      $ref: '#/definitions/WidgetContextFilters',
    },
  },
  definitions: {
    DashboardRow: {
      additionalProperties: false,
      title: 'Dashboard Rows',
      type: 'object',
      properties: {
        size: {
          enum: [
            'EXTRA_SMALL',
            'SMALL',
            'MEDIUM',
            'LARGE',
            'EXTRA_LARGE',
            'WRAP_CONTENT',
            'FILL_PAGE',
          ],
        },
        columns: {
          type: 'array',
          items: {
            $ref: '#/definitions/DashboardColumn',
          },
        },
      },
    },
    DashboardColumn: {
      type: 'object',
      additionalProperties: false,
      properties: {
        widgetLookup: {
          oneOf: [
            {
              type: 'object',
              $ref: '#/definitions/WidgetModels',
            },
            {
              type: 'array',
              items: {
                $ref: '#/definitions/WidgetModels',
              },
            },
          ],
        },
        colSpan: {
          title: 'Column Span',
          type: 'number',
        },
      },
    },
  },
} as WidgetSchema
