<template>
  <div>
    <json-field name="alarmOrganization" />

    <json-field
      name="alarmTypeCode"
      attribute="code"
      autocomplete="off"
      :query="queryAlarmTypes"
      :disabled="!alarmOrganization"
      as="AlarmsFormCustomRelationField"
    />

    <json-field
      name="alarmConnectionCustomId"
      attribute="customId"
      autocomplete="off"
      :query="queryAlarmConnections"
      :disabled="!alarmOrganization"
      as="AlarmsFormCustomRelationField"
    />

    <div v-if="hasLocation" class="mb-4">
      <AlarmsFormLocationTile />

      <MapCoordinateSelector
        v-if="hasLocationCoordinate"
        class="pt-2 alarms-form--location-map"
        hide-search-field
        prevent-click
        :coordinates="defaultPosition"
      />
      <v-banner
        v-else
        class="my-2 pa-2 d-flex justify-center"
        dark
        outlined
        rounded
        color="warning"
        v-text="$t('common.address_not_geocoded')"
      />
    </div>

    <AlarmsFormEditButton
      class="mb-2"
      :has-location-coordinate="hasLocationCoordinate"
      :has-alarm-connection-custom-id="hasAlarmConnectionCustomId"
      @click="openLocationPage"
    />

    <AlarmsFormTimingFields
      :alarm-timing="alarmTiming"
      @update:alarmTiming="$emit('update:alarmTiming', $event)"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { CollectionQuery, FormHookProvider } from '@/tt-widget-components'
import { Resources } from '../../../types'
import AlarmsFormCustomRelationField from './AlarmsFormCustomRelationField.vue'
import AlarmsFormTimingFields from './AlarmsFormTimingFields.vue'
import AlarmsFormLocationTile from './AlarmsFormLocationTile.vue'
import AlarmsFormEditButton from './AlarmsFormEditButton.vue'
import AlarmsFormLocationPage from './AlarmsFormLocationPage.vue'
import MapCoordinateSelector from '@/tt-widget-components/components/map/MapCoordinateSelector.vue'
import { LocationServiceProvider } from './location-form-service'
import { Coordinates } from '@tracktik/tt-geo-proxy/lib/GeocodeSdk/types'
import { FilterOperatorType } from '@/tt-widget-factory'
import { LayoutWindowEvent } from '@/tt-app-layout'

Vue.component('AlarmsFormCustomRelationField', AlarmsFormCustomRelationField)

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & LocationServiceProvider & { namespace: string }
  >
).extend({
  name: 'AlarmsFormMainFields',
  components: {
    AlarmsFormTimingFields,
    AlarmsFormLocationTile,
    AlarmsFormEditButton,
    MapCoordinateSelector,
  },
  inject: ['formHook', 'locationService', 'namespace'],
  props: {
    hasLocationCoordinate: {
      type: Boolean,
      required: true,
    },
    defaultPosition: {
      type: Object as PropType<Coordinates>,
      default: null,
    },
    alarmTiming: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasLocation(): boolean {
      return !!this.locationService.getAddress()
    },
    alarmOrganization(): string | null {
      return this.formHook().getPathValue('alarmOrganization')
    },
    hasAlarmConnectionCustomId(): boolean {
      return !!this.formHook().getPathValue('alarmConnectionCustomId')
    },
    queryAlarmConnections(): Partial<CollectionQuery> {
      return {
        resource: Resources.ALARM_CONNECTIONS,
        filters: [
          {
            value: this.alarmOrganization,
            operator: FilterOperatorType.IN,
            attribute: 'alarmOrganization',
          },
        ],
      }
    },
    queryAlarmTypes(): Partial<CollectionQuery> {
      return {
        resource: Resources.ALARM_TYPES,
        filters: [
          {
            value: this.alarmOrganization,
            operator: FilterOperatorType.IN,
            attribute: 'alarmOrganization',
          },
        ],
      }
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
    openLocationPage(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: AlarmsFormLocationPage,
        title: this.translationKey('select-custom-location'),
        props: {
          formHook: this.formHook,
          locationService: this.locationService,
          namespace: this.namespace,
        },
      })
    },
  },
})
</script>
<style scoped>
.alarms-form--location-map >>> .map--container .maplibregl-map {
  border-radius: 4px;
}
</style>
