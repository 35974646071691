import { Scope, WidgetSchema } from '@/tt-widget-factory'

import { Filter } from '@/tt-widget-components'

export interface TQLQuery {
  resource?: string
  tql: string
  offset?: number
  limit?: number
  search?: string
  filters?: Filter[]
  scope?: Scope[]
  includeInactive?: boolean

  [k: string]: any
}

export default {
  type: 'object',
  required: ['is', 'title', 'dataSet'],
  properties: {
    is: {
      enum: ['TQLQueryWidget'],
    },
    title: {
      title: 'Widget Title',
      description: 'Title that represents the widget',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    dataSet: {
      additionalProperties: false,
      title: 'Query',
      type: 'object',
      required: ['tql'],
      properties: {
        tql: {
          title: 'TQL Statement',
          $ref: '#/definitions/TQLStatement',
        },
        ignoreValidation: {
          type: 'boolean',
          title: 'Ignore Validation',
        },
        filters: {
          $ref: '#/definitions/FilterGroup',
        },
        includeInactive: {
          type: 'boolean',
        },
        contextFilters: {
          $ref: '#/definitions/ContextFiltersMap',
        },
      },
    },
    toolbar: {
      $ref: '#/definitions/TQLToolbar',
    },
    options: {
      title: 'TQL Query Options',
      $ref: '#/definitions/TQLQueryOptions',
    },
    contextFilters: {
      $ref: '#/definitions/WidgetContextFilters',
    },
  },
  definitions: {
    TQLQueryOptions: {
      title: 'Customization Options',
      type: 'object',
      additionalProperties: false,
      properties: {
        exportOptions: {
          $ref: '#/definitions/TQLQueryExportOptions',
        },
      },
    },
    TQLQueryExportOptions: {
      title: 'Export Options',
      type: 'object',
      default: {
        disableExport: false,
      },
      properties: {
        disableExport: {
          type: 'boolean',
          title: 'Disable Export Option',
          default: false,
        },
      },
      if: {
        properties: {
          disableExport: {
            const: false,
          },
        },
      },
      then: {
        properties: {
          fileName: {
            title: 'Export file name',
            type: 'string',
          },
          excludeHeaders: {
            title: 'Exclude Headers in Export',
            type: 'boolean',
            default: false,
          },
          csvOptions: {
            title: 'CSV Options',
            $ref: '#/definitions/CsvOptions',
          },
          pdfOptions: {
            title: 'PDF Options',
            $ref: '#/definitions/PdfOptions',
          },
        },
      },
    },
    CsvOptions: {
      additionalProperties: false,
      type: 'object',
      properties: {
        delimiter: {
          title: 'Delimiter',
          type: 'string',
        },
        enclosure: {
          title: 'Enclosure',
          type: 'string',
        },
        encloseAll: {
          title: 'Enclose All',
          type: 'boolean',
          default: false,
        },
      },
    },
    PdfOptions: {
      includeCoverPage: false,
      exportTitle: '',
      type: 'object',
      properties: {
        includeCoverPage: {
          title: 'Include Cover Page',
          type: 'boolean',
        },
        exportTitle: {
          title: 'Report Title',
          type: 'string',
        },
      },
    },
  },
} as WidgetSchema
