<template>
  <div>
    <div v-if="isLoading">
      <v-skeleton-loader type="list-item" />
    </div>
    <div v-else>
      <json-field name="id" />
      <json-field name="taskType" />
      <json-field name="site" />
      <json-field name="jobInstructions" />
      <json-field name="generalInstructions" />
      <json-field name="checkPointTour" />
      <json-field name="priceTier" />
      <json-field name="dispatchSla" />
      <json-field name="generalDispatchSla" />
      <div v-if="hasAssetTrackingFeature">
        <json-field v-if="isDispatchable" name="dispatchableAssets" />
        <json-field v-if="schedulableRunsheet" name="schedulableAssets" />
        <json-field v-if="schedulableSiteTask" name="siteTaskAssets" />
      </div>
      <div v-if="showVendorsInput">
        <span class="text-body-1 grey--text text--darken-3">
          {{ $t('vendors.tabs.assign-job-type-form.assign-vendor') }}
        </span>
        <v-divider class="mt-2 mb-4" />
        <json-field
          :disabled="!selectedTaskTypeId || !isDispatchable"
          name="vendor"
        />
      </div>
      <span
        v-if="!isDispatchable"
        class="mt-2 text-body-2 grey--text text--darken-1"
      >
        {{ $t('vendors.tabs.assign-job-type-form.dispatchable-warning') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { getTaskType } from './helpers/task-site-instructions-api-helper'
import { TaskType } from './types'
import { Resources } from '@/tt-entity-design/src/types'
import { LayoutWindowEvent } from '@/tt-app-layout'

import { createUserRegionFeatureManager } from '@/tt-feature-manager/UserRegionFeatureManager'
import { EntityFeatureManager } from '@/tt-feature-manager/types'

const VENDOR_DISPATCH_FEATURE = 'VendorDispatchFeature'
const ASSET_HUB_FEATURE = 'AssetHubFeature'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'TaskSiteInstructionsForm',
  inject: ['formHook'],
  data() {
    return {
      selectedTaskType: null as TaskType,
      isLoading: false,
      hasAssetTrackingFeature: false,
      featureManager: null as EntityFeatureManager,
    }
  },
  computed: {
    selectedTaskTypeId(): number | null {
      return this.formHook().getPathValue('taskType') || null
    },
    isDispatchable(): boolean {
      return this.selectedTaskType?.dispatchable ?? false
    },
    schedulableRunsheet(): boolean {
      return this.selectedTaskType?.schedulableRunsheet ?? false
    },
    schedulableSiteTask(): boolean {
      return this.selectedTaskType?.schedulableSiteTask ?? false
    },
    clientId(): number | null {
      return this.formHook().getPathValue('site') || null
    },
    showVendorsInput(): boolean {
      return this.featureManager.hasFeature(VENDOR_DISPATCH_FEATURE)
    },
  },
  watch: {
    async selectedTaskTypeId(taskTypeId) {
      this.selectedTaskType = await getTaskType(this.$appContext, taskTypeId, [
        'dispatchable',
        'schedulableRunsheet',
        'schedulableSiteTask',
      ])
      this.formHook().setObjectValue('vendor', null)
    },
    clientId: {
      immediate: true,
      async handler(clientId: number) {
        this.hasAssetTrackingFeature = clientId
          ? await this.fetchAssetTrackerFeatureState()
          : false
      },
    },
  },
  created() {
    this.featureManager = createUserRegionFeatureManager(
      this.$appContext.authModule,
    )
  },
  methods: {
    async fetchAssetTrackerFeatureState(): Promise<boolean> {
      this.isLoading = true
      const id = `${this.clientId}/features/${ASSET_HUB_FEATURE}`

      const res: { active?: boolean } = await this.$auth
        .getApi()
        .get(Resources.CLIENTS, id, {
          fields: [{ attribute: 'active' }],
        })
        .catch((error) => {
          if (error.response?.status !== 404) this.handleError(error)

          return { active: false }
        })

      this.isLoading = false

      return res.active
    },
    handleError(error: unknown) {
      this.$crash.captureException(error)
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: this.$t('common.error_message'),
      })
    },
  },
})
</script>
