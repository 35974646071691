import { Field } from '@/tt-fieldset-builder/type'

export type FilteredAssetTypesInfo = {
  [key: string]: unknown
  filteredFields: Field[]
  beContainedIntoBundle: boolean
  isBundle: boolean
}

export type CustomAttributeDisplay = {
  fileId: number
  value: string
  uid: string
  label: string
  type: string
}

export enum AssetActions {
  ARCHIVE = 'archive',
  ASSOCIATE_FILE = 'associate-file',
  CHECKOUT = 'check-out',
  CHECK_OUT_SUBASSET = 'check-out-sub-asset',
  CHECKIN = 'check-in',
  CHECK_IN_SUBASSET = 'check-in-sub-asset',
  RESERVE = 'reserve',
  ADD_ASSETS = 'add-assets',
}

export enum AssetStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
}

export type assetAccount = {
  id: number
  name: string
  type: number
}

export type assetType = {
  color: string
  icon: string
  id: number
  name: string
  isSerialNumberUnique?: boolean
}

export type SearchedAsset = {
  account: assetAccount
  id: number
  name: string
  message: string
  serialNumber: string
  status: string
  type: assetType
}

export interface AssetsTranslationsTileItem {
  id: number
  translation: string
  fieldName: string
  language: string
}

export type AssetsTranslationsTileGroupedItems = Record<
  string,
  AssetsTranslationsTileItem[]
>
