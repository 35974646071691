import { FormHook } from '@tracktik/tt-json-schema-form'

import {
  CustomFilter,
  Dimension,
  Measure,
} from '@/tt-widget-factory/definitions'
import { WidgetReference } from '@/tt-widget-factory/types'

import {
  AggregationSort,
  AggregationSortItemSingle,
  AggregationSortItemMultiple,
  CollectionQuery,
  Filter,
} from './schemas-types'
import { AttributeName } from './lib/names'
import { ContextAttributeMap } from './base/contextAttributeMap'
import { EntityId } from '@/tt-widget-components/base/EntityCollector/type'
import { CancelableFunction } from 'tracktik-sdk/lib/common/entity-collection'

export * from './schemas-types'

export type FiltersMap = { [k: string]: Filter }

export enum WidgetDownloadType {
  CSV = 'csv',
  PDF = 'pdf',
  XLS = 'xls',
}

export type WidgetDownloadTypes =
  | WidgetDownloadType.CSV
  | WidgetDownloadType.PDF
  | WidgetDownloadType.XLS

export interface DataSetMeasure {
  key?: string
  name?: string
  alias?: string
  attribute: string
  operation: string
}

export interface OffsetEventUpdate {
  cancel: boolean
  currentPage: number
  name: string
  newProp: unknown
  oldProp: unknown
}

export interface DataSetDimension {
  key?: string
  name?: string
  alias?: string
  attribute: string
  modifier?: string
}

/**
 * Clean an object of its null keys
 * @param object
 * @param needsDelete
 */
export function removeNullKeys<T>(object: T, needsDelete = true) {
  const isNill = (value: any) => [null, undefined].includes(value)

  return Object.entries(object).reduce((result, entry) => {
    const [key, value] = entry

    if (isNill(value)) {
      needsDelete === true && delete object[key]

      return result
    }

    result[key] = value

    return result
  }, {}) as T
}

/**
 * Data Set
 */
export type DataSetDefinition = {
  resource: string
  measure?: Measure
  measures?: Measure[]
  dimension?: Dimension
  dimensions?: Dimension[]
  filters?: {
    includeInactive?: boolean
    whereQL?: string
    havingQL?: string
    scopes?: string[]
    filters?: Filter[]
    customFilters?: CustomFilter[]
  }
  contextFilters?: ContextAttributeMap
  params?: { [k: string]: any }
  sort?: AggregationSort
}

export type DataSetWidget = {
  dataSet?: DataSetDefinition
  dataSets?: DataSetDefinition[]
} & WidgetReference

export type CollectionWidget = {
  query: CollectionQuery
} & WidgetReference

export type Widget = DataSetWidget | CollectionWidget | WidgetReference

export type DataSetValue = any

export type DataSetRow = {
  ___query?: any
  [k: string]: DataSetValue
}

export type ResourceProvider = {
  resourceProvider: {
    resource?: string
  }
}

export type FormHookProvider = {
  formHook: () => FormHook
}

export type NamespaceProvider = {
  namespace: string
}

export enum WidgetHookEvents {
  COLLECTION_STATE_CHANGED = 'widget_hook.collection_state_changed',
}

export type WidgetHookEventsPayload = {
  [WidgetHookEvents.COLLECTION_STATE_CHANGED]: {
    items: Record<string, unknown>[]
    total: number
  }
}

export enum CollectionWidgetEventNames {
  SELECTION_LIST_UPDATED = 'selection-updated',
}

export type ContextFiltersChip = {
  id: string | number
  label: string
  tooltipLabel?: string
  attributeName: AttributeName
  attributeLabel: string
  icon: string
}
export type ContextFiltersChipsList = ContextFiltersChip[]

export enum AggregationSortItemType {
  MEASURE = 'MEASURE',
  MEASURES = 'MEASURES',
  DIMENSION = 'DIMENSION',
  DIMENSIONS = 'DIMENSIONS',
}

export type SortType = {
  type: AggregationSortItemSingle['type'] | AggregationSortItemMultiple['type']
  index?: number
}
export interface SortTypeOption {
  text: string
  value: SortType
}
export const AGGREATION_SORT_ITEM_TYPE: AggregationSortItemType[] = [
  AggregationSortItemType.MEASURE,
  AggregationSortItemType.DIMENSION,
  AggregationSortItemType.MEASURES,
  AggregationSortItemType.DIMENSIONS,
]

export type FetchOptions = {
  disableCache?: boolean
}

export type ExcelTypes = 'CSV' | 'XLSX' | 'PDF'

export enum ExcelExportTypes {
  'CSV' = 'CSV',
  'XLSX' = 'XLSX',
  'PDF' = 'PDF',
}

export type ExcelCSVType = {
  XLSX: ExcelTypes
  CSV: ExcelTypes
  PDF: ExcelTypes
}

export type PaginationType = {
  offset: number
  limit: number
}
export interface FilterHandler {
  values?: FilterHandlerValues[]
  attribute: string
  component: string
  handleFilterChange: (value: any) => Filter
}

export interface FilterHandlerValues {
  value: any
  label: string
}

export type FetchingState = {
  promise: Promise<void> | null
  cancel: CancelableFunction | null
}

export const CANCELLED_PROMISE = 'CANCELLED_PROMISE'

export type PinnedItem = EntityId[]

export interface UserPinnedItems {
  [uniqueKey: string]: PinnedItem
}

export interface ServerPinnedItems {
  [userId: string]: UserPinnedItems
}

export interface PinnedItemsStorage {
  [serverUrl: string]: ServerPinnedItems
}
