<template>
  <v-container>
    <div v-if="!form">
      <v-skeleton-loader v-for="index in 4" :key="index" type="list-item" />
    </div>
    <div v-if="form">
      <v-row no-gutters>
        <v-col>
          <ReportFieldsForm
            :value="formModel"
            :form="form"
            @input="setReportFormValues($event)"
            @errors="reportErrors($event)"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import ReportFieldsForm from '@/tt-widget-views/reports/components/ReportFieldsForm.vue'
import { FormHookProvider } from '@/tt-widget-components'
import { DispatchTaskFormServiceProvider } from './types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormReportField',
  components: { ReportFieldsForm },
  inject: ['formHook', 'service'],
  computed: {
    form(): Record<string, any> | null {
      return this.service.getReportForm()
    },
    formModel(): Record<string, unknown> | null {
      return this.service.getReportFormModel()
    },
  },
  methods: {
    setReportFormValues(values: Record<string, unknown>): void {
      this.service.setReportFormValues(values)
    },
    reportErrors(errors: Record<string, unknown>): void {
      const { account, ...reportFields } = errors

      this.$emit('errors', reportFields)
    },
  },
})
</script>
