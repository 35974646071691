import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import dateFormatter, { DateFormatTypes } from '@/helpers/formats/dates'
import { i18n } from '@/main'
import { Filter } from '@/tt-widget-components'
import { FieldTypes } from '@/tt-widget-factory'
import { createTemporalFilterManager } from './TemporalFilterManager'
import { DATE_PRESETS } from './date-presets'
import { TemporalFilterManager } from './TemporalFilterManager/types'
import { FieldTypeTemporal } from './types'

const formatPreset = (manager: TemporalFilterManager) => {
  const isPreset = (preset) => preset.value === manager.getPreset()
  const i18nKey = DATE_PRESETS.find(isPreset)?.text

  return i18n.t(i18nKey)
}

const formatByType = (
  manager: TemporalFilterManager,
  type: FieldTypeTemporal,
  userPreference?: UserPreferenceInterface,
) => {
  const formatDate = (date: string) =>
    dateFormatter[DateFormatTypes.asDate](date, userPreference) as string

  const formatTime = (time: string) =>
    dateFormatter[DateFormatTypes.asFullTime](time, userPreference) as string

  const formatToDateOnly = () =>
    manager
      .getDateTimeRange()
      .filter(({ date }) => date)
      .map(({ date }) => formatDate(date))
      .join(' <> ')

  const formatToDateAndTime = () =>
    manager
      .getDateTimeRange()
      .filter(({ date }) => date)
      .map(({ date, time }) => `${formatDate(date)} ${formatTime(time)}`)
      .join(' <> ')

  const formatToDateAndTimeISO = () =>
    `${formatToDateAndTime()} (${manager.getTimezone()})`

  const typeFormatterMap: Record<FieldTypeTemporal, () => string> = {
    [FieldTypes.Date]: formatToDateOnly,
    [FieldTypes.DateTime]: formatToDateAndTime,
    [FieldTypes.TimeStampDate]: formatToDateAndTimeISO,
    [FieldTypes.TimeStampNumber]: formatToDateAndTimeISO,
  }

  return typeFormatterMap[type]()
}

/**
 * Given a temporal filter value, returns a formatted string according to userPreferences.
 * If the filter is invalid, returns an empty string.
 *
 * Should only be used to display the filter value in the UI.
 */
export const formatTemporalFilter = (
  filter: Filter,
  type: FieldTypeTemporal,
  userPreference?: UserPreferenceInterface,
): string => {
  const manager = createTemporalFilterManager(filter)

  if (!manager.isValid()) return ''

  if (manager.isPreset()) return formatPreset(manager)

  return formatByType(manager, type, userPreference)
}
