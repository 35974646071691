<template>
  <v-card
    v-if="jsonSchema"
    flat
    class="fill-height d-flex flex-column flex-nowrap dispatch-task-form--hidden-overflow"
  >
    <v-divider />

    <FormUpdatedBanner class="mt-2" v-bind="{ resourceName, entityId }" />

    <json-form
      v-model="model"
      class="dispatch-task-form--hidden-overflow fill-height"
      :schema="jsonSchema"
      :form-options="effectiveFormOptions"
      :name="rootName"
      :user-context="userContext"
      @errors="mainFormErrors = $event"
      @valid="mainFormErrors = {}"
      @debouncing="debouncing = $event"
    >
      <DispatchTasksFormFieldSetter class="fill-height">
        <v-tabs v-model="currentTab" slider-color="orange" dense small short>
          <v-tab
            v-text="$t('tt-entity-design.dispatch-tasks.form-tabs.main')"
          />
          <v-tab
            v-if="!isEditForm"
            class="report--tab"
            :disabled="!hasReportFields"
          >
            <v-badge color="orange" :content="errorsCount" :value="errorsCount">
              {{ $t('tt-entity-design.dispatch-tasks.form-tabs.report') }}
            </v-badge>
          </v-tab>
          <v-tooltip v-if="!hasReportFields" top>
            <template #activator="{ on }">
              <div class="tooltip--indicator" v-on="on">
                <v-icon v-if="!isEditForm" small v-text="infoIcon" />
              </div>
            </template>
            <span
              v-text="
                $t(
                  'tt-entity-design.dispatch-tasks.form-tabs.report-hover-message',
                )
              "
            />
          </v-tooltip>
        </v-tabs>
        <v-tabs-items
          v-model="currentTab"
          class="fill-height pa-4 dispatch-task-form--fields-wrapper"
        >
          <v-tab-item>
            <DispatchTasksFormMainFields @back="back" />
          </v-tab-item>
          <v-tab-item>
            <DispatchTasksFormReportField @errors="reportErrors($event)" />
          </v-tab-item>
        </v-tabs-items>
      </DispatchTasksFormFieldSetter>
    </json-form>

    <v-divider />

    <v-toolbar flat text>
      <json-valid
        v-if="isDev"
        :loading="debouncing"
        :show-text="false"
        :valid="!isSubmitDisabled"
        :errors="fromErrors"
        :valid-text="$t('common.form.valid')"
        :invalid-text="$t('common.form.invalid')"
      />

      <v-spacer />

      <v-btn class="ma-3" color="grey" outlined raised @click="$emit('cancel')">
        <span v-text="$t('common.cancel.btn')" />
      </v-btn>

      <v-btn
        :disabled="isSubmitDisabled"
        raised
        color="success"
        class="ma-3"
        :loading="loading"
        @click="submitDispatchTaskForm"
      >
        <span v-text="$t('common.save.btn')" />
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ErrorObject } from 'ajv'
import {
  EmptyValueRule,
  FormOptions,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'
import { filterErrors } from '@/helpers/form-errors-manager'
import { LayoutWindowEvent } from '@/tt-app-layout'
import DispatchTasksFormMainFields from './DispatchTasksFormMainFields.vue'
import DispatchTasksFormReportField from './DispatchTasksFormReportField.vue'
import DispatchTasksFormFieldSetter from './DispatchTasksFormFieldSetter.vue'
import FormUpdatedBanner from '@/tt-app-layout/components/FormUpdatedBanner.vue'
import { Resources } from '../../../types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { DispatchTasksForm } from '../types'
import { dispatchTaskFormService } from './dispatch-task-form-service'
import {
  DispatchTaskFormServiceProvider,
  DispatchTaskFormServiceType,
} from './types'

export default Vue.extend({
  name: 'DispatchTasksForm',
  components: {
    DispatchTasksFormMainFields,
    DispatchTasksFormReportField,
    DispatchTasksFormFieldSetter,
    FormUpdatedBanner,
  },
  provide(): DispatchTaskFormServiceProvider {
    return {
      service: this.service,
    }
  },
  inject: {
    activeWindow: { default: null },
  },
  props: {
    /**
     * tt-json-schema-form's form options
     */
    formOptions: { type: Object as PropType<FormOptions>, default: () => ({}) },
    /**
     * Form's initial value
     */
    initialModel: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({} as Record<string, any>),
    },
    /**
     * tt-json-schema-form's JSON Schema
     */
    jsonSchema: { type: Object as PropType<JSONSchema7>, required: true },
    /**
     * tt-json-schema-form's root field name
     */
    rootName: { type: String, default: undefined },
    /**
     * tt-json-schema-form's user context
     */
    userContext: {
      type: Object as PropType<Record<string, any>>,
      default: undefined,
    },
    /**
     * Submit handler
     */
    submit: {
      type: Function as PropType<(data: Record<string, any>) => Promise<any>>,
      required: true,
    },
  },
  data() {
    return {
      debouncing: false,
      loading: false,
      mainFormErrors: null as { [key: string]: ErrorObject[] } | null,
      currentTab: 0,
      model: {} as Record<string, any>,
      hasReportErrors: false,
      errorsCount: 0,
      infoIcon: 'mdi-information-outline',
    }
  },
  computed: {
    isDev(): boolean {
      return process.env.NODE_ENV !== 'production'
    },
    isSubmitDisabled(): boolean {
      return (
        !!Object.keys(this.fromErrors || {}).length ||
        this.debouncing ||
        this.loading ||
        this.hasReportErrors ||
        !this.service.getForceSave()
      )
    },
    service(): DispatchTaskFormServiceType {
      return dispatchTaskFormService(this.$appContext.authModule)
    },
    effectiveFormOptions(): FormOptions {
      return {
        emptyValues: EmptyValueRule.KEEP,
        locale: this.$i18n.locale,
        ...this.formOptions,
      }
    },
    fromErrors(): Record<string, ErrorObject[]> {
      return filterErrors(this.service.getErrorsListWhiteListed(), {
        ...this.mainFormErrors,
      })
    },
    hasReportFields(): boolean {
      return this.service.getHasReportFields()
    },
    isEditForm(): boolean {
      // We cannot edit the location and the report. This boolean allows us to hide these fields.
      return this.service.getIsEditForm()
    },
    entityId(): string {
      const entityId = this.userContext?.itemHook?.getEntityId()

      return entityId ? `${entityId}` : ''
    },
    resourceName(): string {
      return Resources.DISPATCH_TASKS
    },
  },
  async created() {
    this.service.setIsEditForm(this.rootName as DispatchTasksForm)
    this.model = this.initializeFormModel()

    if (this.isEditForm && this.model.location) {
      await this.service.fetchDispatchCustomLocations(this.model.location)
    }
  },
  beforeDestroy() {
    this.service.destroy()
  },
  methods: {
    initializeFormModel(): Record<string, any> {
      return this.service.initializeForm(this.initialModel)
    },
    back(): void {
      this.$emit('back')
    },
    reportErrors(errors: { [key: string]: ErrorObject[] }) {
      const errorsCount = Object.keys(errors).length
      this.errorsCount = errorsCount
      this.hasReportErrors = !!errorsCount
    },
    onSubmitError(error): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: error,
      })
    },
    onSubmitSuccess(): void {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: Resources.DISPATCH_TASKS,
      })
      this.back()
    },
    async submitDispatchTaskForm(): Promise<void> {
      if (this.fromErrors) {
        return
      }
      this.loading = true

      this.submit(this.service.sanitizeFormModel(this.model))
        .then(() => {
          this.onSubmitSuccess()
        })
        .catch((error) => {
          this.$crash.captureException(error)
          this.onSubmitError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
<style scoped>
.report--tab {
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.tooltip--indicator {
  padding-left: -10px;
  margin-left: -10px;
}
.dispatch-task-form--hidden-overflow {
  overflow: hidden;
}
.dispatch-task-form--fields-wrapper {
  flex-grow: 1;
  height: calc(100% - 48px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
