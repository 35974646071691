<template>
  <div v-if="page" class="fill-height d-flex flex-column">
    <!-- @todo: refactor to allow the use of the close button without a title [FE-1173] -->
    <PageTitleBar v-if="page.title" :title="title" class="level1">
      <template #before>
        <v-btn
          small
          icon
          text
          circle
          data-analytics="page-close"
          @click="$emit('close')"
        >
          <slot name="before">
            <v-icon small v-text="'close'" />
          </slot>
        </v-btn>
      </template>
      <template #title="slotProps">
        <slot name="title" v-bind="slotProps" />
      </template>
      <template #default>
        <v-btn v-if="showClose && showBack" small icon @click="$emit('back')">
          <v-icon small v-text="'arrow_back'" />
        </v-btn>
      </template>
      <slot />
    </PageTitleBar>
    <slot name="beforeContent" />
    <component
      :is="page.is"
      v-bind="page.props"
      :is-page-active="active"
      v-on="$listeners"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import PageTitleBar from './PageTitleBar.vue'
import { ActiveWindowPageProvider, PageInterface } from '../types'

export default Vue.extend({
  name: 'PageBlock',
  components: { PageTitleBar },
  provide(): ActiveWindowPageProvider {
    return {
      activeWindowPage: {
        active: this.active,
        index: this.pageIndex,
        page: this.page,
      },
    }
  },
  props: {
    /**
     * Whether this page is at the top of the page stack
     */
    active: { type: Boolean, default: true },
    /**
     * Whether the page should have a "back" button
     */
    backButton: { type: Boolean, default: false },
    /**
     * Whether the page should have a "close" button
     */
    closable: { type: Boolean, default: false },
    /**
     * The page configuration object
     */
    page: { type: Object as PropType<PageInterface>, required: true },
    /**
     * The page's index in the page stack handler
     */
    pageIndex: { type: Number, default: 0 },
  },
  computed: {
    title(): string {
      return this.$t(this.page.title as string)
    },
    showBack(): boolean {
      return this.backButton
    },
    showClose(): boolean {
      return this.closable
    },
  },
})
</script>
