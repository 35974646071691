<template>
  <div class="fill-height d-flex flex-column">
    <v-skeleton-loader v-if="!isReady" type="table" />
    <WidgetFactory v-if="isReady" :widget="transactionActivityWidgetModel" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { DataTableWidgetModel, Filter } from '@/tt-widget-components'
import TransactionActivityCustomColumns from './TransactionActivityCustomColumns'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'

export default Vue.extend({
  name: 'TransactionActivity',
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      unsubscribeFn: null as UnsubscribeFunction,
    }
  },
  computed: {
    isReady(): boolean {
      return this.$appContext.isReady()
    },
    transactionActivityWidgetModel(): DataTableWidgetModel {
      const employeeFilter: Filter = {
        attribute: 'employee',
        operator: 'EQUAL',
        value: this.employeeId,
      }
      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_MANAGEMENT_ADJUSTMENTS,
          filters: [employeeFilter],
        },
        component: {
          columns: TransactionActivityCustomColumns,
        },
      }
    },
  },
  created() {
    // to refresh the list once a new leave request is posted for the employee
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ resource }) => {
        if (resource === Resources.LEAVE_REQUESTS) {
          this.updatePolicyBalances()
        }
      },
    )
  },
  beforeDestroy() {
    if (this.unsubscribeFn) this.unsubscribeFn()
  },
  methods: {
    updatePolicyBalances(): void {
      this.$appContext.eventManager.dispatchEvent(
        EntityIntentTypes.RESOURCE_UPDATED,
        { resource: Resources.LEAVE_MANAGEMENT_ADJUSTMENTS },
      )
    },
  },
})
</script>
