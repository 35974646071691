<template>
  <div v-if="loading">
    <v-skeleton-loader type="text, text, sentences, sentences" />
  </div>
  <div v-else>
    <json-field name="name" />
    <json-field name="details" />
    <json-field name="category" />
    <json-field name="type" />
    <json-field
      name="defaultLanguage"
      :placeholder="$t(defaultLanguagePlaceholder)"
    />
    <json-field name="tag" :label="$t(reportTagPlaceholder)" />
    <json-field name="translatable" as="BooleanSelector" />
    <json-field
      v-if="accountId"
      name="associatedFeatures"
      as="ReportTemplateFeatureAssociationField"
      :label="$t(featureAssociationPlaceholder)"
      :features="features"
    />
    <json-field name="approveAutomatically" />
    <json-field name="adminOnly" :label="$t(adminOnlyPlaceholder)" />
    <json-field name="notifyAlertQueue" />
    <json-field name="excludeFromShiftReport" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { getFeatureAssociationsForAccount } from './helpers'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'ReportTemplatesForm',
  inject: ['formHook'],
  data() {
    return {
      features: null,
      loading: true,
    }
  },
  computed: {
    adminOnlyPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.admin-only.placeholder'
    },
    defaultLanguagePlaceholder(): string {
      return 'tt-entity-design.report-templates.form.default-language.placeholder'
    },
    reportTagPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.tag.placeholder'
    },
    featureAssociationPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.feature-association.placeholder'
    },
    accountId(): number | null {
      return this.formHook().getPathValue('assignedSite')
    },
  },
  created() {
    if (this.accountId !== null) {
      this.loadFeatureAssociation()
    } else {
      this.loadingDone()
    }
  },
  methods: {
    async loadFeatureAssociation() {
      try {
        const response = await getFeatureAssociationsForAccount(
          this.$auth,
          this.accountId as number,
        )
        this.features = response.reportTemplateFeatures.features
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.loadingDone()
      }
    },
    loadingDone() {
      this.loading = false
    },
  },
})
</script>
