<template>
  <v-container class="site-task-schedules-exceptions">
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="fill-height" :style="{ overflow: 'hidden' }">
          <GenericForm
            v-if="form"
            v-bind="form"
            :cancellable="false"
            :closable="false"
            :save-btn-text="translationKey('submit-btn')"
            :title="translationKey('form-title')"
            @submit:error="onSubmitError"
            @submit:success="onSubmitSuccess"
          />
          <template v-else>
            <v-skeleton-loader type="card-heading" />
            <v-divider />
            <v-skeleton-loader class="field-loader" tile type="card-heading" />
            <v-skeleton-loader class="field-loader" tile type="card-heading" />
            <v-skeleton-loader class="field-loader" tile type="card-heading" />
            <v-skeleton-loader class="field-loader" tile type="card-heading" />
            <v-divider class="mt-4" />
            <v-skeleton-loader type="actions" />
          </template>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card class="fill-height">
          <v-toolbar dense short flat class="widget-header toolbar toolbar2">
            <v-toolbar-title class="modal-title pl-2 font-weight-bold">
              <span
                :style="{ fontSize: '18px' }"
                v-text="$t(translationKey('list-title'))"
              />
            </v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <div class="widget-content">
            <WidgetFactory
              :key="JSON.stringify(widget)"
              :widget="widget"
              :props-data="{ container: widgetContainer, onSelect: () => {} }"
              @[recordsLoadedEventName]="setExceptions"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" offset-sm="6" sm="6">
        <v-divider class="mb-6" />

        <div class="text-right">
          <v-btn
            class="mx-2"
            color="ttPrimary"
            :disabled="!canSkip"
            text
            @click="close"
          >
            <span v-text="$t('tt-site-tasks.schedules-wizard.cancel-btn')" />
          </v-btn>
          <v-btn
            class="mx-2"
            color="ttPrimary"
            :dark="canClose"
            :disabled="!canClose"
            @click="close"
          >
            <span v-text="$t('tt-site-tasks.schedules-wizard.close-btn')" />
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormOptions } from '@tracktik/tt-json-schema-form'

import { DialogFormInterface } from '@/tt-app-layout'
import { EntityActionIntent, EntityIntentTypes } from '@/tt-widget-entity-flow'
import {
  FilterOperatorType,
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'
import {
  GenericFormError,
  GenericFormSuccess,
} from '@/tt-app-layout/components/GenericForm.vue'
import { ListWidgetEventNames } from '@/tt-widget-components/widgets/List/types'
import { ListWidgetModel } from '@/tt-widget-components'
import { Resources } from '@/tt-entity-design/src/types'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'

type VueWithInjections = VueConstructor<Vue & { formOptions: FormOptions }>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskSchedulesExceptions',
  inject: {
    formOptions: { default: undefined },
  },
  props: {
    /**
     * ID of the entity the action should be performed on
     */
    entityId: { type: Number, required: true },
  },
  data() {
    return {
      exceptions: [] as Record<string, unknown>[],
      form: null as DialogFormInterface | null,
      unsubscribeFn: null as UnsubscribeFunction,
    }
  },
  computed: {
    actionName(): string {
      return 'add-exception-types'
    },
    canSkip(): boolean {
      return this.exceptions.length === 0
    },
    canClose(): boolean {
      return this.exceptions.length > 0
    },
    recordsLoadedEventName(): string {
      return ListWidgetEventNames.RECORDS_LOADED
    },
    resourceName(): string {
      return Resources.SITE_TASK_SCHEDULES
    },
    translationScope(): string {
      return 'tt-site-tasks.schedules-exceptions'
    },
    widget(): ListWidgetModel {
      return {
        is: 'ListWidget',
        title: '',
        query: {
          resource: Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
          filters: [
            {
              attribute: 'schedule',
              operator: FilterOperatorType.EQUAL,
              value: this.entityId,
            },
          ],
        },
        toolbar: { show: false },
      }
    },
    widgetContainer(): WidgetContainerInterface {
      return { type: WidgetContainerType.STANDALONE }
    },
  },
  watch: {
    entityId: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.resetForm()
        }
      },
    },
  },
  created() {
    // Update exceptions list every time we submit the form
    this.unsubscribeFn = this.$eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ resource }) => {
        if (resource === Resources.SITE_TASK_SCHEDULES) this.updateExceptions()
      },
    )
  },
  beforeDestroy() {
    this.unsubscribeFn()
  },
  methods: {
    close(): void {
      /**
       * @event close
       * @type {void}
       */
      this.$emit('close')
    },
    onSubmitError({ data, error }: GenericFormError): void {
      this.form?.error(error, data)
    },
    onSubmitSuccess({ data, response }: GenericFormSuccess): void {
      this.form?.success(data, response)
      this.resetForm()
    },
    async resetForm(): Promise<void> {
      this.form = null // set component to loading state

      const intent = new EntityActionIntent(this.$appContext)
      const builder = await intent.getDialogFormBuilder({
        actionName: this.actionName,
        entityId: this.entityId,
        formOptions: this.formOptions,
        //@todo: remove ts-ignore when the resource is part of the resource enum
        //@ts-ignore
        resourceName: this.resourceName,
      })
      this.form = builder.getState()
    },
    setExceptions(exceptions: Record<string, unknown>[]): void {
      this.exceptions = exceptions
    },
    translationKey(key: string): string {
      return `${this.translationScope}.${key}`
    },
    updateExceptions(): void {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
      })
    },
  },
})
</script>

<style scoped>
.field-loader {
  width: 100%;
}

.field-loader >>> .v-skeleton-loader__heading {
  height: 40px;
  width: 90%;
}

.site-task-schedules-time-adjustments >>> .form-array-section .remove-item-btn {
  height: 40px;
}

.widget-header {
  height: 48px;
}

.widget-content {
  /* header height + separator */
  height: calc(100% - 49px);
}
</style>
