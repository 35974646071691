import { DialogFormInterface, LayoutWindowEvent } from '@/tt-app-layout/types'
import { EventManagerInterface } from '@tracktik/tt-event-manager'
import { JSONSchema7, JSONSchema7TypeName } from '@tracktik/tt-json-schema-form'

import VueI18n from 'vue-i18n'
import { FormOptions } from '@tracktik/tt-json-schema-form'
import { merge } from 'lodash'
import { AppContextEventPayload } from '@/tt-app-context'

const createBaseJsonSchema = (): JSONSchema7 => ({
  type: 'object',
  properties: {},
  required: [],
})

interface FieldOptions<T> {
  required?: boolean
  defaultValue?: T
}

/**
 * Helper to display a json-schema-form dialog
 */
export class DialogFormBuilder {
  private state: DialogFormInterface
  private services: {
    eventManager: EventManagerInterface<AppContextEventPayload>
    i18n?: Partial<VueI18n>
  }

  constructor(
    title: string,
    rootName: string,
    services: DialogFormBuilder['services'],
  ) {
    this.services = { ...services }
    this.state = {
      title: this.useI18nIfDefined(title),
      rootName,
      jsonSchema: createBaseJsonSchema(),
    }
  }

  mergeWithDialogOptions(dialogOptions: DialogFormInterface) {
    this.state = { ...this.state, ...dialogOptions }

    return this
  }

  private useI18nIfDefined(label: string): string {
    return this.services?.i18n?.tc(label) || label
  }

  addToInitialModel<T>(initialModel: { [k: string]: T }): this {
    this.state.initialModel = { ...this.state.initialModel, ...initialModel }

    return this
  }

  setCloseOnOutsideClick(val = true): this {
    this.state.closeOnOutsideClick = val

    return this
  }

  addField<T>(
    type: JSONSchema7TypeName,
    name: string,
    label: string,
    { required, defaultValue }: FieldOptions<T> = {},
    extraProps?: Partial<JSONSchema7>,
  ): this {
    const title = this.useI18nIfDefined(label)

    this.addToJsonSchemaProperties(name, { type, title, ...extraProps })

    if (required) this.addToRequired(name)
    if (defaultValue) this.addToInitialModel<T>({ [name]: defaultValue })

    return this
  }

  addText<T extends string>(
    name: string,
    label: string,
    options: FieldOptions<T> = {},
  ): this {
    this.addField<T>('string', name, label, options)

    return this
  }

  addBoolean<T extends boolean>(
    name: string,
    label: string,
    options: FieldOptions<T> = {},
  ): this {
    this.addField<T>('boolean', name, label, options)

    return this
  }

  addEnum(
    name: string,
    label: string,
    values: string[],
    options: FieldOptions<(typeof values)[number]> = {},
  ) {
    this.addField('string', name, label, options)
    const schema = this.state.jsonSchema.properties[name]
    this.addToJsonSchemaProperties(name, { ...schema, enum: values })

    return this
  }

  addSchema(name: string, schema: JSONSchema7): this {
    this.addToJsonSchemaProperties(name, schema)

    return this
  }

  addToRequired(name: string): this {
    this.state.jsonSchema.required = [...this.state.jsonSchema.required, name]

    return this
  }

  onSubmit(submit: DialogFormInterface['submit']): this {
    this.state = { ...this.state, submit }

    return this
  }

  onSuccess(success: DialogFormInterface['success']): this {
    this.state = { ...this.state, success }

    return this
  }

  onError(error: DialogFormInterface['error']): this {
    this.state = { ...this.state, error }

    return this
  }

  onClose(close: DialogFormInterface['close']): this {
    this.state = { ...this.state, close }

    return this
  }

  onCancel(cancel: DialogFormInterface['cancel']): this {
    this.state = { ...this.state, cancel }

    return this
  }

  addToFormOptions(options: FormOptions): this {
    this.state = {
      ...this.state,
      formOptions: merge({}, this.state.formOptions, options),
    }

    return this
  }

  setTranslateFunction(
    translateFunction: DialogFormInterface['formOptions']['translateFunction'],
  ): this {
    this.addToFormOptions({ translateFunction })

    return this
  }

  addToDefinitions(
    definitionsToAdd: DialogFormInterface['formOptions']['definitions'],
  ): this {
    const definitions = {
      ...this.state.formOptions?.definitions,
      ...definitionsToAdd,
    }
    this.addToFormOptions({ definitions })

    return this
  }

  addToContext(contextToAdd: { [k: string]: any }): this {
    this.state.userContext = { ...this.state.userContext, ...contextToAdd }

    return this
  }

  addProperty(name: string, prop: JSONSchema7): this {
    this.state.jsonSchema.properties = {
      ...this.state.jsonSchema.properties,
      [name]: prop,
    }

    return this
  }

  private addToJsonSchemaProperties(name: string, schema: JSONSchema7): void {
    this.state.jsonSchema.properties = {
      ...this.state.jsonSchema.properties,
      [name]: schema,
    }
  }

  getState(): DialogFormInterface {
    return { ...this.state }
  }

  getJsonSchema(): DialogFormInterface['jsonSchema'] {
    return { ...this.state.jsonSchema }
  }

  displayDialog(): this {
    this.services.eventManager.dispatchEvent(
      LayoutWindowEvent.DIALOG_FORM,
      this.state,
    )

    return this
  }

  setJsonSchema(jsonSchema: JSONSchema7): this {
    this.state.jsonSchema = { ...jsonSchema }

    return this
  }

  setWidth(width: number): this {
    this.state.width = width

    return this
  }
}
