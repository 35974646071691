import { reactive } from 'vue'
import { AxiosRequestConfig } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import i18n from '@/plugins/i18n'
import ResourcePermissionAuditor from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { lang } from '@/tt-tql-inputs/src/lang/types'
import {
  BaseWidgetHook,
  ResourceAndContextAttributeMap,
} from '@/tt-widget-factory/types'
import { downloadAsFile } from '@/helpers/downloadAsFile'
import { EntityToolbarManager } from '@/tt-widget-entity-flow/EntityToolbarManager'
import { LayoutWindowEvent } from '@/tt-app-layout'
import {
  TQLValidator,
  TQLValidatorInput,
} from '@/tt-tql-inputs/src/lang/validator'
import { MimeType } from '@tracktik/tt-helpers/lib/types'
import {
  markup,
  TQLMarkup,
} from '@/tt-widget-components/widgets/TQLQuery/markup'

import QueryCache from '../../base/QueryCache'
import TQLQueryManager from '../../base/TQLQueryManager'
import { TQLQuery } from '../TQLQuery/schema'
import { TQLQueryOptions, TQLQueryWidgetModel } from '../../types'
import { getTqlExportHeadersByType } from './helpers'
import { TQLExportFormat } from './constants'
import { applyDefaultContextFiltersToContextManager } from '@/tt-widget-factory/helpers/default-context-filters'

export type TQLResponse = { meta: { headers: string[] }; data: any[] }

export type TQLColumn = {
  text: string
  name: string
  size?: string
  class?: string
}
/**
 * TQL Query Widget
 */
export default class TQLQueryWidgetHook extends BaseWidgetHook<TQLQueryWidgetModel> {
  isExporting = false
  response?: TQLResponse
  toolbarManager: EntityToolbarManager<TQLQuery>

  protected tqlQueryManager: TQLQueryManager

  private tqlWidgetState = reactive({
    loading: false,
  })

  get loading() {
    return this.tqlWidgetState.loading
  }

  get templateMarkup(): TQLMarkup {
    return markup(this.state.widget.dataSet.tql)
  }

  set loading(loading: boolean) {
    this.tqlWidgetState.loading = loading
  }

  get resource() {
    return this.validator.getResource(this.tql)
  }

  get hasDataSource(): boolean {
    return !isEmpty(this.state?.data)
  }

  hasPermission(): boolean {
    const { authModule, resourceMetaManager } = this.services
    const resourceAndContext = this.getResourcesAndContext()

    return ResourcePermissionAuditor.canViewAllResources(
      { authModule, resourceMetaManager },
      resourceAndContext.map((item) => item.resource),
    )
  }

  get totalEntities(): number | null {
    return this.state.data ? this.state.data.length : null
  }

  validator: TQLValidator = new TQLValidator(
    this.services.resourceMetaManager,
    lang,
  )

  /**
   * Query
   */
  get tqlQuery() {
    return {
      resource: this.resource,
      tql: this.tql,
      includeInactive: this.state.widget?.dataSet?.includeInactive,
      filters: this.state.widget?.dataSet?.filters?.filters,
    } as TQLQuery
  }

  get showToolbar() {
    return this.state.widget?.toolbar?.show !== false
  }

  /**
   * Initialize
   */
  initialize() {
    this.validate()
    if (!this.isValid) {
      this.setAsInvalidState()

      return
    }

    if (this.isStandaloneWidget()) {
      applyDefaultContextFiltersToContextManager(
        this.state.widget,
        this.services.contextManager,
      )
    }

    super.initialize()

    this.state.widget.toolbar = this.state.widget.toolbar ?? {
      show: true,
      displayCounts: true,
      filterOptions: {
        allowSearch: false,
        allowScopes: true,
        filters: [],
      },
    }

    const { resourceDataManager, resourceMetaManager } = this.services
    this.tqlQueryManager = new TQLQueryManager(
      this.tqlQuery,
      this.services.contextManager,
      {
        contextAttributeMap: this.widget.dataSet.contextFilters,
        defaultFilterValues: this.widget.toolbar?.filterOptions?.filters,
        services: { resourceDataManager, resourceMetaManager },
      },
    )

    this.toolbarManager = new EntityToolbarManager(
      this.state.widget?.toolbar,
      this.tqlQueryManager,
    )

    this.setInitiated()

    return this.run()
  }

  update() {
    return this.run()
  }

  set ignoreValidation(ignoreValidation) {
    this.validator = new TQLValidator(
      this.services?.resourceMetaManager,
      lang,
      ignoreValidation,
    )
  }

  /**
   * The statement
   */
  get tql(): string | null {
    return this.state?.widget?.dataSet?.tql
  }

  /**
   * Filters
   */
  get filters() {
    const filters = {
      tql: this.tql,
    }
    if (this.state.widget.dataSet.includeInactive) {
      filters['includeInactive'] = 1
    }

    return filters
  }

  /**
   * Show export
   */
  get showExport(): boolean {
    return !this.state.widget?.options?.exportOptions?.disableExport
  }

  /**
   * Get resources and context
   */
  getResourcesAndContext(): ResourceAndContextAttributeMap[] {
    const resourceAndContextAttributeMaps = []
    if (!this.resource) {
      return resourceAndContextAttributeMaps
    }

    const resourceModel = this.services.resourceMetaManager.getResource(
      this.resource,
    )

    resourceAndContextAttributeMaps.push({
      uid: this.state.widget.uid,
      resource: this.resource,
      contextAttributeMap: {
        ...resourceModel.modelContext,
        ...(this.state.widget.dataSet?.contextFilters ?? {}),
      },
    })

    return resourceAndContextAttributeMaps
  }

  /**
   * Return the headers
   */
  get headers(): string[] {
    if (!this.state.headers) {
      return []
    }

    return this.state.headers.filter((item: string | number) => {
      return `${item}`.indexOf('__') != 0
    })
  }

  get columns(): TQLColumn[] {
    const markup = this.templateMarkup ?? {}
    const sizes = markup.columns?.sizes ?? []
    const align = markup.columns?.align ?? []

    return this.headers.map((header: string, i: number) => ({
      name: header,
      text: header,
      size: sizes[i] ?? null,
      class: align[i] ?? null,
    }))
  }

  /**
   * Validate the Query
   */
  validate(): TQLValidatorInput {
    if (!this.tql) {
      return
    }

    const validatorInput: TQLValidatorInput = {
      tqlStatement: this.tql,
      output: {
        valid: false,
      },
    } as TQLValidatorInput
    this.validator.validate(validatorInput)
    this.errors = validatorInput.output.errors
  }

  get isValid(): boolean {
    const input: TQLValidatorInput = {
      tqlStatement: this.tql,
      output: {
        valid: false,
      },
    } as TQLValidatorInput

    return this.validator.validate(cloneDeep(input))
  }

  private get httpInstance() {
    return this.services.authModule.getApi().httpClient
  }

  /**
   * Get the export header
   */
  getExportHeaders(format: keyof typeof MimeType) {
    const options = this.state.widget.options as TQLQueryOptions
    const exportHeaders = {
      export: format,
    }

    // If there are no export options, return the headers
    if (!options || !options.exportOptions) {
      return exportHeaders
    }

    // Get the export options
    const exportOptions = options.exportOptions

    return {
      ...exportHeaders,
      ...getTqlExportHeadersByType(format, exportOptions),
    }
  }

  /**
   * Export the data
   */
  exportData(format: keyof typeof MimeType = 'CSV') {
    this.isExporting = true

    const getRequest = this.tqlQueryManager.getUrl(
      this.getExportHeaders(format),
    )

    const configs: AxiosRequestConfig =
      format === TQLExportFormat.PDF ? { responseType: 'blob' } : {}

    this.httpInstance
      .get(getRequest, configs)
      .then((response: any) => {
        this.isExporting = false
        downloadAsFile(response.data, this.exportName, format)
      })
      .catch((e: any) => {
        console.error(e)
        this.isExporting = false
        this.services.contextManager.dispatchEvent(
          LayoutWindowEvent.SNACK_ERROR,
          {
            message: i18n.t('tql_query_widget.error_downloading_file'),
          },
        )
      })
  }

  /**
   * Export Name
   */
  get exportName() {
    const fileName = this.state.widget?.options?.exportOptions?.fileName?.trim()
    if (fileName && fileName.length) {
      return fileName
    }

    return this.state.widget.title?.split(' ').join('-') || 'report'
  }

  protected fetchData() {
    return this.run()
  }

  async run() {
    this.validate()

    if (!this.isValid) {
      this.setAsInvalidState()

      return
    }

    const responseCallback = (data: any) => {
      this.loading = false
      this.state.response = data
      this.state.headers = data.meta.headers
      this.state.data = data.data
      this.state = { ...this.state }
    }

    const cacheKey = this.tqlQueryManager.getUrl()

    if (QueryCache.has(cacheKey)) {
      responseCallback(QueryCache.get(cacheKey))

      return
    }

    this.errors = []
    this.loading = true
    await this.httpInstance
      .get(this.tqlQueryManager.getUrl())
      .then((response: any) => {
        QueryCache.set(cacheKey, response.data, 10)

        return response.data
      })
      .then(responseCallback)
      .catch((err: any) => {
        this.loading = false

        this.errors = [err]

        const message = err.response?.data?.message || 'Error running query'
        this.services.contextManager.dispatchEvent(
          LayoutWindowEvent.SNACK_ERROR,
          {
            message,
          },
        )
      })
  }
}
