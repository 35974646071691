import { AuthModule } from '@tracktik/tt-authentication'
import { EntityId } from '@/tt-widget-components/base/EntityCollector/type'
import { PINNED_ITEM_LOCAL_STORAGE_KEY } from '@/tt-widget-components/constants'
import { PinnedItemsStorage } from '@/tt-widget-components/types'

/**
 * Set the pinned items to the local storage for a specific serverUrl, userId and resource key
 */
const setPinnedItemsToLocalStorage = (
  pinnedItems: PinnedItemsStorage,
): void => {
  try {
    localStorage.setItem(
      PINNED_ITEM_LOCAL_STORAGE_KEY,
      JSON.stringify(pinnedItems),
    )
  } catch (error) {
    console.warn(`Could not set the pinned items to the Locale Storage.`, error)
  }
}

/**
 * Retrieve the pinned items from the local storage
 */
const getPinnedItemsFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(PINNED_ITEM_LOCAL_STORAGE_KEY)) || {}
  } catch (error) {
    console.warn(
      `Could not get the pinned items from the Locale Storage.`,
      error,
    )

    return {}
  }
}

/**
 * Update local storage with the new pinned items
 */
export const persistPinnedRowIdCache = (
  authModule: AuthModule,
  rowPinnerString: string,
  pinnedRowId: EntityId,
) => {
  const { getUserId, getServerUrl } = authModule
  const userId = getUserId()
  const serverUrl = getServerUrl()

  if (!userId || !serverUrl) {
    console.warn('User ID or Server URL is missing.')

    return
  }

  const currentStorage = getPinnedItemsFromLocalStorage()

  const existingItems =
    currentStorage?.[serverUrl]?.[userId]?.[rowPinnerString] || []

  const updatedItems = existingItems.includes(pinnedRowId)
    ? existingItems
    : [...existingItems, pinnedRowId]

  const updatedStorage: PinnedItemsStorage = {
    ...currentStorage,
    [serverUrl]: {
      ...(currentStorage[serverUrl] || {}),
      [userId]: {
        ...(currentStorage[serverUrl]?.[userId] || {}),
        [rowPinnerString]: updatedItems,
      },
    },
  }

  setPinnedItemsToLocalStorage(updatedStorage)
}

/**
 * Fetch the pinned items for a specific resource
 */
export const getCachedPinnedRowIds = (
  authModule: AuthModule,
  rowPinnerString: string,
): EntityId[] => {
  const { getUserId, getServerUrl } = authModule
  const userId = getUserId()
  const serverUrl = getServerUrl()

  if (!userId || !serverUrl) {
    console.warn('User ID or Server URL is missing.')

    return []
  }

  const currentStorage = getPinnedItemsFromLocalStorage()

  return currentStorage?.[serverUrl]?.[userId]?.[rowPinnerString] || []
}

/**
 * Remove an item from the local storage
 */
export const removePinnedRowIdCache = (
  authModule: AuthModule,
  rowPinnerString: string,
  pinnedRowId: EntityId,
): void => {
  const { getUserId, getServerUrl } = authModule
  const userId = getUserId()
  const serverUrl = getServerUrl()

  if (!userId || !serverUrl) {
    console.warn('User ID or Server URL is missing.')

    return
  }

  const currentStorage = getPinnedItemsFromLocalStorage()
  const existingItems =
    currentStorage?.[serverUrl]?.[userId]?.[rowPinnerString] || []

  const updatedItems = existingItems.filter(
    (id: EntityId) => id !== pinnedRowId,
  )

  const updatedStorage: PinnedItemsStorage = {
    ...currentStorage,
    [serverUrl]: {
      ...(currentStorage[serverUrl] || {}),
      [userId]: {
        ...(currentStorage[serverUrl]?.[userId] || {}),
        [rowPinnerString]: updatedItems,
      },
    },
  }

  setPinnedItemsToLocalStorage(updatedStorage)
}
