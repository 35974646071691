import { ActionMenuItem } from '@/tt-app-layout'
import { modularManager, PresetItem, PresetTypes } from '@/tt-app-modular'
import { MENU_QUICK_ADD_EXTENSION } from '@/tt-app-extensions/menu-quick-add-extension'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { AppContext } from '@/tt-app-context'
import { Resources } from '@/tt-entity-design/src/types'

import { defaultPreset } from '../utils/constants'
import { Routes } from '../enumRoutes'
import {
  AssetCreateMenuItem,
  AssetTrackingSettings,
  AssetTrackingSettingsActions,
} from '../types'
import {
  canExecuteAction,
  fetchAssetTrackingSettings,
  getContextAccountInfo,
} from '.'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'

const CREATE_MENU_LINKS = {
  [Routes.BATCH_IMPORT]: {
    icon: 'mdi-file-upload-outline',
    color: 'green',
  },
  [Routes.IMPORT_WIZARD]: {
    icon: 'mdi-import',
    color: 'ttPrimary',
  },
}

/**
 * Get Preset Settings
 */
export const getPresetSettings = (resource: string): PresetItem => {
  return (
    modularManager.getResourcePreset(resource, PresetTypes.LOGO_COLOR) ??
    defaultPreset
  )
}

/**
 * Register Create Menu Intents
 * Register a "CREATE" intent menu item to show on the "Create" dropdown menu
 */

export const registerCreateMenuIntent = (item: AssetCreateMenuItem): void => {
  const preset = getPresetSettings(item.resource)

  modularManager.addItem(MENU_QUICK_ADD_EXTENSION, {
    text: `assets.create-menu.${item.resource}`,
    icon: preset.data.icon,
    color: preset.data.color,
    action: {
      type: 'dispatch',
      props: {
        eventName: EntityIntentTypes.CREATE,
        payload: {
          resourceName: item.resource,
          formOptions: {
            fieldErrorRule: FieldErrorRule.ONLY_TOUCHED,
          },
        },
      },
    },
  } as ActionMenuItem)
}

/**
 * Register Create Menu Router Links
 * Register a link at the bottom of the "Create" dropdown menu.
 */
export const registerCreateMenuLink = (item: AssetCreateMenuItem): void => {
  const linkSettings = CREATE_MENU_LINKS[item.route]
  const { icon, color } = linkSettings || {}

  modularManager.addItem(MENU_QUICK_ADD_EXTENSION, {
    text: `assets.create-menu.${item.route}`,
    icon,
    color,
    action: {
      type: 'route',
      props: {
        path: item.route,
      },
    },
  } as ActionMenuItem)
}

/**
 * Checks if a menu item already exists in the modular manager's quick add extension.
 *
 * @param item - The menu item to check for existence. It should be of type `AssetCreateMenuItem`.
 * @returns `true` if the menu item exists, otherwise `false`.
 */
export function doesMenuItemExist(item: AssetCreateMenuItem): boolean {
  const menuItems = modularManager.getItems(MENU_QUICK_ADD_EXTENSION)
  const itemKey = `assets.create-menu.${
    item.resource ? item.resource : item.route
  }`

  return (
    menuItems?.some((existingItem) => existingItem.text === itemKey) ?? false
  )
}

/**
 * Register the Create Menu Items and register custom asserts for each item
 */
export const registerCreateMenuItems = async (appContext: AppContext) => {
  // clear previous items from the extension
  await modularManager.clearItems(MENU_QUICK_ADD_EXTENSION)

  const accountId = appContext.contextManager.getSelectedAccounts()[0].id
  const assetTrackingSettings: AssetTrackingSettings =
    await fetchAssetTrackingSettings({ accountId, appContext })
  const isRegion = getContextAccountInfo(appContext).isRegion
  const canShowCreateType = canExecuteAction(
    Resources.ASSET_TYPES,
    AssetTrackingSettingsActions.CREATE,
    assetTrackingSettings,
  )
  const canShowCreateCategory = canExecuteAction(
    Resources.ASSET_CATEGORIES,
    AssetTrackingSettingsActions.CREATE,
    assetTrackingSettings,
  )
  const canImportModelTypes =
    assetTrackingSettings[AssetTrackingSettingsActions.CAN_IMPORT] ?? false

  const CREATE_MENU_ITEMS = [
    { resource: Resources.ASSETS, canShow: !isRegion },
    { resource: Resources.ASSET_TYPES, canShow: canShowCreateType },
    {
      resource: Resources.ASSET_CATEGORIES,
      canShow: canShowCreateCategory,
    },
    {
      resource: Resources.ASSET_PERSONS,
      canShow: !isRegion,
    },
    {
      resource: Resources.ASSET_MANUFACTURERS,
      canShow: !isRegion,
    },
    {
      resource: Resources.ASSET_SUPPLIERS,
      canShow: !isRegion,
    },
    { route: Routes.BATCH_IMPORT, canShow: !isRegion },
    {
      resource: Resources.ASSET_CUSTOM_ATTRIBUTES,
      canShow: !isRegion,
    },
    { route: Routes.IMPORT_WIZARD, canShow: canImportModelTypes },
  ] as AssetCreateMenuItem[]

  CREATE_MENU_ITEMS.map(async (item) => {
    const itemAlreadyExists = doesMenuItemExist(item)

    // if the item already exists, don't register it again
    if (!item.canShow || itemAlreadyExists) return

    if (item.resource) {
      await registerCreateMenuIntent(item)
    } else if (item.route) {
      await registerCreateMenuLink(item)
    }
  })
}
