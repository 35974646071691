import { WidgetSchema } from '@/tt-widget-factory'
import { JSONSchema7 } from 'json-schema'

const MarkerCollectionModel: WidgetSchema = {
  title: 'Map Marker',
  description: 'A collection of markers to be displayed on a map.',
  type: 'object',
  properties: {
    is: {
      enum: ['MarkerCollection'],
    },
    uid: {
      title: 'Configuration ID',
      description:
        'This ID is used to identify the hooks in the widget. Must be unique for the widget.',
      type: 'string',
    },
    title: {
      title: 'Configuration Title',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    query: {
      $ref: '#/definitions/CollectionQuery',
    },
    geoMapOptions: {
      title: 'Attribute Mapping',
      $ref: '#/definitions/ResourceGeoMappingOptions',
    },
    markerOptions: {
      anyOf: [
        {
          title: 'Basic Marker Options',
          type: 'object',
          properties: {
            color: {
              title: 'Marker Color',
              type: 'string',
            },
            icon: {
              title: 'Marker Icon',
              type: 'string',
            },
          },
        },
        {
          title: 'Marker Component',
          type: 'object',
          properties: {
            is: {
              title: 'Marker Component Name',
              type: 'string',
            },
            props: {
              title: 'Marker Component Props',
              type: 'object',
            },
          },
        },
      ],
    },
    toolbar: {
      $ref: '#/definitions/DefaultToolbar',
    },
    allowLiveUpdate: {
      title: 'Allow Live Update',
      description:
        'Allow the widget to automatically refresh its data when a change is received from the API for the resources. Will use Pusher internally.',
      type: 'boolean',
    },
    contextFilters: {
      $ref: '#/definitions/WidgetContextFilters',
    },
  },
  required: ['is', 'uid', 'title', 'query', 'geoMapOptions'],
  additionalProperties: false,
}

export const MapMultiMarkersWidgetSchema: WidgetSchema = {
  title: 'Map Multi Markers',
  description: 'A widget to display multiple markers on a map.',
  type: 'object',
  required: ['is', 'title'],
  properties: {
    is: {
      enum: ['MapMultiMarkersWidget'],
    },
    title: {
      title: 'Widget Title',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Widget Title',
      type: 'string',
    },
    configs: {
      type: 'array',
      title: 'List of configurations',
      description:
        'Each map markers configuration will generate a separate CollectionWidgetHook with its own settings and data to be rendered on the map',
      items: {
        $ref: '#/definitions/MarkerCollectionModel',
      },
    },
  },
  definitions: {
    MarkerCollectionModel,
  },
} satisfies JSONSchema7
