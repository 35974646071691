<template>
  <div>
    <!-- Jobs/Tasks & Client fields-->
    <json-field
      class="mt-2"
      name="taskType"
      :label="taskTypeLabel"
      :disabled="isEditForm"
    />

    <json-field name="client" :disabled="isEditForm" />

    <!-- Checkbox to force save banned task/site -->
    <DispatchTasksFormBannedCheckbox v-if="!isEditForm" />

    <DispatchTasksFormLocationSection />

    <!-- Instruction fields & taskSiteSettings instructions-->
    <DispatchTasksFormInstructionsField />

    <!-- Dispatch timing fields -->
    <v-row v-if="isEditForm" no-gutters>
      <v-col cols="12">
        <json-field name="startedOn" />
      </v-col>
      <v-col cols="6" class="pr-4">
        <json-field
          name="plannedDurationInMinutes"
          prepend-inner-icon="mdi-timer-outline"
        />
      </v-col>
      <v-col cols="6">
        <json-field
          name="reminderInMinutes"
          prepend-inner-icon="mdi-bell-outline"
        />
      </v-col>
    </v-row>
    <DispatchTasksFormTimingFields v-else />

    <!-- Billing field -->
    <div v-if="isBillable">
      <span
        class="subtitle-2 pb-2"
        v-text="$t(translationKey('form-fields.billing'))"
      />
      <json-field name="priceTier" />
    </div>

    <!-- AlarmOrganization fields -->
    <json-field name="alarmOrganization" :disabled="isEditForm" />

    <!-- User assignment field/button -->
    <div v-if="!isEditForm">
      <span
        class="subtitle-2 pb-2"
        v-text="$t(translationKey('form-fields.assignment'))"
      />
      <DispatchTasksFormAssignUser @back="$emit('back')" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import DispatchTasksFormTimingFields from './DispatchTasksFormTimingFields.vue'
import DispatchTasksFormInstructionsField from './DispatchTasksFormInstructionsField.vue'
import DispatchTasksFormAssignUser from './DispatchTasksFormAssignUser.vue'
import DispatchTasksFormBannedCheckbox from './DispatchTasksFormBannedCheckbox.vue'
import DispatchTasksFormLocationSection from './DispatchTasksFormLocationSection.vue'
import { DispatchTaskFormServiceProvider } from './types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormMainFields',
  components: {
    DispatchTasksFormTimingFields,
    DispatchTasksFormInstructionsField,
    DispatchTasksFormAssignUser,
    DispatchTasksFormBannedCheckbox,
    DispatchTasksFormLocationSection,
  },
  inject: ['formHook', 'service'],
  computed: {
    isBillable(): boolean {
      return this.service.getIsBillable()
    },
    isEditForm(): boolean {
      return this.service.getIsEditForm()
    },
    taskTypeLabel(): string {
      return `${this.formHook().getFieldLabel('taskType')} *`
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
  },
})
</script>
