/**
 * This interface is specific for objects that are going to be passed down to
 * Vuetify's VTreeview component.
 * Vuetify types its `items` property as `any[]` and doesn't export the type so
 * we have to create our own.
 *
 * @see https://v2.vuetifyjs.com/en/api/v-treeview/#props
 */
export interface TreeviewItem {
  id: string
  name: string
  children?: TreeviewItem[]
  disabled?: boolean
}

export type SelectionType = 'independent' | 'leaf'

export type Item = TreeviewItem
export type ItemId = TreeviewItem['id']

export enum SUBSCRIPTION_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export type pusherStatusType =
  | SUBSCRIPTION_STATUS.SUCCESS
  | SUBSCRIPTION_STATUS.ERROR
  | SUBSCRIPTION_STATUS.WARNING
