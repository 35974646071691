<template>
  <v-card flat outlined>
    <v-toolbar class="pt-1" height="20" flat>
      <v-spacer />
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon small v-on="on" @click="clearLocation">
            <v-icon small v-text="'close'" />
          </v-btn>
        </template>
        <span
          v-text="$t('tt-entity-design.dispatch-tasks.clear-custom-location')"
        />
      </v-tooltip>
    </v-toolbar>
    <v-list class="py-0">
      <v-list-item class="tt-tile pb-6 pt-1">
        <v-list-item-action class="my-0 mr-4">
          <v-icon size="50" color="ttPrimary" v-text="'mdi-city'" />
        </v-list-item-action>
        <v-list-item-content class="py-0">
          <v-list-item-title v-if="name" class="pb-1">
            <span v-text="name" />
          </v-list-item-title>
          <v-list-item-subtitle class="pb-1">
            <span style="color: var(--v-ttPrimary-base)" v-text="address" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="region" class="pb-1">
            <span v-text="`region: ${region}`" />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { FormHookProvider } from '@/tt-widget-components'
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'
import { LocationType } from '../types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormAddressTile',
  inject: ['formHook', 'service'],
  computed: {
    name(): string {
      return this.formHook().getPathValue('location.name')
    },
    address(): string {
      const addressLine1 = this.formHook().getPathValue('location.addressLine1')
      const addressLine2 = this.formHook().getPathValue('location.addressLine2')
      const city = this.formHook().getPathValue('location.city')
      const state = this.formHook().getPathValue('location.state')

      const address = [addressLine1, addressLine2, city, state]

      return address.filter(Boolean).join(', ')
    },
    region(): string {
      return this.formHook().getPathValue('location.region')
    },
  },
  methods: {
    clearLocation() {
      this.service.setIsCustomLocation(false)
      this.formHook().setObjectValue('location', null)
      this.formHook().setObjectValue(
        'locationType',
        LocationType.ACCOUNT_ADDRESS,
      )
    },
  },
})
</script>
