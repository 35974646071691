// Enter your custom installation here
import i18n from '@/plugins/i18n'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import {
  addPreviewTab,
  registerResourceForm,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'
import AssetTypesForm from '@/tt-entity-design/src/components/asset-types/AssetTypesForm.vue'
import AssetTypesAssociateReportForm from '@/tt-entity-design/src/components/asset-types/AssetTypesAssociateReportForm.vue'
import AssetTypesAssignCustomAttributeForm from '@/tt-entity-design/src/components/asset-types/AssetTypesAssignCustomAttributeForm.vue'
import AssetTypesIcon from '@/tt-entity-design/src/components/asset-types/AssetTypesIcon.vue'
import AssetTypesAssociateFileForm from './AssetTypesAssociateFileForm.vue'
import AssetTypesCategoryAttr from './AssetTypesCategoryAttr.vue'
import AssetTypesMetaCardInstructions from './AssetTypesMetaCardInstructions.vue'
import AssetTypesAddTranslationForm from './AssetTypesAddTranslationForm.vue'
import { Resources } from '../../types'
import EntityRelationListCustomPreviewWrapper from '@/tt-widget-entity-flow/components/EntityRelationListCustomPreviewWrapper.vue'
import { getContextAccountInfo } from '@/apps/app.tracktik.assets/helpers'

/**
 * TODO: Move Asset Tracking's business logics to the application folder: SDAM-957
 */
import { AssetsTranslationsActions } from '@/apps/app.tracktik.assets/utils/constants'

export default {
  install(Vue) {
    Vue.component('AssetTypesForm', AssetTypesForm)
    Vue.component('AssetTypesIcon', AssetTypesIcon)
    Vue.component('AssetTypesAssociateFileForm', AssetTypesAssociateFileForm)
    Vue.component('AssetTypesCategoryAttr', AssetTypesCategoryAttr)
    Vue.component('AssetTypesAddTranslationForm', AssetTypesAddTranslationForm)
    Vue.component(
      'AssetTypesMetaCardInstructions',
      AssetTypesMetaCardInstructions,
    )
    Vue.component(
      'AssetTypesAssociateReportForm',
      AssetTypesAssociateReportForm,
    )
    Vue.component(
      'AssetTypesAssignCustomAttributeForm',
      AssetTypesAssignCustomAttributeForm,
    )
    registerResourceForm(Resources.ASSET_TYPES, 'AssetTypesForm')
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssociateFileForm',
      'associate-file',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssociateReportForm',
      'assign-report-templates',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssociateReportForm',
      'unassign-report-templates',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssignCustomAttributeForm',
      'assign-custom-attribute',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssignCustomAttributeForm',
      'unassign-custom-attribute',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAddTranslationForm',
      'add-translation',
    )

    // Adding instructions panel
    const customFieldsDetails: PresetItem = {
      title: i18n.t('asset-types.tabs.instructions'),
      data: { is: 'AssetTypesMetaCardInstructions', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }
    modularManager.setResourcePresets(
      Resources.ASSET_TYPES,
      PresetTypes.DETAILS_CARD,
      [customFieldsDetails],
    )

    Vue.component(
      'EntityRelationListCustomPreviewWrapper',
      EntityRelationListCustomPreviewWrapper,
    )

    // Set the color
    setIconAndColorResourcePreset(Resources.ASSET_TYPES, 'mdi-tag', 'orange')

    // Add transactions
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Assets',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'type.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSETS,
          },
        },
      },
      condition: ({ itemHook }) => {
        const isRegion = getContextAccountInfo(itemHook.appContext).isRegion

        return !isRegion
      },
    })

    // Add files list tab
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Files',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'files',
        showToolbar: false,
      },
    })

    // Add incident reports tab
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Report Forms',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'reportTemplates',
        showToolbar: false,
      },
      //@TODO: to remove when available in the API and validated by Securitas
      condition: ({ itemHook }) => {
        const isRegion = getContextAccountInfo(itemHook.appContext).isRegion
        // checks that the attribute exists in the API
        const isAvailableInAPI =
          !!itemHook.appContext.widgetServices.resourceMetaManager.getAttribute(
            Resources.ASSET_TYPES,
            'reportTemplates',
          )

        return isAvailableInAPI && !isRegion
      },
    })

    // Add custom attributes tab
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Custom Fields',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'customAttributes',
        showToolbar: false,
      },
      condition: ({ itemHook }) => {
        const isRegion = getContextAccountInfo(itemHook.appContext).isRegion

        return !isRegion
      },
    })

    // Add translations
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Translations',
      is: 'AssetsTranslationsTile',
      props: {
        resource: Resources.ASSET_TYPE_TRANSLATIONS,
        parentResource: Resources.ASSET_TYPES,
        deleteAction: AssetsTranslationsActions.DELETE_ASSET_TYPE_TRANSLATION,
      },
    })
  },
}
