<template>
  <div :key="`${uniqueId}`" class="app-window--container">
    <div
      v-for="(page, index) in pageStack"
      v-show="index === pageStack.length - 1"
      :key="`${uniqueId}-${index}`"
      class="fill-height"
    >
      <PageBlock
        slot="before"
        :active="isPageActive(index)"
        :closable="closable"
        :page="page"
        :page-index="index"
        :back-button="canGoBack"
        @close="close"
        @open="next($event)"
        @back="back"
        @beforeClose="setBeforeClose($event)"
      >
        <div v-if="breadcrumb" slot="beforeContent">
          <div class="ma-2">
            <span v-for="(page, index) in pageStack" :key="'B' + index">
              &nbsp;
              <v-icon size="12">keyboard_arrow_right</v-icon>&nbsp;
              <a style="color: #333" @click="goTo(index)">{{ page.title }}</a>
            </span>
          </div>
        </div>
        <template #before="slotProps">
          <slot name="before" v-bind="slotProps" />
        </template>
        <template #title="slotProps">
          <slot name="title" v-bind="slotProps" />
        </template>
      </PageBlock>
    </div>
    <div :style="displayIfOnlyOnePage" class="fill-height flex-column">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import uniqueId from 'lodash/uniqueId'

import { UnsubscribeFunction } from '@tracktik/tt-event-manager'

import PageBlock from './PageBlock.vue'
import { ActiveWindowProvider } from '..'
import { createPageStackHandler, PageStackHandler } from '../PageStackHandler'
import { LayoutComponents, LayoutWindowEvent, PageInterface } from '../types'

export default Vue.extend({
  name: 'AppWindow',
  components: { PageBlock },
  provide(): ActiveWindowProvider {
    return {
      activeWindow: {
        back: this.back,
        goTo: this.goTo,
        next: this.next,
        name: this.layoutComponentName,
      },
    }
  },
  props: {
    landingPage: {
      type: Object as PropType<PageInterface>,
      default: null,
    },
    darkTitleBar: {
      type: Boolean,
      default: false,
    },
    breadcrumb: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: null,
    },
    event: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    pageStackHandler: {
      type: Object as PropType<PageStackHandler>,
      default: null,
    },
    layoutComponentName: {
      type: String as PropType<LayoutComponents>,
      default: undefined,
    },
  },
  data() {
    return {
      uniqueId: uniqueId(),
      unregisterCallbacks: [] as UnsubscribeFunction[],
      beforeClose: () => true,
      internalPageStackHandler:
        this.pageStackHandler || createPageStackHandler(),
    }
  },
  computed: {
    hasDefaultSlot(): boolean {
      return !!this.$slots.default
    },
    canGoBack(): boolean {
      return this.hasDefaultSlot ? true : this.pageStack.length > 1
    },
    displayIfOnlyOnePage(): any {
      // v-show does not work as Vuetify 'd-flex' class has higher priority (!important)
      return { display: this.pageStack.length === 0 ? 'flex' : 'none' }
    },
    pageStack(): PageInterface[] {
      return this.internalPageStackHandler.getPageStack() || []
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.internalPageStackHandler.setPageStack([])
      }
    },
  },
  created() {
    if (this.landingPage) {
      this.start(this.landingPage)
    }
  },
  mounted() {
    if (this.event) {
      this.unregisterCallbacks.push(
        this.$eventManager.subscribeEvent(this.event, this.start),
      )
    }
  },
  beforeDestroy() {
    this.unregisterCallbacks.forEach((callback) => {
      callback()
    })
  },
  methods: {
    /**
     * Go to a specific position in the stack
     * @param index
     */
    goTo(index: number) {
      this.internalPageStackHandler.goTo(index)
    },
    isPageActive(index: number): boolean {
      return index === this.pageStack.length - 1
    },
    /**
     * Close the stack
     */
    async close() {
      const close = await this.beforeClose()
      if (!close) {
        return
      }

      this.internalPageStackHandler.reset()
      this.$emit('input', false)
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.FULL_PAGE_DIALOG_CLOSED,
        {},
      )
      this.$props.onClose ? this.$props.onClose() : null
    },
    /**
     * Restart a stack
     * @param page
     */
    start(page: PageInterface) {
      this.uniqueId = uniqueId()
      this.$emit('input', true)
      this.internalPageStackHandler.setPageStack([page])
    },
    /**
     * Add a page
     * @param page
     */
    next(page: PageInterface) {
      this.$emit('input', true)
      this.internalPageStackHandler.next(page)
    },
    /**
     * Go back
     */
    back() {
      this.internalPageStackHandler.back()
      if (this.pageStack.length === 0) this.close()
    },
    setBeforeClose(beforeClose) {
      this.beforeClose = beforeClose
    },
  },
})
</script>

<style scoped>
.app-window--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
</style>
