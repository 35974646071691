<template>
  <div>
    <div
      class="datatable-row--left-border"
      :class="
        assignedOperatorId ? 'left-border--success' : 'left-border--warning'
      "
    />
    <tt-attr :name="attributeName" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Attribute } from '@/tt-entity-design/src/schema-types'

const ATTR_NAME: Attribute<'system-exception-tickets'> = 'assignedOperator'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsOperator',
  inject: ['getItemHook'],
  computed: {
    attributeName(): string {
      return ATTR_NAME
    },
    assignedOperatorId(): string {
      return this.getItemHook().getRawValue(ATTR_NAME + '.id')
    },
  },
  created() {
    this.getItemHook().addAttribute(ATTR_NAME + '.id')
  },
})
</script>

<style scoped>
.datatable-row--left-border {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 !important;
}

.left-border--warning {
  border-left: 4px solid var(--v-warning-base);
}

.left-border--error {
  border-left: 4px solid var(--v-error-base);
}

.left-border--success {
  border-left: 4px solid var(--v-success-base);
}
</style>
