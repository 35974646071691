import { ValueOf } from '@/helpers/types/ValueOf'

/**
 * These are the database entries that describe the exceptionTypes
 * since the original idea was that the root exceptionTypes could
 * be created by the users.
 * They are created by migrations and thus we know the exact ids
 * of the root exceptionTypes
 **/
export const rootExceptionTypeId = {
  HolidayBeforeWeekDay: 7,
  WeekdayBeforeEve: 8,
  WeekdayBeforeHoliday: 9,
  HolidayEve: 10,
  Closed: 11,
  Holiday: 12,
}

export type ExceptionTypeId = ValueOf<typeof rootExceptionTypeId>

export interface ExceptionTypeColor {
  backgroundColor: string
  textColor: string
}

export const exceptionTypeColorMap: Record<
  ExceptionTypeId,
  ExceptionTypeColor
> = {
  [rootExceptionTypeId.Closed]: {
    backgroundColor: '#E9EAFF',
    textColor: '#405CE7',
  },
  [rootExceptionTypeId.Holiday]: {
    backgroundColor: '#FFEFE1',
    textColor: '#F17300',
  },
  [rootExceptionTypeId.HolidayEve]: {
    backgroundColor: '#FCC89A',
    textColor: '#7D3C01',
  },
}

export const getExceptionTypeColor = (
  id: ExceptionTypeId,
): ExceptionTypeColor | null => {
  const { [id]: color } = exceptionTypeColorMap

  return color ?? null
}
