import {
  EventHandler,
  EventManagerInterface,
  GlobalEventHandler,
} from '@tracktik/tt-event-manager'
import { ContextManagerBaseInterface } from '../types'
import { AppContextEventPayload } from '@/tt-app-context'

/**
 * Context manager base
 */
export default class ContextManagerBase implements ContextManagerBaseInterface {
  eventManager: EventManagerInterface<AppContextEventPayload>

  constructor(eventManager: EventManagerInterface<AppContextEventPayload>) {
    this.eventManager = eventManager
  }

  subscribeGlobal(handler: GlobalEventHandler): void {
    this.eventManager.subscribeGlobal(handler)
  }

  dispatchEvent<
    E extends keyof AppContextEventPayload,
    P extends AppContextEventPayload[E],
  >(eventName: E & string, payload: P, contextId?: string | undefined): void {
    this.eventManager.dispatchEvent(eventName, payload, contextId)
  }

  subscribeEvent(
    eventName: string,
    handler: EventHandler,
    contextIds?: string[] | undefined,
  ) {
    return this.eventManager.subscribeEvent(eventName, handler, contextIds)
  }
}
