import { SessionRegion } from '@tracktik/tt-authentication'
import { compact } from 'lodash'
import {
  Item,
  SUBSCRIPTION_STATUS,
  TreeviewItem,
  pusherStatusType,
} from '@/tt-widget-components/components/treeview/types'
import { extractId } from '@/tt-region-manager/extractId'
import { RegionManager } from '@/tt-region-manager/types'
import { getUniqueRegions } from '@/tt-region-manager/utils'
import { Resources } from '@/tt-entity-design/src/types'
import { AppContext } from '@/tt-app-context'

export type UserRegion = SessionRegion

export type FlatTreeItem = {
  parentId: string | null // if null === the top parent ID
  id: string
}

/**
 * Converts a list of objects to a tree of `TreeviewItem`s by matching the
 * `idAttribute` of the parent items to the `parentIdAttribute` of their
 * children
 *
 * @param list
 * @param itemToTreeItemFn
 * @param parentIdAttribute
 * @param idAttribute
 * @param parentId
 */
export const listToTree = <
  Item extends Record<string, any>,
  Id extends keyof Item,
>(
  list: Item[],
  itemToTreeItemFn: (item: Item) => TreeviewItem,
  parentIdAttribute = 'parentId' as Id,
  idAttribute = 'id' as Id,
  parentId?: Item[Id], // accepts multiple parent IDs (regions with a parentId the user does not see)
  sortFn: (a: TreeviewItem, b: TreeviewItem) => number = compareName,
): TreeviewItem[] => {
  const getId = (item) => item[idAttribute]
  const getParentId = (item) => item[parentIdAttribute]

  const isOrphan = (item) =>
    !list.find((searchItem) => getId(searchItem) === getParentId(item))

  const isDirectChildrenOfParent = (item) => getParentId(item) === parentId

  const isRootItem = (item) =>
    parentId ? isDirectChildrenOfParent(item) : isOrphan(item)

  const createTreeViewItem = (item: Item): TreeviewItem => ({
    children: listToTree(
      list,
      itemToTreeItemFn,
      parentIdAttribute,
      idAttribute,
      getId(item),
      sortFn,
    ),
    ...itemToTreeItemFn(item),
  })

  return list.filter(isRootItem).map(createTreeViewItem).sort(sortFn)
}

/**
 * TODO: how to properly sort by language locales
 */
const compareName = (a: TreeviewItem, b: TreeviewItem) =>
  a.name.localeCompare(b.name)

const itemToTreeFn = ({ id, name }: UserRegion): TreeviewItem => ({
  id,
  name,
})

/**
 * Given an item ID in a tree, returns the corresponding item
 */
export const findItem = (
  treeItems: TreeviewItem[],
  id: TreeviewItem['id'],
): TreeviewItem | undefined => {
  const found = treeItems.find((subTree) => subTree.id === id)

  if (found) return found

  const allDirectChildren = treeItems.flatMap((tree) => tree?.children || [])

  return findItem(allDirectChildren, id)
}

/**
 * Run the checkItemFn on every leaf down the hierarchy of the given item
 *
 * @param item
 * @param checkItemFn
 * @param operation
 */
const checkTreeBranches = (
  item: TreeviewItem,
  checkItemFn: (item: TreeviewItem) => boolean,
  operation: keyof Pick<Array<TreeviewItem>, 'some' | 'every'> = 'every',
): boolean => {
  if (item.children.length > 0) {
    return item.children[operation]((item) =>
      checkTreeBranches(item, checkItemFn, operation),
    )
  }

  return checkItemFn(item)
}

/**
 * If the given item has children, checks recursively if every leaf down the
 * hierarchy are selected.
 * Otherwise just checks if the `id` of the given item is present in the
 * provided list of selected ids.
 *
 * @param item
 * @param selectedIds
 */
const areLeavesSelected = (
  item: TreeviewItem,
  selectedIds: TreeviewItem['id'][],
): boolean => {
  const checkItemFn = (item: TreeviewItem) => selectedIds.includes(item.id)

  return checkTreeBranches(item, checkItemFn)
}

/**
 * Not Being used at the moment but keeping method in case we want to go back to
 * the "leaf" UI concept.
 *
 * VTreeview's `selection-type="leaf"` doesn't consider parent items as
 * selectable, therefore when you select a parent item or all of its children,
 * the item will be shown as selected in the UI, but its id will not be in the
 * component value.
 * This method returns the union of the provided `selectedIds` and the ids of
 * the parent items in the situation mentioned above.
 *
 * @param selectedIds
 * @param treeItems
 */
export const selectedItemsWithParents = (
  selectedIds: TreeviewItem['id'][],
  treeItems: TreeviewItem[],
): TreeviewItem[] => {
  return treeItems.reduce((acc, item) => {
    const selectedChildren = selectedItemsWithParents(
      selectedIds,
      item.children,
    )

    return compact([
      ...acc,
      areLeavesSelected(item, selectedIds) ? item : null,
      ...selectedChildren,
    ])
  }, [])
}

/**
 * Returns list of regions where their parentRegion is not in the provided list.
 */
export const getRegionsWithUnknownParent = (regions: UserRegion[]) => {
  const regionIds = regions.map(extractId)
  const parentIdDoesNotExist = ({ parentId }) => !regionIds.includes(parentId)

  return regions.filter(parentIdDoesNotExist)
}

/**
 * Return the top parent region from a list of regions tree
 */
export const getTopParentRegion = (regions: UserRegion[]): UserRegion => {
  const regionsWithoutParent = getRegionsWithUnknownParent(regions)

  if (regionsWithoutParent.length < 1) console.error('No top parent id found.')

  if (regionsWithoutParent.length > 1)
    console.error('More than 1 top parent id found.', regionsWithoutParent)

  return regionsWithoutParent[0]
}

export const getAllChildren = (item: TreeviewItem): TreeviewItem[] => {
  const { children, ...itemWithoutChildren } = item
  const childrenFlat = (children || []).map(getAllChildren).flat()

  return [itemWithoutChildren, ...childrenFlat]
}

/**
 * Convert a tree of items -> to a flat list of items with added `parentId`
 */
export const flattenTreeItemsWithParentId = (
  item: TreeviewItem,
): FlatTreeItem[] => {
  const _flatten = (item: TreeviewItem, parentId: string | null) => {
    const { children, ...restItem } = item
    const currentId = restItem.id

    const childrenFlat = (children || [])
      .map((child) => _flatten(child, currentId))
      .flat()

    const itemWithParentId: FlatTreeItem = { id: currentId, parentId }

    return [itemWithParentId, ...childrenFlat]
  }

  return _flatten(item, null)
}

/**
 * Given a flat tree of items (FlatTreeItem) and a list of selected IDs,
 * returns all the parent IDs of the selected IDs
 */
export const getAllParentIdsFromFlatTree = (
  ids: TreeviewItem['id'][],
  flatTreeItems: FlatTreeItem[],
): string[] => {
  const getParentIds = (id) => getAllParentIdsFromFlatTree([id], flatTreeItems)

  const allBranchIds = flatTreeItems
    .filter(({ id }) => ids.includes(id))
    .map(({ id, parentId }) =>
      id === null ? [id] : [id, ...getParentIds(parentId)],
    )
    .flat()
    .filter(Boolean)

  return [...new Set(allBranchIds)]
}

/**
 * Given a tree of items (TreeviewItem) and a list of IDs,
 * returns all the parent IDs of the selected IDs.
 *
 * Same as getAllParentIds but with a tree of items.
 */
export const getAllParentIdsFromTree = (
  ids: string[],
  treeItems: TreeviewItem,
) => {
  const flattenTreeItems = flattenTreeItemsWithParentId(treeItems)

  return getAllParentIdsFromFlatTree(ids, flattenTreeItems)
}

/**
 * Converts a list of regions to a tree of `TreeviewItem`s
 */
export const convertRegionsToTreeItems = (
  regionManager: RegionManager,
  regions: UserRegion[],
): TreeviewItem[] => {
  const itemToTreeFn = ({ id, name, status }: UserRegion): TreeviewItem => ({
    id,
    name,
    disabled: !regionManager.hasAccessToRegionId(id),
    // @ts-ignore
    status,
  })

  const contextRegion = regionManager.getMainContextRegion()

  // build a tree from the regionManager with the missing stuff automatically populated properly
  const missingRegions = regionManager.getMissingParentRegions()

  const allRegions = getUniqueRegions([
    ...(contextRegion ? [contextRegion] : []),
    ...regions,
    ...missingRegions,
  ])

  return listToTree(
    allRegions,
    itemToTreeFn,
    'parentId',
    'id',
    contextRegion?.id,
  )
}

/**
 * Returns true or false if the item (region) is inside the current context
 */
export const isInsideCurrentContextRegion = (
  item: Item,
  appContext: AppContext,
): boolean => {
  return appContext.regionManager.getContextRegionIds().includes(item.id)
}

/**
 * Given the region return the status color of the pusher
 * If this region has all the subscriptions return success
 * If this region has no subscriptions return error
 * If this region has some subscriptions return warning
 */
export const getPusherStatusColor = (
  region: Item,
  appContext: AppContext,
): pusherStatusType => {
  if (!appContext.pusherSdk) return SUBSCRIPTION_STATUS.ERROR

  const getSubscription = (resource: Resources) =>
    appContext.pusherSdk.getSubscription(resource, Number(region.id))

  const subscriptions = [
    getSubscription(Resources.DISPATCH_TASKS),
    getSubscription(Resources.SYSTEM_EXCEPTION_TICKETS),
  ]

  const validSubscriptions = subscriptions.filter(Boolean)

  const hasAllSubscriptions = validSubscriptions.length === subscriptions.length

  const hasNoSubscriptions = validSubscriptions.length === 0

  if (hasAllSubscriptions) return SUBSCRIPTION_STATUS.SUCCESS
  else if (hasNoSubscriptions) return SUBSCRIPTION_STATUS.ERROR
  else return SUBSCRIPTION_STATUS.WARNING
}

/**
 * Given a regions tree and a list of region IDs,
 * Returns the lowest child regions from the list.
 */
export const getLowestChildRegionsFromList = (
  treeItems: TreeviewItem[],
  ids: string[],
): string[] => {
  return ids.filter((id) => {
    const item = findItem(treeItems, id)

    const isInList = (child) => ids.includes(child.id)

    return !item.children?.some(isInList)
  })
}
