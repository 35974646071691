<template>
  <div>
    <json-object :name="dayName">
      <v-row :class="{ disabledRow: !enableRow }" class="mt-3">
        <v-col cols="3" lg="2" class="d-flex" style="align-items: center">
          <v-checkbox
            v-if="!alwaysEnabled"
            v-model="enableRow"
            :label="effectiveLabel"
            class="mt-0"
          >
            <template #prepend>
              <v-btn
                v-if="hasChildren"
                icon
                small
                @click="expanded = !expanded"
              >
                <v-icon>
                  {{ expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                </v-icon>
              </v-btn>
            </template>
          </v-checkbox>
          <div v-else class="mt-5">
            {{ effectiveLabel }}
          </div>
        </v-col>
        <v-col cols="9" lg="10" @click="enableRow = true">
          <v-row>
            <v-col cols="12" lg="4" class="d-flex justify-lg-end py-0 py-lg-3">
              <!--Fake button to show when the row is disabled -->
              <div v-if="showPerformButton && !enableRow">
                <SchedulingGroupDoNotPerformButton
                  :value="parentDoNotPerform"
                  :enable-perform-on-exception-day="enablePerformOnExceptionDay"
                  disabled
                />
              </div>
              <json-field
                v-else-if="showPerformButton && enableRow"
                :name="doNotPerformKey"
                :enable-perform-on-exception-day="enablePerformOnExceptionDay"
                as="SchedulingGroupDoNotPerformButton"
              />
            </v-col>
            <v-col cols="12" lg="8">
              <div v-if="!enableRow" inert>
                <!-- Show the parent value if it's available, otherwise just show the disabled inputs for the current object-->
                <slot name="parentValue">
                  <v-row>
                    <v-col
                      v-for="property in properties"
                      :key="`${dayName}-${property}`"
                      cols="6"
                    >
                      <json-field :name="property" />
                    </v-col>
                  </v-row>
                </slot>
              </div>
              <div v-else>
                <v-row>
                  <v-col
                    v-for="property in properties"
                    :key="`${dayName}-${property}`"
                    cols="6"
                    :class="{
                      'v-col-disabled': !shouldAllowEnterValue(dayName),
                    }"
                  >
                    <template v-if="!shouldAllowEnterValue(dayName)">
                      <div class="field-error">
                        <span>
                          {{ showValidationMessage(dayName) }}
                        </span>
                      </div>
                      <json-field :name="property" :disabled="true" />
                    </template>
                    <template v-else>
                      <json-field :name="property" />
                    </template>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </json-object>
    <div v-if="hasChildren" v-show="expanded">
      <v-row style="margin-top: -40px">
        <v-col cols="11" offset="1">
          <SchedulingGroupDaySetupDayRow
            v-for="(day, index) in children"
            :key="`${dayName}-${day}`"
            :day-name="day"
            :is-holiday="isHoliday"
            :is-others="isOthers"
            :holidays="holidays"
            :parent-do-not-perform="!perform"
            :show-divider="index !== children.length - 1"
            :enable-perform-on-exception-day="enablePerformOnExceptionDay"
          >
            <template #parentValue>
              <div>
                <v-row class="parentField">
                  <v-col
                    v-for="property in properties"
                    :key="`${dayName}-${property}`"
                    cols="6"
                  >
                    <json-field
                      :name="`${parentName}.${dayName}.${property}`"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </SchedulingGroupDaySetupDayRow>
        </v-col>
      </v-row>
    </div>
    <v-divider v-if="showDivider" />
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
import { rangeTimeKeys } from '@/tt-entity-design/src/components/mobile-runsheet-groups/types'

import { ExceptionType, weekDays, weekEnds, PerformType } from '../types'
import { getFieldAbsoluteName } from '@tracktik/tt-json-schema-form'
import { getHolidayKey, parseOthersKey } from '../helpers/schema-helper'
import { rootExceptionTypeId } from '@/tt-entity-design/src/components/exception-types/exception-types-parent'

type VueWithInjections = Vue & FormHookProvider & NamespaceProvider

export default (Vue as VueConstructor<VueWithInjections>).extend({
  name: 'SchedulingGroupDaySetupDayRow',
  inject: ['formHook', 'namespace'],
  props: {
    alwaysEnabled: { type: Boolean, default: false },
    children: { type: Array as PropType<string[]>, default: null },
    dayName: { type: String, required: true },
    disabled: { type: Boolean, default: true },
    isHoliday: { type: Boolean, default: false },
    holidays: { type: Array as PropType<ExceptionType[]>, default: () => [] },
    isOthers: { type: Boolean, default: false },
    parentDoNotPerform: { type: String, default: PerformType.PERFORM_TASK },
    showDivider: { type: Boolean, default: true },
    enablePerformOnExceptionDay: { type: Boolean, default: false },
  },
  data() {
    return {
      enableRow: !this.disabled || this.alwaysEnabled,
      expanded: false,
    }
  },
  computed: {
    absoluteName(): string {
      return getFieldAbsoluteName(this)
    },
    childrenValues(): Record<string, Record<string, unknown>> | null {
      if (!this.children) return null

      const entries = this.children.map((child) => [
        child,
        this.formHook().getPathValue(this.getFieldPath(child)),
      ])

      return Object.fromEntries(entries)
    },
    childrenHaveValues(): boolean {
      return Object.values(this.childrenValues ?? {}).some(
        (value) => value != null,
      )
    },
    doNotPerformKey(): string {
      return 'performType'
    },
    effectiveLabel(): string {
      return this.label || this.translateDayName(this.dayName)
    },
    name(): string {
      return this.dayName
    },
    fieldPath(): string {
      return this.getFieldPath(this.dayName)
    },
    hasChildren(): boolean {
      return this.children?.length > 0
    },
    label(): string {
      return this.viewComponentProps.label
    },
    parentName(): string {
      return this.isHoliday ? 'holidays' : this.isOthers ? 'others' : 'days'
    },
    showPerformButton(): boolean {
      return this.isHoliday || this.isOthers
    },
    perform(): string {
      return this.formHook().getPathValue(
        `${this.parentName}.${this.dayName}.${this.doNotPerformKey}`,
      )
    },
    holidaysFormValues(): any {
      const parentValue = this.formHook().getPathValue(`holidays`)

      return {
        ...parentValue,
      }
    },
    properties(): string[] {
      if (this.isHoliday && this.perform === PerformType.DONT_PERFORM_TASK) {
        return []
      }

      return [rangeTimeKeys.START_TIME_KEY, rangeTimeKeys.END_TIME_KEY]
    },
    value(): Record<string, unknown> {
      return this.formHook().getPathValue(this.fieldPath)
    },
    viewComponentProps(): any {
      return this.formHook().getViewComponentProps(this.absoluteName)
    },
  },
  watch: {
    children: {
      immediate: true,
      handler(children: string[] | null) {
        // Automatically expand row if children have values
        if (children && this.childrenHaveValues && !this.expanded) {
          this.expanded = true
        }
      },
    },
    enableRow(isRowEnabled: boolean) {
      if (isRowEnabled && this.value == null) {
        this.formHook().setObjectValue(this.fieldPath, {})
        if (this.isHoliday) {
          this.formHook().setObjectValue(
            `${this.parentName}.${this.dayName}.${this.doNotPerformKey}`,
            PerformType.PERFORM_TASK,
          )
        }
      } else if (!isRowEnabled && this.value) {
        this.formHook().setObjectValue(this.fieldPath, null)
      }
    },
    value: {
      immediate: true,
      handler(value: Record<string, unknown> | null) {
        // Automatically enables row if it has value
        if (value != null && !this.enableRow) {
          this.enableRow = true
        }
      },
    },
  },
  methods: {
    getFieldPath(dayName: string): string {
      return `${this.parentName}.${dayName}`
    },
    translateDayName(dayName: string): string {
      // @ts-ignore
      const key = [...weekDays, ...weekEnds].includes(dayName)
        ? `res.mobile-runsheets.attr.dayOfWeek.list.${dayName}.label`
        : `scheduling_group_create_wizard.days_setup.${dayName.toLowerCase()}`

      return this.$t(key) as string
    },
    shouldAllowEnterValue(dayName: string): boolean {
      const formValues = this.holidaysFormValues

      const isSet = (id: number): boolean => {
        const parent = formValues[getHolidayKey(id)]
        if (parent && parent.rangeStartTime && parent.rangeEndTime) {
          return true
        }

        const parentData = this.holidays.find((h) => h.id === id)
        if (!parentData || !parentData.children) {
          return false
        }

        return parentData.children.some((child) => {
          const childData = formValues[getHolidayKey(child.id)]

          return childData && childData.rangeStartTime && childData.rangeEndTime
        })
      }
      const parsedOthers = parseOthersKey(dayName)
      if (parsedOthers == rootExceptionTypeId.WeekdayBeforeEve) {
        const hasBeenSet = isSet(rootExceptionTypeId.HolidayEve)
        if (!hasBeenSet) {
          this.formHook().setObjectValue(this.getFieldPath(this.dayName), {})
        }

        return hasBeenSet
      } else if (parsedOthers == rootExceptionTypeId.WeekdayBeforeHoliday) {
        const hasBeenSet = isSet(rootExceptionTypeId.Holiday)
        if (!hasBeenSet) {
          this.formHook().setObjectValue(this.getFieldPath(this.dayName), {})
        }

        return hasBeenSet
      } else if (parsedOthers == rootExceptionTypeId.HolidayBeforeWeekDay) {
        const hasBeenSet = isSet(rootExceptionTypeId.Holiday)
        if (!hasBeenSet) {
          this.formHook().setObjectValue(this.getFieldPath(this.dayName), {})
        }

        return hasBeenSet
      } else if (parsedOthers == 0) {
        const hasBeenSetAnyHoliday = isSet(rootExceptionTypeId.Holiday)
        const hasBeenSetAnyHolidayEve = isSet(rootExceptionTypeId.HolidayEve)

        return hasBeenSetAnyHoliday && hasBeenSetAnyHolidayEve
      } else {
        return true
      }
    },
    showValidationMessage(dayName: string) {
      const parsedOthers = parseOthersKey(dayName)

      if (parsedOthers == rootExceptionTypeId.WeekdayBeforeEve) {
        return this.$t(
          'scheduling_group.others.weekday_before_eve.validation.label',
        )
      } else if (parsedOthers == rootExceptionTypeId.WeekdayBeforeHoliday) {
        return this.$t(
          'scheduling_group.others.weekday_before_holiday.validation.label',
        )
      } else if (parsedOthers == rootExceptionTypeId.HolidayBeforeWeekDay) {
        return this.$t(
          'scheduling_group.others.holiday_before_weekday.validation.label',
        )
      } else {
        return this.$t('scheduling_group.others.validation.label')
      }
    },
  },
})
</script>
<style scoped>
.disabledRow >>> fieldset {
  color: rgba(0, 0, 0, 0.38) !important;
}
.disabledRow >>> .v-input label {
  color: rgba(0, 0, 0, 0.38);
}
[inert] >>> fieldset,
[inert] >>> .v-input input {
  color: rgba(0, 0, 0, 0.38);
}
/* Hide clear button on duplicated fields */
[inert] >>> .v-input__append-inner {
  display: none;
}
.parentField >>> .v-input .error--text {
  color: rgba(0, 0, 0, 0.38) !important;
}

.parentField >>> .v-text-field__details {
  display: none;
}
.v-col-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.field-error {
  color: red;
  font-size: 12px;
  margin-top: -17px;
}
</style>
