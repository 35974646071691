class i {
  constructor() {
    this.eventHandlerMap = {}, this.globalEventHandlers = [];
  }
  /**
   * Subscribe to all events
   * @param handler
   */
  subscribeGlobal(n) {
    this.globalEventHandlers.push(n);
  }
  /**
   * Dispatch an event
   *
   * @param eventName
   * @param payload
   * @param contextId
   */
  dispatchEvent(n, r, t) {
    console.info(
      "MODULE_EVENT",
      n,
      r,
      `context ${t || "not defined"}`
    ), this.globalEventHandlers.length && this.globalEventHandlers.forEach((s) => {
      s(n, r, t);
    });
    let e = this.eventHandlerMap[n] || [];
    if (e.length === 0) {
      console.info(`no callback subscribed for event: ${n}`);
      return;
    }
    t && (e = e.filter(
      ({ contextIds: s }) => s && s.includes(t)
    )), e.forEach((s) => {
      s.handler(r);
    });
  }
  /**
   * Subcribe to an event
   *
   * @param eventName
   * @param handler
   * @param contextIds
   */
  subscribeEvent(n, r, t) {
    const e = { handler: r, contextIds: t };
    return this.eventHandlerMap[n] = this.eventHandlerMap[n] || [], this.eventHandlerMap[n].push(e), () => {
      this.eventHandlerMap[n] = this.eventHandlerMap[n].filter(
        (s) => s !== e
      );
    };
  }
}
const a = () => {
  const l = new i();
  return l.dispatchEvent = jest.fn(), l.subscribeEvent = jest.fn(), l;
};
export {
  i as EventManager,
  a as mockEventManager
};
