import { AuthModule } from '@tracktik/tt-authentication'

import { BatchFile, BatchFileResponse } from '@/types'
import { EventManagerInterface } from '@tracktik/tt-event-manager'
import { SecurityRuleOperation } from '@/tt-widget-factory'

import { EntityIntentEventPayload, EntityIntentTypes } from './intents'
import { AppContextEventPayload } from '@/tt-app-context'

/**
 * Entity runner
 */
export class EntityPersistRunner {
  authModule: AuthModule
  eventManager: EventManagerInterface<AppContextEventPayload>

  constructor(
    authModule: AuthModule,
    eventManager: EventManagerInterface<AppContextEventPayload>,
  ) {
    this.authModule = authModule
    this.eventManager = eventManager
  }

  /**
   * Add entity
   * @param resource
   * @param entity
   */
  async addEntity(resource: string, entity: any) {
    const newEntity = await this.api.create<{ id }>(resource, entity)
    this.success({
      resource,
      entityId: newEntity.id,
      operation: SecurityRuleOperation.CREATE,
    })

    return newEntity
  }

  get api() {
    return this.authModule.getApi()
  }

  /**
   * Update entity
   * @param resource
   * @param entity
   * @param entityId
   */
  async updateEntity(resource: string, entity: any, entityId: number) {
    const updatedEntity = await this.api.update(resource, entityId, entity)
    this.success({ resource, entityId, operation: SecurityRuleOperation.EDIT })

    return updatedEntity
  }

  async deleteEntity(resource: string, entityId: number) {
    const response = await this.api.delete(resource, entityId)
    this.success({
      resource,
      entityId,
      operation: SecurityRuleOperation.DELETE,
    })

    return response
  }

  /**
   * Execute batch action
   * @param resource
   * @param actionName
   * @param entityIds
   * @param data
   * @lint-ignore
   */
  async executeBatchAction(
    resource: string,
    actionName: string,
    entityIds: number[],
    data?: any,
  ) {
    await this.api.doBatchActions(resource, entityIds, actionName, data)

    return this.success({
      resource,
      operation: SecurityRuleOperation.EXECUTE,
      actionName,
    })
  }

  /**
   * Execute entity action
   * @param resource
   * @param actionName
   * @param entityId
   * @param data
   */
  async executeEntityAction(
    resource: string,
    actionName: string,
    entityId: number,
    data?: any,
  ) {
    await this.api.doAction(resource, entityId, actionName, data)

    return this.success({
      resource,
      entityId,
      operation: SecurityRuleOperation.EXECUTE,
      actionName,
    })
  }

  /**
   * Batch multiple persister transaction from different resources in the same
   * JSON file
   */
  async executeBatchFileRequest({
    onFailure,
    operations,
  }: BatchFile): Promise<BatchFileResponse> {
    if (operations.length === 0) {
      return null
    }

    const response = await this.api.processBatchFile(operations, onFailure)

    response.success.forEach((item) => {
      this.success({
        resource: item.resource,
        entityId: item.resourceId && Number(item.resourceId),
        // the batch file response doesn't contain information about the operation or actionName
      })
    })

    return response
  }

  /**
   * Success message
   */
  private success({
    resource,
    entityId,
    operation,
    actionName,
  }: EntityIntentEventPayload[EntityIntentTypes.RESOURCE_UPDATED]): void {
    this.eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
      resource,
      entityId,
      operation,
      actionName,
    })
  }
}
