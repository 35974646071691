import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  'customId',
  {
    attributeName: 'taskType.name',
    headerText: 'res.mobile-schedule-groups.attr.taskType.label',
  },
  {
    attributeName: 'taskPriceTier.name',
    headerText: 'res.mobile-schedule-groups.attr.priceTier.label',
    component: {
      props: {
        emptyValue: 'mobile_schedule_group.list.not_billable',
      },
    },
  },
  'beginServiceDate',
  'endServiceDate',
] as ColumnInputDefinition[]
