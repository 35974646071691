<template>
  <v-chip
    :color="color.background"
    :text-color="color.text"
    pill
    :class="{ 'affected-shifts-in-details': inDetails }"
    class="cursor-pointer"
    :data-analytics="
      !inDetails && 'leave-requests-affected-shifts-column-counter'
    "
  >
    <span :style="{ color: color.text }">{{ affectedShiftsAmount }}</span>
  </v-chip>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  UiChipColor,
  ServiceLevelImpactColors,
} from '@/tt-widget-views/leave-management/types'
import { LeaveRequestsStatus, Shift } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsAffectedShiftsCounter',
  inject: ['getItemHook'],
  props: {
    inDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    affectedShiftsTotal(): number {
      return this.getItemHook().get('extensions.affectedShifts.total')
    },
    unassignedShiftsCount(): number {
      return this.getItemHook().get(
        'extensions.affectedShifts.unassignedShiftsCount',
      )
    },
    leaveRequestStatus(): LeaveRequestsStatus {
      return this.getItemHook().getRawValue('status')
    },
    /* 
      An Affected shift is a shift that is affected by the leave request 
      Or a shift that is unassigned because the leave request was approved and the shifts were pushed to vacant
    */
    affectedShiftsAmount(): number {
      if (this.leaveRequestStatus === LeaveRequestsStatus.PENDING) {
        return this.affectedShiftsTotal
      } else {
        return this.unassignedShiftsCount
      }
    },
    color(): UiChipColor {
      return this.affectedShiftsAmount > 0
        ? ServiceLevelImpactColors.HIGH
        : ServiceLevelImpactColors.NONE
    },
  },
  created() {
    this.getItemHook().addAttribute('extensions.affectedShifts.total')
    this.getItemHook().addAttribute(
      'extensions.affectedShifts.unassignedShiftsCount',
    )
    this.getItemHook().addAttribute('status')
  },
})
</script>
<style scoped>
.affected-shifts-in-details {
  height: 20px !important;
  padding: 0 7px;
  font-size: 12px !important;
  cursor: default !important;
}
</style>
